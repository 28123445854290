<template>
<svg version="1.1" id="logo-sportstudio" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
y="0px" viewBox="0 0 314.1 56.7" style="enable-background:new 0 0 314.1 56.7;" xml:space="preserve">
<path class="st1" d="M309.8,54.4c-1,0-1.8-0.5-1.8-1.3s0.8-1.3,1.8-1.3s1.8,0.5,1.8,1.3S310.8,54.4,309.8,54.4 M309.8,47.9
c0.8,0,1.3,0.4,1.3,1c0,0.6-0.5,1-1.3,1c-0.8,0-1.3-0.4-1.3-1C308.5,48.3,309,47.9,309.8,47.9 M312.7,51c0.8-0.5,1.1-1.1,1.1-2.2
c0-2-1.9-3.2-4.2-3.2c-2.3,0-4.2,1.1-4.2,3.2c0,2,0.5,1.7,1.1,2.2c-1,0.6-1.7,1.5-1.7,2.6c0,1.9,1.7,3.2,4.6,3.2s4.6-1.3,4.6-3.2
s-0.5-2-1.7-2.6 M290,45.7V48h5.1l-4,8.4h3.1l4.1-8.7v-2H290z M276.7,49.3c0-0.9,0.9-1.5,1.8-1.5c0.9,0,1.8,0.5,1.8,1.5
c0,1-0.8,1.5-1.8,1.5S276.7,50.3,276.7,49.3 M283.1,49.3c0-2.2-1.9-3.8-4.7-3.8c-2.8,0-4.7,1.5-4.7,3.8c0,2.3,1.7,3.6,4.2,3.8
l-2.7,3.4h3.3l3.8-5c0.5-0.6,0.8-1.4,0.8-2.2 M263.2,45.7l-3.3,1l0.6,2l2.2-0.5v8.2h2.8V45.7h-2.6H263.2L263.2,45.7z M236.8,54.1
v-1.9h4.5v-2.3h-4.5v-1.8h5v-2.3h-7.8v10.7h7.9v-2.3h-5.1L236.8,54.1z M220.5,56.7c2.3,0,4.3-1,5.4-2.4l-2.4-1.3
c-0.5,0.8-1.7,1.3-2.9,1.3c-2.2,0-3.7-1.3-3.7-3.2s1.4-3.2,3.7-3.2s2.3,0.5,2.9,1.3l2.4-1.3c-1.1-1.5-3.1-2.4-5.4-2.4
c-3.8,0-6.5,2.4-6.5,5.6S216.7,56.7,220.5,56.7 M202.9,45.7v5.9l-4.8-5.9h-2.2v10.7h2.8v-5.9l4.8,5.9h2.2V45.7H202.9z M184.3,56.4
h2.8V45.7h-2.8V56.4z M171.7,56.7c2.7,0,4.6-1.3,4.6-3.3s-2.2-2.8-4.1-3.3c-1.9-0.5-2.3-0.9-2.3-1.3s0.5-0.8,1.4-0.8
s1.8,0.5,2.2,1.3l2.4-1.1c-0.9-1.5-2.6-2.4-4.6-2.4c-2,0-4.2,1.1-4.2,3.3s1.8,2.8,3.7,3.3c1.9,0.4,2.6,0.8,2.6,1.4
c0,0.6-0.4,0.9-1.7,0.9s-2.2-0.6-2.7-1.5l-2.4,1.3c0.6,1.5,2.4,2.3,5,2.3 M144,54.1v-8.4h-2.8v10.7h7.4v-2.3
C148.6,54.1,144,54.1,144,54.1z M129.4,56.4h2.8V45.7h-2.8V56.4z M107.5,56.4h3.3l2-6.4l2,6.4h3.3l3.4-10.7h-3.1l-2.2,7.5l-2.4-7.5
h-2.3l-2.4,7.5l-2.2-7.5H104L107.5,56.4L107.5,56.4L107.5,56.4z M93.3,45.7v4.1h-4v-4.1h-2.8v10.7h2.8v-4.2h4v4.2h2.8V45.7H93.3z
M73.1,56.7c2.3,0,4.3-1,5.4-2.4L76.1,53c-0.5,0.8-1.7,1.3-2.9,1.3c-2.2,0-3.7-1.3-3.7-3.2s1.4-3.2,3.7-3.2s2.3,0.5,2.9,1.3l2.4-1.3
c-1.1-1.5-3.1-2.4-5.4-2.4c-3.8,0-6.5,2.4-6.5,5.6S69.3,56.7,73.1,56.7 M54.5,56.7c2.7,0,4.7-1.3,4.7-3.3s-2.2-2.8-4.1-3.3
c-1.9-0.5-2.3-0.9-2.3-1.3s0.5-0.8,1.4-0.8s1.8,0.5,2.2,1.3l2.4-1.1c-0.9-1.5-2.6-2.4-4.6-2.4S50,46.9,50,49s1.8,2.8,3.7,3.3
c1.9,0.4,2.6,0.8,2.6,1.4c0,0.6-0.4,0.9-1.7,0.9c-1.3,0-2.2-0.6-2.7-1.5l-2.4,1.3C50.2,55.9,51.9,56.7,54.5,56.7 M37.9,54.1v-8.4
h-2.8v10.7h7.4v-2.3C42.5,54.1,37.9,54.1,37.9,54.1z M22.7,54.1v-8.4h-2.8v10.7h7.4v-2.3C27.3,54.1,22.7,54.1,22.7,54.1z M4.6,52.5
l1.5-4l1.5,4H4.6z M8.9,56.4h3.2L7.9,45.7H4.2L0,56.4h3.2l0.6-1.7h4.7l0.6,1.7C9.2,56.4,8.9,56.4,8.9,56.4z"/>
<path class="st0" d="M298.1,25.1c-5,0-9-3.7-9-9.2s4-9.2,9-9.2s9,3.7,9,9.2S303.1,25.1,298.1,25.1 M298.1,32c8.8,0,16-7,16-16
s-7.2-16-16-16s-16,7-16,16S289.3,32,298.1,32 M269.7,31.3h7V0.5h-7V31.3z M249.5,24.6h-5.3V7.4h5.3c4.8,0,8.2,3.3,8.2,8.6
S254.4,24.6,249.5,24.6 M249.5,0.5h-12.3v30.8h12.3c8.5,0,15-6.7,15-15.4S258,0.5,249.5,0.5 M218.9,32c6.8,0,11.9-4,11.9-10.6V0.5
h-7v20.2c0,2.3-1.1,4.3-4.8,4.3s-4.8-2-4.8-4.3V0.5h-7v20.9c0,6.6,5.1,10.6,11.9,10.6 M202.8,0.5h-22.9v6.8h7.9v24h7v-24h7.9V0.5
H202.8z M167,32c6.7,0,11.4-3.5,11.4-9.5s-5.2-8.1-9.9-9.5c-4.8-1.5-5.5-2.4-5.5-3.8s1.1-2.3,3.3-2.3s4.3,1.4,5.3,3.6l5.9-3.5
c-2.2-4.5-6.2-7-11.3-7s-10.3,3.4-10.3,9.4s4.5,8.1,9.1,9.4s6.4,2,6.4,3.9s-0.8,2.5-4.2,2.5s-5.4-1.7-6.5-4.3l-6.1,3.5
c1.7,4.5,5.9,7.6,12.3,7.6 M139.9,0.5H117v6.8h7.9v24h7v-24h7.9V0.5H139.9z M98.6,7.1h5.3c1.9,0,3.5,1.7,3.5,4s-1.6,4-3.5,4h-5.3
V7.1L98.6,7.1L98.6,7.1z M107.7,31.3h7.6L108.7,20c3.4-1.7,5.7-5.1,5.7-8.9c0-5.9-4.7-10.6-10.6-10.6H91.5v30.8h7V21.2h3.3
L107.7,31.3L107.7,31.3L107.7,31.3z M70.2,25.1c-5,0-9-3.7-9-9.2s4-9.2,9-9.2s9,3.7,9,9.2S75.2,25.1,70.2,25.1 M70.2,32
c8.8,0,16-7,16-16S79,0,70.2,0s-16,7-16,16S61.4,32,70.2,32 M40.1,15.1h-4.6V7.2h4.6c2.2,0,3.7,1.7,3.7,4S42.2,15.2,40.1,15.1
M40.1,0.5H28.4v30.8h7v-9.7H40c6.1,0,10.8-4.7,10.8-10.6S46.1,0.4,40.1,0.5 M12.4,32c6.7,0,11.4-3.5,11.4-9.5s-5.2-8.1-9.9-9.5
c-4.8-1.5-5.5-2.4-5.5-3.8s1.1-2.3,3.3-2.3S16,8.3,17,10.5L22.9,7c-2.2-4.5-6.2-7-11.3-7S1.3,3.4,1.3,9.4s4.5,8.1,9.1,9.4
s6.4,2,6.4,3.9s-0.8,2.5-4.2,2.5s-5.4-1.7-6.5-4.3L0,24.4C1.7,28.9,5.9,32,12.4,32"/>
</svg>
</template>