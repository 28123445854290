<template>

  <div style="margin-top: 80px;">
  <!-- shop-product  -->
  <!-- {{ msg }} -->
  <!-- shoplist {{ this.shoplist }} -->
  </div>

  <!-- LOADER -->
  <div id="loader">
    <LoadIndicator />
  </div>
  <!-- /LOADER -->


  <div class="dx-card" style="width: 100%; padding: 0;">


  <!-- LIST -->
  <div v-for="shopitem in this.shoplist" 
  :key="shopitem.id" 
  class="-grid-x -align-middle" 
  style="border-bottom: 1px solid #e0e0e0; ;padding: 0 5px;"
  >


  <!-- <div class="shrink cell">
  {{ shopitem.id  }}
  </div>
  <div class="auto cell">
  {{ shopitem.Subject  }}
  </div>
 -->



  <div class="grid-x align-middle dx-template-wrapper">
  <div class="shrink cell">

  <!-- image-thumb -->
  <div class="image-thumb" style="margin: 2px 10px 2px 0;"
    v-bind:style="{ 'background-image': 'url(' + shopitem.Picthumb + ')' }" >
  </div>
  <!-- /image-thumb -->
  
  </div>
  <div class="auto cell">
  <div class="grid-x align-middle">
  <div class="shrink cell">
  <div class="fixedwidth-listitem">
  <p class="ck-overflow-ellipsis strong small">
  <span>{{ shopitem.Subject }}</span>
  </p>
  </div>
  </div>
  <div class="shrink cell">
  <div class="fixedwidth-listitem">
  <p class="ck-overflow-ellipsis -strong small">
  <span>Art-Nr. {{ shopitem.Sku  }}</span>
  </p>
  </div>
  </div>
  <div class="shrink cell">
  <div class="fixedwidth-listitem">
  <p class="ck-overflow-ellipsis -strong small">
  <span>CHF {{ formatPrice(shopitem.Price) }}</span>
  </p>
  </div>
  </div>
  <div class="shrink cell -hide">
  <div class="fixedwidth-listitem">
  <p class="ck-overflow-ellipsis -strong small">
  <span>EAN {{ shopitem.Barcode  }}</span>
  </p>
  </div>
  </div>
  </div>
  </div>
  </div>






  </div>
  <!-- /LIST -->


</div>
<!-- /dx-card -->


</template>

<script>

let shoplist;

import { 
    apihost, 
  } from "../api";

import LoadIndicator from '../parts/loader-template.vue';


export default {
  components: {
    LoadIndicator,
  },
  setup() {
    LoadIndicator

  },
  mounted() {

    //loader
    const loader = document.getElementById("loader");

    //fetchProducts 
    fetch(apihost+'/de/vue/shop/list/?case=webshop')
    .then(response => response.text())
    .then(result => {
      const data = JSON.parse(result);
      this.shoplist = data;
      loader.classList.add("hide");
    })
    .catch(() => { throw 'Network error' });

  },
  data() {
    return {
      msg: 'Product',
      apihost,
      LoadIndicator,
      shoplist,
    };
  },
  methods: {
				formatPrice(value) {
					value = parseFloat(value);
					//auf 5 Rappen runden
					value = Math.round(value * 20) / 20;
					//zwei Stellen anzeigen
					value = value.toFixed(2);
					return value;
				},

  },
  unmounted() {

  },
  
};

</script>
