<template>
<svg version="1.1" id="logo-moveon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
y="0px" viewBox="0 0 561.1 148.6" style="enable-background:new 0 0 561.1 148.6;" xml:space="preserve">
<path class="st0" d="M355.2,68.3c-1.5,4.1-5.5,5.1-9.5,5.6c-7.8,0.9-17.1,0.7-25,0c-3.9-0.4-8.8-0.3-11.1-4.2
c-2.1-3.7-1.9-13.7-1.7-18.2c0.1-3.3,0.4-8.1,3-10.4c3.8-3.4,15.2-3,20.2-3s17.3-0.3,21.3,2.4c1.2,0.8,2.4,2.4,2.9,3.8
c1.6,4.5,1.6,19.7,0,24.1 M371.6,32c-6.3-9.1-23.9-8.2-33.8-8.4c-9.5-0.2-19.3-0.5-28.8,0.7c-11.6,1.5-17.9,5.2-19.8,17.4
c-1.3,8.7-1.6,28.8,2.1,36.7c5.4,11.5,22.9,10.4,33.5,10.6c10.4,0.2,22.3,0.7,32.6-1c4.2-0.7,9.5-2.2,12.6-5.3
c4.2-4.2,5.3-12.1,5.6-17.8C376,56.3,376.4,39,371.6,32"/>
<path class="st0" d="M178.2,72.1c-0.5,4.5-1.6,5.8-6.3,5.2c-2.3-0.3-2.8-3.3-3-5.2c-0.8-10.3,0.6-21.7,0-32.1
c0.2-1.5,0.6-4.2,2.3-4.6c0.7-0.2,3.2-0.2,4,0c2,0.2,2.4,2,2.8,3.7C177.7,49.6,179.3,61.8,178.2,72.1 M193.2,39.5
c-0.5-10.2-3.8-14.9-14.3-15.9c-5.6-0.5-16.7-0.5-20.9,3.7c-3.9,3.8-3.8,9.9-4,14.9c-0.3,9.1-0.3,18.4,0,27.5
c0.2,7.2,0,15.6,8.2,18.3c5.1,1.7,18.2,1.8,23.3-0.1c6.4-2.4,7.4-8.9,7.8-15c0.5-10,0.5-23.3,0-33.3"/>
<path class="st0" d="M458.1,71.9V24.6l0.4-0.4h18.2l0.4,0.4v63.5l-0.4,0.4h-28.1c-12.7-15.6-25-31.6-37.4-47.5l-0.7-0.4v47.6
l-0.4,0.4h-18.2l-0.4-0.4V24.7l0.4-0.4h28.8l36.6,47.3L458,72L458.1,71.9L458.1,71.9z"/>
<path class="st0" d="M105.7,24.2l7.3,48.6l7.8-48.6h21.4l0.4,0.4v63.3c0,0-0.3,0.4-0.2,0.6h-12.8l0.6-52.2l-9.9,52.3l-14.9-0.2
l-9.5-51.8l0.7,52H84l-0.4-0.4V24.7l0.4-0.4h21.8h-0.1L105.7,24.2z"/>
<polygon class="st0" points="215.1,24.2 221.4,76.7 227.9,24.2 241.2,24.2 231.4,87.9 231.1,88.3 211.1,88.4 201.2,24.2 "/>
<polygon class="st0" points="280.3,24.2 280.3,35.4 265.1,35.4 264.7,35.8 264.7,49.8 277.9,49.8 277.9,60.9 264.7,60.9 264.7,76.7 
279.9,76.7 280.3,77 280.3,88 279.9,88.4 250.2,88.4 249.8,88 249.8,24.5 250.2,24.2 "/>
<path class="st1" d="M47.2,0h23c1.2,0,2.1,0.9,2.1,2.1v108.3c0,1.2-0.9,2.1-2.1,2.1h-23c-1.2,0-2.1-0.9-2.1-2.1V2.1
C45.1,0.9,46,0,47.2,0z"/>
<path class="st1" d="M2.1,50.3h8c1.2,0,2.1,0.9,2.1,2.1v7.7c0,1.2-0.9,2.1-2.1,2.1h-8c-1.2,0-2.1-0.9-2.1-2.1v-7.7
C0,51.2,0.9,50.3,2.1,50.3z"/>
<path class="st1" d="M551,50.3h8c1.2,0,2.1,0.9,2.1,2.1v7.7c0,1.2-0.9,2.1-2.1,2.1h-8c-1.2,0-2.1-0.9-2.1-2.1v-7.7
C548.9,51.2,549.8,50.3,551,50.3z"/>
<path class="st1" d="M20.2,10.7h17.3c1.2,0,2.1,0.9,2.1,2.1v87.1c0,1.2-0.9,2.1-2.1,2.1H20.2c-1.2,0-2.1-0.9-2.1-2.1V12.8
C18.1,11.6,19,10.7,20.2,10.7z"/>
<path class="st1" d="M523.1,10.7h17.3c1.2,0,2.1,0.9,2.1,2.1v87.1c0,1.2-0.9,2.1-2.1,2.1h-17.3c-1.2,0-2.1-0.9-2.1-2.1V12.8
C521,11.6,521.9,10.7,523.1,10.7z"/>
<path class="st1" d="M490.8,0h23c1.2,0,2.1,0.9,2.1,2.1v108.3c0,1.2-0.9,2.1-2.1,2.1h-23c-1.2,0-2.1-0.9-2.1-2.1V2.1
C488.7,0.9,489.6,0,490.8,0z"/>
<path class="st0" d="M542.7,138.3h2.7c2.2,0,2.5,0.2,2.5,1.7s0,1.2-0.5,1.4c-0.3,0.2-1.5,0.3-3.1,0.3h-1.6L542.7,138.3L542.7,138.3
L542.7,138.3z M537.5,148.3h5.2v-3.7h2.5c2,0,2.5,0.2,2.5,1.2v2.6h5.2v-2.3c0-2.1-0.6-2.7-3.7-3.1c1.1,0,1.8-0.2,2.4-0.6
c1-0.5,1.3-1.2,1.3-2.7s-0.5-2.9-2-3.6c-1.1-0.5-2.7-0.7-5.8-0.7h-7.7L537.5,148.3L537.5,148.3L537.5,148.3z M519.8,148.3h14.5v-2.9
h-9.2v-2.5h8.7v-2.5h-8.7v-2.2h9.2v-2.9h-14.5V148.3L519.8,148.3z M512.6,148.3v-10h4.8v-3.1h-14.9v3.1h4.8v10H512.6z M481.8,148.3
h5v-4.4c0-1-0.2-4.8-0.2-5.7c0.9,1.8,1.9,3.4,3.8,6.5l2.2,3.6h7.6v-13h-5v4.3c0,2,0.2,5.2,0.2,5.9c-0.7-1.3-1.2-2.3-3.3-5.8
l-2.7-4.4h-7.7v13L481.8,148.3L481.8,148.3z M464.2,148.3h14.5v-2.9h-9.2v-2.5h8.7v-2.5h-8.7v-2.2h9.2v-2.9h-14.5V148.3L464.2,148.3
z M455.9,143.3v0.5c0,0.6,0,1-0.3,1.2c-0.3,0.3-1.1,0.5-2.5,0.5c-2,0-2.6-0.4-2.6-1.7v-3.7c0-1.4,0.6-1.7,2.6-1.7s1.8,0.1,2.2,0.4
c0.4,0.2,0.5,0.5,0.5,1.3v0.2h5.1c0-2-0.2-2.5-0.7-3.2c-1-1.2-3.2-1.8-7.1-1.8s-4.9,0.4-6.3,1.4c-1.1,0.8-1.6,1.7-1.6,3.2v3.6
c0,1.6,0.3,2.6,1.1,3.4c1.2,1.2,3.2,1.7,7.1,1.7s5.3-0.4,6.5-1.4c1-0.8,1.2-1.5,1.2-3.8L455.9,143.3L455.9,143.3z M426.5,144.3v0.6
c0,1.4,0.4,2.1,1.5,2.7c1.3,0.6,3,0.8,6.3,0.8s5-0.2,6.1-0.7c1.5-0.6,2.1-1.7,2.1-3.4s-0.3-2.3-1.1-2.9c-0.9-0.7-1.9-0.9-5.9-1.1
c-3.5-0.2-3.7-0.2-3.7-1.2s0.3-1.2,1.9-1.2s2.6,0,2.9,0.2c0.3,0,0.5,0.4,0.5,0.8v0.2h4.8v-0.2c0-1.6-0.3-2.3-1.3-2.9
c-1.2-0.7-2.5-0.9-6.5-0.9s-4.8,0.2-6,0.8c-1.1,0.6-1.6,1.6-1.6,3.1s0.6,2.6,1.8,3.2c0.9,0.4,2,0.6,5.6,0.8c3.3,0.2,3.4,0.2,3.4,1.4
s0,0.8-0.4,1c-0.3,0.1-1.3,0.2-2.8,0.2s-2.8-0.3-2.8-1.1s0-0.2,0-0.3h-4.9L426.5,144.3L426.5,144.3z M416,141.1v2.4h2.9
c0,1.1,0,1.2-0.3,1.5c-0.4,0.4-1.3,0.4-3.8,0.4s-2-0.2-2.4-0.5c-0.4-0.4-0.5-1.4-0.5-3.5c0-3,0.4-3.4,3.3-3.4s3.4,0.2,3.5,1.2h5.1
c0-1.7-0.3-2.4-1.3-3c-1.2-0.8-2.8-1.1-6.1-1.1s-4.2,0-4.9,0.1c-1.6,0.2-2.9,0.8-3.8,1.7s-1.2,2.2-1.2,5.2s0.5,4.2,2,5.1
c1.3,0.8,2.6,1.1,5.4,1.1h3.4c1.3,0,2.5-0.1,3.5-0.4c1.3-0.4,2.5-1.3,2.7-2.2c0.2-0.7,0.3-1.9,0.3-3.4s0-0.7,0-1.2L416,141.1
L416,141.1L416,141.1z M384.9,148.3h5v-4.4c0-1-0.2-4.8-0.2-5.7c0.9,1.8,1.9,3.4,3.8,6.5l2.2,3.6h7.6v-13h-5v4.3
c0,2,0.2,5.2,0.2,5.9c-0.7-1.3-1.2-2.3-3.3-5.8l-2.7-4.4h-7.7v13L384.9,148.3L384.9,148.3z M375.9,148.3h5.3v-13h-5.3V148.3z
M353.8,148.3h5v-4.4c0-1-0.2-4.8-0.2-5.7c0.9,1.8,1.9,3.4,3.8,6.5l2.2,3.6h7.6v-13h-5v4.3c0,2,0.2,5.2,0.2,5.9
c-0.7-1.3-1.2-2.3-3.3-5.8l-2.7-4.4h-7.7v13L353.8,148.3L353.8,148.3z M344.9,148.3h5.3v-13h-5.3V148.3z M331.3,143.4l1.9-5.4h0.4
l1.9,5.4H331.3L331.3,143.4L331.3,143.4z M329.5,135.3l-5.4,13h5.5l0.8-2.3h6.1l0.9,2.3h5.4l-5.3-13H329.5z M311.9,138.3h2.7
c2.2,0,2.5,0.2,2.5,1.7s-0.1,1.2-0.5,1.4c-0.3,0.2-1.5,0.3-3.1,0.3h-1.6V138.3z M306.6,148.3h5.2v-3.7h2.5c2,0,2.5,0.2,2.5,1.2v2.6
h5.2v-2.3c0-2.1-0.6-2.7-3.7-3.1c1.1,0,1.8-0.2,2.4-0.6c1-0.5,1.3-1.2,1.3-2.7s-0.5-2.9-2-3.6c-1.1-0.5-2.7-0.7-5.8-0.7h-7.7v13
L306.6,148.3L306.6,148.3z M299.5,148.3v-10h4.8v-3.1h-14.9v3.1h4.8v10H299.5L299.5,148.3z"/>
<path class="st0" d="M390.6,120.3v0.6c0,1.4,0.4,2.1,1.6,2.7c1.3,0.6,3,0.8,6.4,0.8s5.1-0.2,6.2-0.7c1.5-0.6,2.1-1.7,2.1-3.4
s-0.3-2.3-1.2-2.9c-0.9-0.7-1.9-0.9-6-1.1c-3.6-0.2-3.8-0.2-3.8-1.2s0.3-1.2,1.9-1.2s2.6,0,3,0.2c0.3,0,0.5,0.4,0.5,0.8v0.2h4.9
v-0.2c0-1.6-0.3-2.3-1.3-2.9c-1.2-0.7-2.6-0.9-6.5-0.9s-4.9,0.2-6,0.8c-1.1,0.6-1.6,1.6-1.6,3.2s0.6,2.6,1.8,3.2
c0.9,0.4,2,0.6,5.7,0.8c2.3,0.1,3.4,0.6,3.4,1.4c0,1.2,0,0.8-0.4,1c-0.3,0.1-1.3,0.2-2.8,0.2s-2.8-0.3-2.8-1.1s0-0.2,0-0.3H390.6
L390.6,120.3z M371.8,120.3v0.6c0,1.4,0.4,2.1,1.6,2.7c1.3,0.6,3,0.8,6.4,0.8s5.1-0.2,6.2-0.7c1.5-0.6,2.1-1.7,2.1-3.4
s-0.3-2.3-1.2-2.9c-0.9-0.7-1.9-0.9-6-1.1c-3.6-0.2-3.8-0.2-3.8-1.2s0.3-1.2,1.9-1.2s2.6,0,3,0.2c0.3,0,0.5,0.4,0.5,0.8v0.2h4.9
v-0.2c0-1.6-0.3-2.3-1.3-2.9c-1.2-0.7-2.6-0.9-6.5-0.9s-4.9,0.2-6,0.8c-1.1,0.6-1.6,1.6-1.6,3.2s0.6,2.6,1.8,3.2
c0.9,0.4,2,0.6,5.7,0.8c2.3,0.1,3.4,0.6,3.4,1.4c0,1.2,0,0.8-0.4,1c-0.3,0.1-1.3,0.2-2.8,0.2s-2.8-0.3-2.8-1.1s0-0.2,0-0.3H371.8
L371.8,120.3z M354.6,124.3h14.7v-2.9h-9.4v-2.5h8.9v-2.5h-8.9v-2.2h9.4v-2.9h-14.7V124.3z M332.2,124.3h5.1v-4.4
c0-1-0.2-4.8-0.2-5.7c0.9,1.8,1.9,3.4,3.9,6.5l2.3,3.6h7.7v-13h-5.1v4.3c0,2,0.2,5.2,0.2,5.9c-0.7-1.3-1.2-2.3-3.4-5.8l-2.7-4.4
h-7.8V124.3z M325,124.3v-10h4.9v-3.1h-15.1v3.1h4.9v10H325L325,124.3L325,124.3z M307,124.3h5.4v-13H307V124.3z M290.7,124.3h5.4
v-4.8h7.3v-2.9h-7.3v-2.4h7.8v-2.9h-13.1L290.7,124.3L290.7,124.3L290.7,124.3z"/>
</svg>
</template>
