<template>

  <div v-if="currentUser.Role == 'Customer'" class="-content-block -hide">
  
    <h2 class="app-title hide">
      {{ title }}
    </h2>
  
    <div class="tabs-content tabs-content-customer" :data-tabs-content="'tabsDashboard'+currentUser.id">
  
      <div class="tabs-panel" :class="tab1" :id="'panelDashboard_1'+currentUser.id">
        <!-- <p>subtitle panel1</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-file-contract"></i>
          Verträge
        </h2>

        <div class="dx-card hide">
          <div class="user-base-wrap has-padding">
            <!-- <p>Verträge Dashboard</p> -->
          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 

      </div><!-- /tabs-panel -->     
    
      <div class="tabs-panel" :class="tab2" :id="'panelDashboard_2'+currentUser.id">
        <!-- <p>subtitle panel1</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-ticket"></i>
          Abo's
        </h2>

        <div class="dx-card">
          <div class="user-base-wrap -has-padding">

            <div class="customer-datagrid">

              <DxDataGrid
                ref="myDataGrid"
                id="gridContainer"
                :data-source="contractDataSource"
                :focused-row-enabled="false"
                :column-auto-width="false"
                :column-hiding-enabled="false"
                :allow-column-reordering="false"
                :show-borders="false"
              >

              <DxPaging :page-size="50" />
              <DxPager :show-page-size-selector="true" :show-info="true" />
              <DxFilterRow :visible="false" />

              <DxEditing
                :allow-updating="false"
                :allow-deleting="false"
                :allow-adding="false"
                mode="row"
              />  

              <DxColumn
                alignment='left' 
                data-field="Search"
                :caption="'Abos'"
                :placeholder="'Suchen'"
                :hiding-priority="99"
                cell-template="contractTemplate"

              />
              <template #contractTemplate="{ data: content }">

                <div class="grid-x -grid-margin-x align-middle ck-itemrow">

                  <div class="shrink cell">
                    <!-- avatar -->
                    <div class="user-thumb" style="margin-left: 5px; margin-right: 10px;"
                      v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
                    </div>
                    <!-- /avatar -->
                  </div><!-- /shrink cell -->

                  <div class="auto cell">
                    <p class="ck-overflow-ellipsis strong">

                      <span v-if="content.data.AboSubject">
                        VG-{{ content.data.id}} | {{ content.data.AboSubject }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Abo
                      </span>

                    </p>
                    <p class="ck-overflow-ellipsis small -hide">          

                      <span v-if="content.data.DateStart && content.data.DateStop">
                        {{ formatDate(content.data.DateStart) }} – {{ formatDate(content.data.DateStop) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                      </span>
                      | 
                      <span v-if="content.data.Price">
                        CHF {{ formatPrice(content.data.Price - content.data.Discount) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Preis
                      </span>
                      | 
                      <span v-if="content.data.Rates && content.data.Price">
                        {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Raten
                      </span>

                    </p>
                  </div><!-- auto cell -->
                  <div class="shrink cell">

                    <!-- as wkhtmltopdf-->
                    <div class="-hide">

                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/contract/'+currentUser.id+'/'+content.data.id+'&dest=send'" target="_blank" style="font-size: 20px;">
                        <i class="fa-light fa-file-pdf"></i> 
                        <!-- Qualicert-{{content.data.id}}.pdf -->
                      </a>


                    </div>

                  </div><!-- /shrink cell -->

                </div><!-- grid-x -->
              </template>

              </DxDataGrid>

            </div><!-- customer-datagrid -->

          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 
    
      <div class="tabs-panel" :class="tab3" :id="'panelDashboard_3'+currentUser.id">
        <!-- <p>subtitle panel3</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-file-invoice"></i>
          Rechnungen
        </h2>
        
        <div class="dx-card">
          <div class="user-base-wrap -has-padding">

            <div class="customer-datagrid">

              <DxDataGrid
              id="gridContainer"
              :data-source="invoiceDataSource"

              :focused-row-enabled="false"
              :column-auto-width="true"
              :column-hiding-enabled="true"

              :allow-column-reordering="true"
              :show-borders="false"

              @editing-start="logEvent('EditingStart')"
              @init-new-row="logEvent('InitNewRow')"
              @row-inserting="logEvent('RowInserting')"
              @row-inserted="logEvent('RowInserted')"
              @row-updating="logEvent('RowUpdating')"
              @row-updated="logEvent('RowUpdated')"
              @row-removing="logEvent('RowRemoving')"
              @row-removed="logEvent('RowRemoved')"
              @saving="logEvent('Saving')"
              @saved="logEvent('Saved')"
              @edit-canceling="logEvent('EditCanceling')"
              @edit-canceled="logEvent('EditCanceled')"
              >

              <DxPaging :page-size="100" />
              <DxPager :show-page-size-selector="false" :show-info="true" />
              <DxFilterRow :visible="true" />

              <DxEditing
              :allow-updating="false"
              :allow-deleting="false"
              :allow-adding="false"
              mode="row"
              />

              <DxColumn
              alignment='left' 
              data-field="Search"
              :caption="title"
              :placeholder="'Suchen'"
              cell-template="subjectTemplate"
              :hiding-priority="99"
              />

              <template #subjectTemplate="{ data: content }">

              <div class="grid-x align-middle ck-itemrow">
                <div class="shrink cell" style="width: 80px; margin-left: 10px; margin-right: 5px;">
                  <p class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                    RE-Nr.
                  </p>
                  <p class="ck-overflow-ellipsis small">
                    <strong>{{ content.data.id}}</strong>
                  </p>
                </div>
                <div class="shrink cell" style="width: 100px; margin-right: 5px;">
                  <p class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                    Preis (Rate)
                  </p>
                  <p class="ck-overflow-ellipsis small">
                    CHF {{ formatPrice(content.data.ratePrice)}}
                  </p>
                </div>

                <!-- <div class="shrink cell" style="width: 150px; margin-right: 5px;">
                  <p class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                    Kunde
                  </p>
                  <p class="ck-overflow-ellipsis small">
                    {{ content.data.customerSubject}}
                  </p>
                </div> -->

                <div class="shrink cell" style="width: 100px; margin-right: 5px;">
                  <p v-if="content.data.rateValuta" class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                    Bezahlt
                  </p>
                  <p v-else class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                    Fällig
                  </p>

                  <p v-if="content.data.rateValuta" class="ck-overflow-ellipsis small">
                    <span class="success-color"><i class="fa-light fa-check-circle"></i> {{ formatDate(content.data.rateValuta) }}</span>
                  </p>
                  <p v-else class="ck-overflow-ellipsis small">
                    <span v-if="content.data.rateDate">
                      <span v-if="content.data.rateStatus == 'alert'" class="alert-color"><i class="fa-light fa-triangle-exclamation"></i> {{ formatDate(content.data.rateDate) }}</span>
                      <span v-if="content.data.rateStatus == 'wait'" class=""><i class="fa-light fa-hourglass-clock"></i> {{ formatDate(content.data.rateDate) }}</span>
                    </span>
                  </p>

                </div>

                <div class="auto cell">

                </div>


                <div class="shrink cell">

                <!-- as wkhtmltopdf-->
                <div class="-hide">
                  <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+currentUser.id+'/'+content.data.invoiceId+'/'+content.data.rateId+'&dest=send'" target="_blank" style="font-size: 20px;">
                    <i class="fa-light fa-file-pdf"></i> 
                    <!-- Rechnung-{{content.data.id}}.pdf -->
                  </a>
                </div>

                </div><!-- /shrink cell -->

                </div><!-- grid-x -->


              </template>


              </DxDataGrid>


              </div><!-- customer-datagrid -->

          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 
    
      <div class="tabs-panel" :class="tab4" :id="'panelDashboard_4'+currentUser.id">
        <!-- <p>subtitle panel4</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-money-check-dollar"></i>
          Krankenkasse
        </h2>

        <div class="dx-card">
          <div class="user-base-wrap -has-padding">
            <!-- <p>Krankenkasse</p> -->

            <div class="customer-datagrid">

              <DxDataGrid
                ref="myDataGrid"
                id="gridContainer"
                :data-source="contractDataSource"
                :focused-row-enabled="false"
                :column-auto-width="false"
                :column-hiding-enabled="false"
                :allow-column-reordering="false"
                :show-borders="false"
              >

              <DxPaging :page-size="50" />
              <DxPager :show-page-size-selector="true" :show-info="true" />
              <DxFilterRow :visible="false" />

              <DxEditing
                :allow-updating="false"
                :allow-deleting="false"
                :allow-adding="false"
                mode="row"
              />  

              <DxColumn
                alignment='left' 
                data-field="Search"
                :caption="'Abos'"
                :placeholder="'Suchen'"
                :hiding-priority="99"
                cell-template="contractTemplate"

              />
              <template #contractTemplate="{ data: content }">

                <div class="grid-x -grid-margin-x align-middle ck-itemrow">

                  <div class="shrink cell hide">
                    <!-- avatar -->
                    <div class="user-thumb" style="margin-right: 10px;"
                      v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
                    </div>
                    <!-- /avatar -->
                  </div><!-- /shrink cell -->

                  <div class="auto cell" style="padding-left: 10px;">
                    <p v-if="content.data.AboSubject" class="ck-overflow-ellipsis strong">
                      <span v-if="content.data.FitnessguidePdf">
                        Bestätigung Fitnessguide  | {{ content.data.AboSubject }}
                      </span>
                      <span v-if="content.data.QualicertPdf">
                        Bestätigung Qualicert  | {{ content.data.AboSubject }}
                      </span>
                      <span v-if="content.data.QualitopPdf">
                        Bestätigung Qualitop | {{ content.data.AboSubject }}
                      </span>
                    </p>
                    <p v-else>
                      <span class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Abo
                      </span>
                    </p>
                    <p class="ck-overflow-ellipsis small -hide">          

                      <span v-if="content.data.DateStart && content.data.DateStop">
                        {{ formatDate(content.data.DateStart) }} – {{ formatDate(content.data.DateStop) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                      </span>
                      | 
                      <span v-if="content.data.Price">
                        CHF {{ formatPrice(content.data.Price - content.data.Discount) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Preis
                      </span>
                      | 
                      <span v-if="content.data.Rates && content.data.Price">
                        {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Raten
                      </span>

                    </p>
                  </div><!-- auto cell -->
                  <div class="shrink cell">

                    <!-- pdf Fitnessguide-->
                    <div v-if="content.data.FitnessguidePdf" class="-hide">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/fitnessguide/'+currentUser.id+'/'+content.data.id+'&dest=send'" target="_blank" style="font-size: 20px;">
                        <i class="fa-light fa-file-pdf"></i> 
                      </a>
                    </div>
                    <!-- /pdf Fitnessguide-->

                    <!-- pdf Qualicert-->
                    <div v-if="content.data.QualicertPdf" class="-hide">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/qualicert/'+currentUser.id+'/'+content.data.id+'&dest=send'" target="_blank" style="font-size: 20px;">
                        <i class="fa-light fa-file-pdf"></i> 
                      </a>
                    </div>
                    <!-- /pdf Qualicert-->

                    <!-- pdf Qualitop-->
                    <div v-if="content.data.QualitopPdf" class="-hide">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/qualitop/'+currentUser.id+'/'+content.data.id+'&dest=send'" target="_blank" style="font-size: 20px;">
                        <i class="fa-light fa-file-pdf"></i> 
                      </a>
                    </div>
                    <!-- /pdf Qualitop-->
                    

                  </div><!-- /shrink cell -->

                </div><!-- grid-x -->
              </template>

              </DxDataGrid>

            </div><!-- customer-datagrid -->

          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 
    
      <div class="tabs-panel" :class="tab5" :id="'panelDashboard_5'+currentUser.id">
        <!-- <p>subtitle panel4</p> -->
        <h2 class="app-title">
          <i class="fa-light fa-file-signature"></i>
          Unterschrift
        </h2>
        
        <div class="dx-card">
          <div class="user-base-wrap -has-padding">
            <!-- <p>Unterschrift</p> -->

            <div class="customer-datagrid">

              <DxDataGrid
                ref="myDataGrid"
                id="gridContainer"
                :data-source="contractDataSource"
                :focused-row-enabled="false"
                :column-auto-width="false"
                :column-hiding-enabled="false"
                :allow-column-reordering="false"
                :show-borders="false"
              >

              <DxPaging :page-size="50" />
              <DxPager :show-page-size-selector="true" :show-info="true" />
              <DxFilterRow :visible="false" />

              <DxEditing
                :allow-updating="false"
                :allow-deleting="false"
                :allow-adding="false"
                mode="row"
              />  

              <DxColumn
                alignment='left' 
                data-field="Search"
                :caption="'Abos'"
                :placeholder="'Suchen'"
                :hiding-priority="99"
                cell-template="contractTemplate"

              />
              <template #contractTemplate="{ data: content }">

                <div class="grid-x -grid-margin-x align-middle ck-itemrow">

                  <div class="shrink cell hide">
                    <!-- avatar -->
                    <div class="user-thumb" style="margin-right: 10px;"
                      v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
                    </div>
                    <!-- /avatar -->
                  </div><!-- /shrink cell -->

                  <div class="auto cell">
                    <p class="ck-overflow-ellipsis strong">

                      <span v-if="!content.data.SignaturePng" class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> 
                      </span>
                      <span v-else class="success-color">
                        <i class="fa-light fa-light fa-circle-check"></i>
                      </span>
                      
                      <span v-if="content.data.AboSubject">
                        VG-{{ content.data.id}} | {{ content.data.AboSubject }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Abo
                      </span>


                    </p>
                    <p class="ck-overflow-ellipsis small -hide">          

                      <span v-if="content.data.DateStart && content.data.DateStop">
                        {{ formatDate(content.data.DateStart) }} – {{ formatDate(content.data.DateStop) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                      </span>
                      | 
                      <span v-if="content.data.Price">
                        CHF {{ formatPrice(content.data.Price - content.data.Discount) }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Preis
                      </span>
                      | 
                      <span v-if="content.data.Rates && content.data.Price">
                        {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Raten
                      </span>

                    </p>
                  </div><!-- auto cell -->
                  <div class="shrink cell">

                    <!-- as wkhtmltopdf-->
                    <div class="hide">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/contract/'+currentUser.id+'/'+content.data.id+'&dest=send'" target="_blank" style="font-size: 20px;">
                        <i class="fa-light fa-file-pdf"></i> 
                        <!-- Vertrag-{{content.data.id}}.pdf -->
                      </a>
                    </div>

                  </div><!-- /shrink cell -->

                </div><!-- grid-x -->
              </template>

              <DxMasterDetail
                :enabled="true"
                template="masterContractDetailTemplate"
              />
              <template #masterContractDetailTemplate="{ data: content }">
                <ContractDetailTemplate
                  :template-data="content"
                />
              </template>

              </DxDataGrid>

            </div><!-- customer-datagrid -->







          </div><!-- /user-base-wrap --> 
        </div><!-- /dx-card --> 
      </div><!-- /tabs-panel --> 
    
    </div><!-- /tabs-content --> 
    
    <ul :id="'tabsDashboard'+currentUser.id" class="tabs grid-x grid-margin-x small-up-2" data-tabs style="border: none; background: none; margin: 0 -15px;">
      <li class="cell tabs-title hide" :class="tab1">
          <a :href="'#panelDashboard_1'+currentUser.id" aria-selected="true" style="padding: 0;">
            <!-- panelDashboard_1 start -->
            <div class="dx-card dashboard-item">
              <!-- alert-icon -->
              <AppAlertDashboard
                :count="countAlertContract"
              />
              <!-- /alert-icon -->
              <div class="text-center">
                <i class="dashboard-icon fa-light fa-file-contract"></i>
              </div>
              <div class="text-center">
                <p class="dashboard-text">Verträge</p>
              </div>
            </div>
          </a>
        </li>
        <li class="cell tabs-title" :class="tab2">
          <a :href="'#panelDashboard_2'+currentUser.id" aria-selected="true" style="padding: 0;">
            <!-- panelDashboard_2 adress -->
            <div class="dx-card dashboard-item">
              <!-- alert-icon -->
              <AppAlertDashboard
                :count="countAlertAbo"
              />
              <!-- /alert-icon -->
              <div class="text-center">
                <i class="dashboard-icon fa-light fa-ticket"></i>
              </div>
              <div class="text-center">
                <p class="dashboard-text">Abo's</p>
              </div>
            </div>
          </a>
        </li>
        <li class="cell tabs-title" :class="tab3">
          <a :href="'#panelDashboard_3'+currentUser.id" aria-selected="true" style="padding: 0;">
            <!-- panelDashboard_3 foto -->
            <div class="dx-card dashboard-item">
              <!-- alert-icon -->
              <AppAlertDashboard
                :count="countAlertInvoice"
              />
              <!-- /alert-icon -->
              <div class="text-center">
                <i class="dashboard-icon fa-light fa-file-invoice"></i>
              </div>
              <div class="text-center">
                <p class="dashboard-text">Rechnungen</p>
              </div>
            </div>
          </a>
        </li>
        <li class="cell tabs-title" :class="tab4">
          <a :href="'#panelDashboard_4'+currentUser.id" aria-selected="true" style="padding: 0;">
            <!-- panelDashboard_4 gesundheit -->
            <div class="dx-card dashboard-item">
              <!-- alert-icon -->
              <AppAlertDashboard
                :count="countAlertConfirmation"
              />
              <!-- /alert-icon -->
              <div class="text-center">
                <i class="dashboard-icon fa-light fa-money-check-dollar"></i>
              </div>
              <div class="text-center">
                <p class="dashboard-text">Krankenkasse</p>
              </div>
            </div>
          </a>
        </li>
        <li class="cell tabs-title" :class="tab5">
          <a :href="'#panelDashboard_5'+currentUser.id" aria-selected="true" style="padding: 0;">
            <!-- panelDashboard_5 messungen -->
            <div class="dx-card dashboard-item">
              <!-- alert-icon -->
              <AppAlertDashboard
                :count="countAlertSignature"
              />
              <!-- /alert-icon -->
              <div class="text-center">
                <i class="dashboard-icon fa-light fa-file-signature"></i>
              </div>
              <div class="text-center">
                <p class="dashboard-text">Unterschrift</p>
              </div>
            </div>
          </a>
        </li>
  
      </ul>
  
  
    </div>
  </template>
  
  <script>


  import {
    DxDataGrid, 
    DxFilterRow, 
    DxColumn, 
    DxEditing, 
    DxPager, 
    DxPaging, 
    DxMasterDetail,
    //DxButton,
    //DxLookup, 
  } from 'devextreme-vue/data-grid';

  import ContractDetailTemplate from '../appdetail/contract-detail.vue';

  import AppAlertDashboard from "../appdetail/app-alert-dashboard";

  import CustomStore from 'devextreme/data/custom_store';

  import $ from 'jquery';
  import Foundation from 'foundation-sites';
  
  import auth from "../auth";
  
  import { 
    apihost, 
    pdfhost,
  } from "../api";
  //console.log(apihost);
    
  let currentUser;
  let customerId;

  let tab1 = 'is-active';
  let tab2 = '';
  let tab3 = '';
  let tab4 = '';
  let tab5 = '';

  let countAlertContract = 0;
  let countAlertAbo = 0;
  let countAlertInvoice = 0;
  let countAlertConfirmation = 0;
  let countAlertSignature = 0;

  let fitnessguidepdf;
  let qualicertpdf;
  let qualitoppdf;

  
  auth.getUser().then((e) => {
    if(e.data){
      //console.log(e.data);
      currentUser = e.data;

    } // e.data
  }); //auth
  
  function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

const contractDataSource = new CustomStore({
  key: 'id',
  load: () => {
    return fetch(apihost+'/de/vue/contract/list/?Customerid='+customerId)
    .then(handleErrors)
    .then(response => response.text())
    .then(data => {
    //console.log(`response text`, text)
    return JSON.parse(data);

    })
    .catch(() => { throw 'Network error' });
  },
  insert: (values) => {
    //console.log(values);
    const key = '0';
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/insert'+objString+'&Customerid='+customerId, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  remove: (key) => {
    //console.log(key);
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/remove', {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  update: (key, values) => {
    // console.log(key);
    // console.log(values);
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/save'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  }

});

 //console.log(contractDataSource)

 const invoiceDataSource = new CustomStore({
  key: 'id',
  load: () => {
  //const objString = '?case=due';
  const objString = '?Customerid='+customerId;

  return fetch(apihost+'/'+currentUser.Language+'/vue/invoicerate/list/'+objString)
    .then(handleErrors)
    .then(response => response.text())
    .then(data => {
    //console.log(`response text`, text)
    return JSON.parse(data)
    })
    .catch(() => { throw 'Network error' });
  },
  insert: (values) => {
    //console.log(values);
    const key = '0';
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/insert'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  remove: (key) => {
    //console.log(key);
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/remove', {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  update: (key, values) => {
    // console.log(key);
    // console.log(values);
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/save'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  }

});

//console.log(invoiceDataSource)


  export default {

    props: {
      customerData: Object,
    },
    
    async mounted() {

      await this.fetchAlert();
  
      let url = window.location.href;
      let pos = url.indexOf("?");
      let tab = '';


      if(pos > 0){
        tab = url.substring(pos+1, 100);
      }

      if(tab == 'abo'){
        this.tab1 = '';
        this.tab2 = 'is-active';
      }

      if(tab == 'invoice'){
        this.tab1 = '';
        this.tab3 = 'is-active';
      }

      if(tab == 'confirmation'){
        this.tab1 = '';
        this.tab4 = 'is-active';
      }

      if(tab == 'signature'){
        this.tab1 = '';
        this.tab5 = 'is-active';
      }
      
      this.tabsDashboard = new Foundation.Tabs($('#tabsDashboard'+this.currentUser.id), {
        matchHeight: false
      });

      if(this.currentUser){
        customerId = this.currentUser.id;
      }

      this.timestamp = this.printTimestamp();
  
    },

    components: {

      DxDataGrid,
      DxFilterRow,
      DxColumn,
      DxEditing,
      DxPager,
      DxPaging,
      DxMasterDetail,

      ContractDetailTemplate,

      AppAlertDashboard,

    },

    data() {
      return {
        apihost,
        pdfhost,
        
        title: 'Verträge',
        currentUser,
        customerId,

        tab1,
        tab2,
        tab3,
        tab4,
        tab5,

        countAlertContract,
        countAlertAbo,
        countAlertInvoice,
        countAlertConfirmation,
        countAlertSignature,

        contractDataSource,
        invoiceDataSource,

        fitnessguidepdf,
        qualicertpdf,
        qualitoppdf,

      };
    },
    methods: {

      async fetchAlert() {

        fetch(apihost+'/'+currentUser.Language+'/de/vue/member/memberalert/'+currentUser.id)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          // console.log(data);

          this.countAlertContract = data.countAlertContract;
          this.countAlertAbo = data.countAlertAbo;
          this.countAlertInvoice = data.countAlertInvoice;
          this.countAlertConfirmation = data.countAlertConfirmation;
          this.countAlertSignature = data.countAlertSignature;

        })
        .catch(() => { throw 'Network error' });

      },
  
      formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
      },
						
						formatPrice(value) {
							value = parseFloat(value);
							//auf 5 Rappen runden
							value = Math.round(value * 20) / 20;
							//zwei Stellen anzeigen
							value = value.toFixed(2);
							return value;
						},

      printTimestamp: function () {
        return Date.now();
      },
        
    },
    unmounted() {
  
    },
  };
  </script>
  
  <style lang="scss">
  </style>
  