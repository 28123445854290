<template>
<svg version="1.1" id="logo-mihusgym" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 5964.9 1321" style="enable-background:new 0 0 5964.9 1321;" xml:space="preserve">
<rect x="1869.4" y="1062" class="st0" width="1000" height="50"/>
<rect x="1553.9" y="1231.7" class="st0" width="1315.5" height="50"/>
<rect x="4342.8" y="1072.3" class="st0" width="1000" height="50"/>
<rect x="4342.8" y="1242.3" class="st0" width="1316.6" height="50"/>
<path class="st0" d="M3188.9,1139.6c2.4-0.6,4.8-1.1,7.2-1.5s4.9-0.6,7.5-0.6c10,0,19.1,1.8,27.3,5.4c8.2,3.6,15.2,8.5,21,14.7
s10.3,13.6,13.5,22c3.2,8.5,4.8,17.5,4.8,27.2s-2.1,21-6.1,29.8c-4.1,8.9-9.7,16.6-16.8,23c-7.1,6.4-15.5,11.4-25.2,14.8
c-9.7,3.5-20.1,5.2-31,5.2c-10.9,0-21.6-1.8-31.2-5.2c-9.6-3.5-18-8.4-25-14.8c-7.1-6.4-12.7-14-16.8-23
c-4.1-8.9-6.1-18.8-6.1-29.8s0.7-14.5,2.1-20.9c1.4-6.3,3.2-12.2,5.5-17.8c2.3-5.6,5-11,8.1-16c3.1-5.1,6.3-10.4,9.8-15.8
l46.5-72.6h54.9l-49.8,75.9H3188.9z M3225.2,1205c0-9.6-3.1-17.6-9.4-23.9s-14.6-9.5-24.8-9.5c-10.2,0-18.5,3.2-24.8,9.5
s-9.5,14.2-9.5,23.9s3.1,17.5,9.5,23.8c6.3,6.3,14.5,9.5,24.8,9.5s18.4-3.2,24.8-9.5C3222.1,1222.5,3225.2,1214.6,3225.2,1205z"/>
<path class="st0" d="M3375.2,1147.4h14.7c4.2,0,8.3-0.3,12.3-0.9c4-0.6,7.7-1.7,11-3.5c3.3-1.7,5.9-4.1,8-7.2c2-3.1,3-7.1,3-12.2
c0-5.1-2.6-11.7-7.7-15.9c-5.1-4.2-11.4-6.3-18.8-6.3s-12.8,1.9-17.4,5.7c-4.6,3.8-7.6,8.5-9,14.1l-49.5-10.2
c2.4-9.2,6-17,10.8-23.5s10.5-11.7,17.1-15.8c6.6-4,14-6.9,22.1-8.9c8.1-1.9,16.6-2.8,25.6-2.8s18.5,1.2,27.1,3.5
c8.7,2.3,16.4,5.9,23,10.8c6.6,4.9,11.9,11.1,15.8,18.5c3.9,7.4,5.9,16.2,5.9,26.4c0,10.2-3.1,21.9-9.3,30.3s-15.2,13.8-27,16.2
v0.9c6.4,0.8,12,2.7,17,5.7c4.9,3,9,6.7,12.4,11.1s6,9.4,7.8,15s2.7,11.5,2.7,17.7c0,10.6-2.1,19.9-6.1,27.9
c-4.1,8-9.6,14.7-16.5,19.9c-6.9,5.3-15,9.3-24.3,11.9s-19.2,3.9-29.6,3.9c-19,0-35.4-4.4-49-13.2c-13.7-8.8-22.8-22.7-27.1-41.7
l47.4-11.1c1.6,7.6,4.8,13.6,9.8,18c4.9,4.4,12.1,6.6,21.8,6.6s17-2.5,21.8-7.7c4.7-5.1,7-11.6,7-19.6s-1.3-10.7-3.8-14
s-5.8-5.7-9.8-7.4c-4-1.6-8.5-2.5-13.5-2.8s-10-0.5-15-0.5h-10.5v-39L3375.2,1147.4z"/>
<path class="st0" d="M3604.1,1139.6c2.4-0.6,4.8-1.1,7.2-1.5s4.9-0.6,7.5-0.6c10,0,19.1,1.8,27.3,5.4c8.2,3.6,15.2,8.5,21,14.7
s10.3,13.6,13.5,22c3.2,8.5,4.8,17.5,4.8,27.2s-2.1,21-6.2,29.8c-4.1,8.9-9.7,16.6-16.8,23s-15.5,11.4-25.2,14.8
c-9.7,3.5-20.1,5.2-31.1,5.2s-21.6-1.8-31.2-5.2c-9.6-3.5-18-8.4-25-14.8c-7.1-6.4-12.7-14-16.8-23c-4.1-8.9-6.1-18.8-6.1-29.8
s0.7-14.5,2.1-20.9c1.4-6.3,3.2-12.2,5.5-17.8c2.3-5.6,5-11,8.1-16c3.1-5.1,6.3-10.4,9.8-15.8l46.5-72.6h54.9L3604.1,1139.6
L3604.1,1139.6z M3640.4,1205c0-9.6-3.2-17.6-9.5-23.9s-14.5-9.5-24.7-9.5s-18.5,3.2-24.8,9.5s-9.5,14.2-9.5,23.9
s3.1,17.5,9.5,23.8c6.3,6.3,14.5,9.5,24.8,9.5s18.5-3.2,24.7-9.5C3637.2,1222.5,3640.4,1214.6,3640.4,1205z"/>
<path class="st0" d="M3811.7,1139.6c2.4-0.6,4.8-1.1,7.2-1.5s4.9-0.6,7.5-0.6c10,0,19.1,1.8,27.3,5.4c8.2,3.6,15.2,8.5,21,14.7
s10.3,13.6,13.5,22c3.2,8.5,4.8,17.5,4.8,27.2s-2.1,21-6.2,29.8c-4.1,8.9-9.7,16.6-16.8,23s-15.5,11.4-25.2,14.8
c-9.7,3.5-20.1,5.2-31,5.2s-21.6-1.8-31.2-5.2c-9.6-3.5-18-8.4-25-14.8c-7.1-6.4-12.7-14-16.8-23c-4.1-8.9-6.2-18.8-6.2-29.8
s0.7-14.5,2.1-20.9c1.4-6.3,3.2-12.2,5.6-17.8c2.3-5.6,5-11,8.1-16c3.1-5.1,6.3-10.4,9.8-15.8l46.5-72.6h54.9l-49.8,75.9H3811.7z
M3848,1205c0-9.6-3.2-17.6-9.5-23.9s-14.5-9.5-24.8-9.5s-18.5,3.2-24.8,9.5s-9.5,14.2-9.5,23.9s3.2,17.5,9.5,23.8
s14.5,9.5,24.8,9.5s18.5-3.2,24.8-9.5S3848,1214.6,3848,1205z"/>
<path class="st0" d="M4049,1105.1h-105v-41.4h155.4v39.6l-84.9,172.8H3962L4049,1105.1z"/>
<path class="st1" d="M1245.4,1130.6c2.1-27.4,4.1-54.7,6.2-81.9c21.6-20.9,43.2-41.3,64.8-61.4c8.6-208.3,17.3-414.9,26.1-619.7
c-23.8-10.1-47.5-20.5-71.3-31.2c1.6-27,3.1-54,4.7-80.9c65.7-0.9,131.4-1.7,197.2-2.5c9.6,43.4,19.2,86.3,28.8,128.9
c26.5,131.7,52.9,259.9,79.2,385.1c30.9-138.8,62-273.6,93.3-404.8c9.1-37.5,18.1-74.8,27.2-111.7c64.8-0.7,129.7-1.3,194.5-1.9
c-2,22.2-4,44.5-6,66.8c-21.5,9.4-43.1,19-64.6,28.8c6.6,177.4,13.1,353.6,19.7,528.6c23.1,5.7,46.3,11.4,69.4,16.9
c-1.7,23.1-3.5,46.3-5.2,69.5c-76.4,15.8-152.7,32.8-228.8,51.2c2.5-23.8,4.9-47.5,7.4-71.2c17.9-16.7,35.9-33.1,53.8-49.3
c-6-141.1-12.1-283.1-18.1-426c-40.5,173.8-80.8,353.9-120.9,540.7c-26.5,12.3-53,25-79.4,37.9c-36.9-175-73.9-356.3-111.3-544.6
c-6.6,156.9-13.2,314.7-19.8,473.6c20.1,6.6,40.1,13,60.2,19.3c-2.4,26.8-4.8,53.7-7.2,80.7c-66.7,18.6-133.2,38.3-199.7,59.2"/>
<path class="st1" d="M1958.3,311.7c2.1-21.3,4.3-42.6,6.4-63.8c82.8-0.7,165.6-1.4,248.4-2c-1.9,20.8-3.8,41.6-5.7,62.5
c-22.7,9.7-45.5,19.5-68.2,29.5c-1.6,162.6-3.1,325.1-4.7,487.8c23.5,5,46.9,10,70.4,14.9c-1.8,21.4-3.5,42.8-5.3,64.3
c-81.7,13.6-163.4,28.5-244.9,44.8c1.2-20.5,2.4-41,3.6-61.5c22.4-15.9,44.9-31.5,67.3-46.9c1.5-166.5,3-333,4.5-499.4
c-24-9.9-48-20-71.9-30.3"/>
<path class="st1" d="M2252.4,304.8c2.5-19.8,5.1-39.5,7.6-59.2c82.3-0.6,164.6-1.1,246.9-1.5c-2.1,18.7-4.3,37.4-6.4,56.1
c-21.8,9.1-43.7,18.4-65.5,27.8c-0.6,60.4-1.2,120.7-1.8,181.1c70-4.3,140-8.1,210-11.6c0.5-57.2,1.1-114.3,1.6-171.5
c-23.6-8.9-47.2-17.9-70.9-27c2-18.5,4-36.9,6-55.3c83-0.4,165.9-0.8,248.9-1.1c-2.1,17.7-4.2,35.4-6.3,53.1
c-22,8.5-43.9,17.2-65.9,25.9c-1.3,144.8-2.6,289.6-3.9,434.4c23.5,5.6,46.9,11.2,70.4,16.8c-1.7,18-3.4,35.9-5.2,53.9
c-82.7,7.3-165.3,15.9-247.9,25.7c1.7-17.5,3.5-34.9,5.2-52.4c21.8-12.6,43.6-25,65.3-37.3c0.6-59.9,1.1-119.8,1.7-179.8
c-70.1,4.5-140.1,9.5-210.1,15.1c-0.6,63.6-1.3,127.1-1.9,190.7c23.8,5.2,47.6,10.4,71.4,15.5c-2.3,19.1-4.6,38.2-6.8,57.3
c-82.1,10.5-164.1,22.3-246.1,35.3c1.5-18.7,3-37.4,4.5-56.1c22-14.2,44-28.1,66-41.8c1.6-155.3,3.1-310.6,4.7-465.8
c-23.9-9.3-47.7-18.8-71.6-28.4"/>
<path class="st1" d="M2865.9,295.1c2.9-17.6,5.8-35.2,8.6-52.8c82.2-0.3,164.4-0.5,246.5-0.7c-2.1,17.1-4.1,34.2-6.2,51.2
c-21.9,8.2-43.9,16.4-65.8,24.7c-0.6,84-1.1,167.9-1.7,251.9c-0.4,55.4,10.6,95.9,33,121.3c22.3,25.5,53.2,37.1,92.5,35.5
c36.1-1.5,64.4-15.1,84.6-40.8c20.3-25.7,30.5-64.3,30.7-115.9c0.5-83.9,0.9-167.9,1.4-251.8c-23.3-8.5-46.6-17-69.9-25.6
c1.9-16.9,3.8-33.9,5.7-50.8c69.3-0.1,138.6-0.2,207.9-0.3c-1.9,16.7-3.7,33.5-5.6,50.2c-21.7,7.9-43.3,15.8-65,23.8
c-0.8,79.9-1.5,159.8-2.3,239.7c-1,85.6-19.2,149.3-54.5,192c-35.3,42.6-88.4,65.5-159.1,69.1c-14.7,0.8-28.2,0.9-40.5,0.4
s-26-2.2-41-5.1c-15-2.9-28.4-7.2-40.2-13c-11.7-5.8-23.4-14.1-35-25c-11.6-10.9-21.2-24.1-28.8-39.6
c-7.6-15.5-13.7-34.8-18.2-57.9c-4.5-23.1-6.7-49.4-6.5-78.6c0.7-92,1.4-184,2.1-275.9c-24.3-8.6-48.6-17.3-72.9-26"/>
<path class="st1" d="M3606.4,257c-22.7,7.4-39.6,18.4-50.8,33s-16.8,29.8-16.9,45.4c0,12.4,3.7,22.7,11.1,31
c7.4,8.3,16.9,12.4,28.4,12.4c5.7,0,11.2-1,16.4-3.1c6,13.6,9,25.4,9,35.4c0,21-6.2,37.4-18.6,49.4c-12.4,12-27.4,18-44.9,18
c-24.8,0-44.7-9-59.5-27.4c-14.9-18.4-22.2-44.2-22.2-77.3c0-15.4,2.3-30.6,6.7-45.7c4.5-15.1,11.5-30.2,21.2-45.3
s23.7-28.6,42.1-40.6c18.4-12,40.1-20.8,65.1-26.4C3597.8,229.5,3602.1,243.3,3606.4,257"/>
<path class="st1" d="M3659.9,737.4c3-46.4,6-92.7,8.9-139.1c18.3,3.2,36.6,6.4,55,9.7c5.3,27.7,10.6,55.4,15.8,83.1
c7.9,13.1,19.7,24,35.4,32.7s32.3,13.2,49.6,13.6c19.4,0.4,36.2-5.4,50.3-17.5s21.1-28.7,21-49.9c0-13.7-4.4-26.3-12.9-37.8
s-19.6-21.5-33.1-30.1c-13.5-8.6-28.4-17.2-44.6-25.9c-16.2-8.7-32.5-18.3-48.9-28.8s-31.3-22.2-44.8-35s-24.5-28.8-33-48
s-12.8-40.8-12.8-64.7c0-22.2,3.4-42.6,10.3-61.4c6.9-18.7,16.2-34.6,27.9-47.6s25.4-24.1,41-33.2c15.6-9.2,32-16,49.2-20.4
c17.2-4.4,34.9-6.6,53.1-6.6c21.9,0,45,4,69.4,12.1c24.4,8.1,42.7,18.4,55,31.1c-3.2,48.2-6.3,96.3-9.5,144.4
c-17.8-3.5-35.5-7-53.3-10.5c-4.3-24.8-8.5-49.5-12.8-74.3c-6.3-11.7-16.7-21.9-31.2-30.7c-14.5-8.8-29.2-13.3-44-13.3
c-16.9,0-30.6,5.5-40.8,16.5c-10.3,11.1-15.4,26.8-15.4,47.3c0,14.5,4.5,27.7,13.4,39.6c8.9,11.9,20.5,22.4,34.9,31.6
s30.2,18.3,47.5,27.4s34.6,19.2,52,30.4s33.3,23.5,47.8,36.9c14.4,13.5,26.1,30.2,35.1,50.2s13.5,42.2,13.7,66.5
c0.1,23.4-3.9,44.9-12.1,64.6c-8.2,19.6-18.9,35.8-32.4,48.7c-13.4,12.8-28.7,23.6-45.9,32.4c-17.1,8.8-34.6,15.1-52.2,18.9
c-17.7,3.8-34.9,5.5-51.7,5.1c-35.4-0.8-67.1-7.6-95.1-20.4c-28-12.8-48-28.5-60-47.5"/>
<path class="st1" d="M4243.2,548.3c-0.3-39.3,4.6-75.9,14.7-109.9s24-63.3,41.8-87.9c17.8-24.6,38.3-45.9,61.6-63.9
c23.3-18,48.3-31.6,75.1-40.6c26.7-9,53.9-13.6,81.5-13.5c39.1,0.1,72,4.1,98.8,12.2c26.8,8.1,52.7,19.3,77.9,33.8
c-3.5,62.6-7.1,125.1-10.7,187.4c-18-3.2-36-6.3-54-9.4c-5.3-33.6-10.6-67.1-15.8-100.6c-29.3-36.8-67.5-55.1-114.6-55.8
c-19.5-0.3-37.3,3.5-53.2,11.4c-16,7.8-30.2,19.9-42.7,36.3c-12.5,16.4-22.1,38.3-28.8,65.7c-6.7,27.5-9.9,59.5-9.6,96.3
c0.4,47.4,6.9,90.5,19.7,129.6c12.7,39.1,31.4,71.1,56.1,96.2c24.6,25.1,53.2,39.4,85.6,42.4c28.2,2.7,51.4-2.9,69.5-16.9
c-0.3-35.1-0.7-70.2-1-105.3c-29.8-10.8-59.7-21.3-89.5-31.6c2-20.1,4.1-40.2,6.1-60.3c79.4,4.6,158.7,9.8,237.9,15.7
c-2,19-4,38-6.1,56.9c-14.8,8.2-29.5,16.4-44.3,24.5c0.5,52,1,104,1.5,155.9c-21,14-49.6,24-85.8,30.2
c-36.2,6.2-71.9,7.3-106.9,3.7c-85.8-9-151.3-40.1-196.3-93.4c-45-53.3-67.8-122.6-68.4-209.5"/>
<path class="st1" d="M4778.7,302.9c2.7-19.4,5.3-38.9,8-58.3c76,0.5,152.1,1,228.1,1.5c-2.4,20.5-4.8,41-7.2,61.4
c-15,8.3-30,16.6-45.1,24.7c28.4,68.7,56.8,138.8,85.2,210.4c26.3-66.7,52.5-134.6,78.8-204c-18.3-10-36.6-19.9-54.9-29.7
c1.9-20.8,3.8-41.6,5.6-62.4c59.9,0.5,119.8,1,179.7,1.5c-2.2,21.8-4.3,43.6-6.5,65.4c-15.4,9-30.8,17.9-46.2,26.6
c-42.1,103.3-84.2,203.1-126.3,299.8c0.6,62.2,1.2,124.3,1.8,186.5c21.7,13.7,43.4,27.7,65.1,41.9c-1.4,19.8-2.7,39.6-4.1,59.4
c-78.5-14.4-157.1-27.6-235.8-39.6c1.6-18,3.3-36,4.9-54c20-8.1,39.9-16.2,59.9-24.4c-0.6-56.9-1.1-113.7-1.7-170.5
c-45.3-106.2-90.6-209-135.9-308.8C4814.3,321,4796.5,311.9,4778.7,302.9"/>
<path class="st1" d="M5296.6,958.1c1.9-21.3,3.8-42.6,5.7-63.9c21.7-7.9,43.4-15.9,65.1-24.1c7.1-173.3,14.2-347.8,21.3-523.6
c-23.9-10.7-47.8-21.1-71.7-31.3c1.4-22.2,2.8-44.4,4.1-66.6c65.7,0.6,131.5,1.2,197.2,1.9c10,40.7,20,81.8,30,123.2
c27.8,127.9,55.5,259,83,393.5c29.6-128.4,59.4-260.4,89.3-396.4c8.7-38.9,17.4-78.2,26.1-117.7c64.8,0.8,129.6,1.6,194.4,2.5
c-1.8,26.9-3.6,53.8-5.4,80.7c-21.4,9.8-42.9,19.4-64.3,28.8c8.2,207.1,16.4,415.8,24.5,626.2c23,17.9,46.1,36.2,69,55
c-1.5,27.7-3.1,55.3-4.6,82.9c-75.5-23.7-151.2-45.8-227.1-66.6c2.2-24.1,4.5-48.3,6.7-72.5c17.7-9.2,35.5-18.6,53.2-28.2
c-7.4-159.5-14.8-317.8-22.3-475.1c-38.7,183.3-77.2,359.6-115.5,529.6c-26.4-0.8-52.9-1.5-79.3-2.3
c-38.7-187.9-77.7-369.2-116.8-544.6c-5.3,140.2-10.5,279.6-15.7,418.2c20.3,14.3,40.5,28.9,60.8,43.7c-2.2,23.7-4.3,47.4-6.5,71.1
C5430.8,986.7,5363.7,971.9,5296.6,958.1"/>
<rect x="1236.6" y="127.6" class="st0" width="4728.3" height="50"/>
<path class="st0" d="M1046.9,669.8c-0.4-7.9-0.9-15.8-1.8-23.7c-6.5-54.8-32.7-98.6-76.9-130.7c-27-19.6-56.3-36.1-84.5-53.9
c-9.6-6-19.3-11.9-29-17.9c0.3-0.8,0.6-1.5,0.8-2.3c10.6,2,21.3,3.3,31.7,6c57.6,15.1,99.3,52.5,136.6,97.2
c-0.2-7.3-0.2-15.1-3.8-20.3c-5.3-7.7-13-13.9-20.1-20.2c-8.2-7.2-17-13.7-25.2-21c-2.3-2-3.2-5.5-4.8-8.4c0.7-0.8,1.4-1.7,2.1-2.5
c9.9,3.6,19.8,7.1,29.7,10.8c12,4.4,24,9,36.1,13.4c7,2.5,8.6-1.5,7.5-7.1c-1.5-7.8-3.4-15.5-5.9-23c-6.5-20.1-19.3-36.7-30.4-54.4
c-8.2-13.2-19.3-22.1-32.8-28.8c-7.6-3.8-14.9-8.2-22.3-12.3c-63-34.4-126.1-68.7-189.1-103.1c-2.1-1.1-4-2.3-8.1-4.6
c6.4-2.6,10.7-4.8,15.2-6.2c21.5-7.1,43.5-8.1,65.7-4.5l0,0c3.7,0.7,7.4,1.4,11.1,2.1c1.5,0.6,3.1,1.1,4.6,1.7
c6.3,2.9,12.5,5.7,18.7,8.7c27.8,13.1,51.7,32.1,75.9,50.4c9.9,7.5,20,14.8,30,22.2c0.6-0.7,1.2-1.3,1.9-2
c-6.1-6.5-11.8-13.4-18.5-19.3c-13.4-11.7-27.5-22.5-41-34.1c-2.7-2.3-5.7-6.4-5.5-9.3c2.6-27.2,5.2-54.5,9.2-81.5
c2.1-14,7.2-27.6,11.4-42.6c-5.3,5.9-9.8,11-14.5,16c-20.7,22.2-43.7,41.5-70.2,56.4c-29.5,16.6-61.6,24.2-95.1,26.1
c-17.3,1-34.4-0.6-50.7-7.5c-9-3.8-9.6-4.2-4.6-12c3.5-5.4,7.6-10.8,12.5-14.9c13.6-11.7,30.7-15.2,47.7-18.6
c22.5-4.5,45.3-7.9,67.6-13.1c21.6-5.1,38.8-16.2,44.2-41.6c-31.5,35.4-72.4,42.8-115.8,44.3c2.2-3.7,4.8-7,8.1-8.8
c9.1-5,18.8-8.9,27.9-13.9c9.5-5.3,19.2-10.5,27.3-17.5c5.4-4.6,9.5-7.4,16.5-5c3.2,1.1,7,0.6,10.6,0.8c0.1-4.3,0.7-8.6,0-12.8
c-0.3-1.9-2.7-4.4-4.7-5c-5.4-1.8-11-3.4-16.6-3.9c-7.9-0.7-16,0.2-23.8-0.7c-2.8-0.3-7.4-4-7.4-6.2c-0.7-25.1-0.1-50.3-1-75.4
c-0.4-11.7-3.4-23.4-5.2-35.1c-1.2,0.1-2.5,0.2-3.7,0.2c0,19.7,0.7,39.3-0.2,59c-0.9,21.2-5.1,41.9-15.2,60.9
c-15.1,28.3-38.2,49.2-63.9,67.4c-18.6,13.1-37.8,25.2-57.1,37.3c-6.1,3.8-6.9,8.4-2.5,12.6c6.4,6.1,13.6,11.6,21.2,16.1
c4.1,2.4,6.5,4.5,7.8,9.3c13.8,47.5,37,89.9,69.8,127.1c3.7,4.2,6.2,10.4,7,16c4.8,35.8,15.1,69.5,34.5,100.2
c4.2,6.7,10.5,13.4,11.2,20.6c3.2,33.3-3.2,65-20.2,94.1C765.7,640,754,658.9,742.7,678c-1.9,3.3-3.3,7-4.9,10.5
c30.5-17.2,48.1-45.6,67-73.7c11.3,12.5,14.3,26.4,15.6,40.6c3.6,39.4-6.3,75.6-27.9,108.5c-3.7,5.6-9,10.2-14.6,16.4
c-7.6-20.1,10.4-35.3,3.5-52.9c-5.5,10.2-9.8,20.7-14.6,31.1c-7.8,16.9-15.1,34.1-24.2,50.2c-7.3,12.9-9.3,12.5-23.6,9.4
c-4.2-1-8.8-0.2-13.3-0.2c-0.2,1-0.4,2-0.5,3.1c3.5,1.8,6.9,3.8,10.6,5.3c5,1.9,8.8,5.4,6.5,10.3c-0.9,1.9-8.4,2.7-11.4,1.1
c-7.4-4-14-9.5-20.8-14.5c-10.7-8-20.6-17.4-32.2-23.8c-11.9-6.7-23-1.7-28.7,10.4c3,0,5.7-0.2,8.4,0c31.7,1.7,57.3,13.7,71.7,43.8
c16.2,33.8,6.8,70.6-23.9,92.5c-2.1,1.5-5.9,2.9-8,2.1c-27.5-10-55.2-19.7-82.2-31c-19.8-8.4-37.6-20.7-51.3-37.6
c-24.9-30.7-20.9-72.5,10.3-98.3c20.4-16.9,42.8-31.4,64.1-47.3c12.4-9.3,22.2-20.8,27.9-37.1c-10.5-2.3-20.2-4.8-30.1-6.4
c-3.2-0.5-7.3,0.4-10.2,2.1c-14.7,8.7-30.8,11.1-47.5,10.8c-12.6-0.2-25.2-1.4-37.7-1.4c-12.6,0-25.3,2.3-37.7,1.1
c-12.8-1.3-26.2-3.8-37.8-9.1c-16.3-7.4-29.4-0.2-44.1,3.7c7.6,20.5,22.2,33.2,38,44.8c17.7,12.9,36,25.1,53.4,38.4
c29.2,22.4,35.9,68.2,11.8,96.2c-10.3,12-23.1,22.7-36.6,31.2c-29.4,18.7-62.6,29-95.6,39.2c-3.1,1-7.8,0.2-10.5-1.6
c-21.8-14.5-31.9-34.9-31.1-61.4c1.2-38.3,29-69.1,67-73c6.7-0.7,13.4-1.5,20.8-2.3c-4.2-7.2-10-11-17.9-11.8
C389,796,381.1,803,373,809.5c-10.3,8.2-20.1,17-30.5,25c-3.3,2.5-7.8,3.7-12,4.7c-1.8,0.4-5.7-1.1-5.9-2.3
c-0.4-2.3,0.4-5.6,1.9-7.3c1.9-2.1,5.2-2.8,7.8-4.3c2.6-1.5,5.2-3.2,8.4-5.3c-7.2-5.8-13.2-2.2-18.9-0.7
c-7.4,1.9-13.1,0.5-16.6-6.6c-9.1-18.4-18.1-36.8-26.9-55.3c-4.8-9.9-9.1-19.9-13.6-29.9c-1.1,0.3-2.1,0.6-3.2,1
c0.7,5.8,1,11.7,2.3,17.3c1.6,6.9,4.6,13.5,5.9,20.5c0.8,3.8-0.6,7.9-1,11.9c-1.2,0.4-2.4,0.8-3.6,1.1c-5.4-7.3-11.5-14.2-16.1-22
c-21.4-35.9-30.4-74.2-21.5-115.7c2-9.3,5-18.8,13.3-26.7c18.1,28,35.9,54.8,64.3,72.5c0-3.4-0.5-6.6-2-9.2
c-5.2-9.1-10.2-18.2-16.1-26.8c-21.7-32-38.1-65.9-39.7-105.5c-0.5-11.8,0.5-22.5,7.7-32.7c21.6-30.7,33.5-65,38.2-102.1
c0.9-6.8,2.7-14.7,7-19.5c33.4-37.8,56.8-81,71.5-129.2c1-3.3,4.4-6.3,7.3-8.6c6.8-5.3,14.6-9.5,20.8-15.5
c4.8-4.6,4.1-9.2-2.7-13.7c-29.8-19.5-61.3-36.5-87.1-61.6c-17.4-17-32.8-35.5-40.4-59.1c-9.7-30.1-10.8-61.3-11.1-92.5
c0-3.3,0.8-6.6,1.1-9.9c-0.8-0.1-1.5-0.1-2.3-0.2c-7.4,37.4-11,75.1-5.5,115.2c-9.3,0.3-17.7,0.3-26,1c-7.3,0.6-14.7,1.3-21.7,3.2
c-5.7,1.5-8.6,10.5-5.9,16.8c2.8,6.8,7.4,2,11.1,1c5.7-1.6,10.1-1.8,14.8,3.1c5.3,5.6,11.7,10.7,18.5,14.4
c9.3,5.1,19.8,8.2,29.2,13.2c6.4,3.3,11.8,8.3,17.7,12.6c-0.5,1-1.1,1.9-1.6,2.9c-20.8-0.2-41-4-60.8-10.1
c-20.4-6.2-37.8-17.6-52.8-32.8c4,14.5,10.4,27.3,25.2,32.4c14.9,5.1,30.2,9.7,45.6,13.2c21.9,5.1,44.2,8.2,65.9,13.7
c15.8,4,28.7,13.4,36.1,28.9c1.6,3.3,3,6.2-1.8,7.8c-8,2.7-16,6.9-24.2,7.5c-16.6,1.2-33.5,1.9-49.9,0.1
c-48.6-5.4-89.8-27-125.2-60.2c-12.7-11.9-25.2-23.9-35.5-33.7c2.5,7.8,7.2,18.4,9,29.5c4.4,29.2,7.6,58.5,11.1,87.8
c0.4,3.3-0.2,7.6-2.2,10c-4.3,5.2-9.7,9.5-14.9,14C102,304.2,88.6,315.4,75.4,327c-2.2,1.9-3.6,4.8-5.3,7.2
c0.6,0.6,1.2,1.1,1.8,1.7c39.6-28.1,75.5-62,122.4-78.8c-0.4-0.9-0.9-1.8-1.3-2.7c-18.4,7.9-36.8,15.8-56.6,24.2
c7-28.5,21.2-56.5,4.8-86.1c30.1,19.9,57.1,43.6,93.1,52.6c-5.3,1.7-10.5,3.5-15.8,5.2c24.5-2.5,47.4,1.4,69.1,12.2
c-1.5,2.3-2.8,3.2-4.2,4c-26.6,14.1-53.4,27.9-79.9,42.4c-44.2,24.2-88.3,48.7-132.2,73.5c-9.9,5.5-20.9,9.7-27.1,20.6
c-4.4,7.6-9.7,14.6-14.7,21.8c-11.4,16.6-21.2,34-25.6,53.9c-1.2,5.4-1.3,11.1-1.9,16.7c5.8-0.6,12-0.2,17.3-2.1
c12.3-4.5,24.1-10.3,36.4-15.1c6.6-2.6,13.6-4,20.4-6c0.7,1.1,1.5,2.3,2.2,3.4c-6.4,6.4-12.4,13.2-19.3,19.1
c-8.9,7.7-18.9,14.1-27.3,22.3c-7.1,6.9-11.1,15.8-8.5,29c22.9-27.5,45.4-52.3,73.7-70.9c30.1-19.7,76.3-35.9,95.5-31.9
c-10.1,6.2-19.5,11.8-28.6,17.6c-27.4,17.2-55.5,33.2-81.9,51.9C38,543.8,10.5,586.2,2.9,640.3c-1.7,11.9-2,24-2.9,36
c1.1,0.1,2.1,0.2,3.2,0.4c0.5-2.5,1.3-5,1.4-7.4c0.6-20.8,6.7-40,17.4-57.6c12.5-20.6,25.8-40.8,39-60.9c1.5-2.3,4.4-3.9,7-5.3
c12.7-7.1,25.6-13.7,38.1-21.1c19.3-11.4,38.1-23.9,57.7-34.8c10.3-5.7,21.9-8.8,33-13.2c0.6,0.8,1.1,1.5,1.7,2.2
c-0.6,2.8-0.9,5.7-2,8.3c-6.3,14.6-13.1,29-19.2,43.6c-2.2,5.3-3.2,11.3-4.7,16.9c1,0.4,2.1,0.8,3.1,1.1
c4.3-6.3,8.9-12.4,12.9-18.8c5.8-9.3,10.5-19.3,17-28.1c3.7-5,9.6-8.7,15.2-11.8c3.6-2,5.9,0.8,4.4,4.7c-1,2.5-2.8,4.8-4.7,6.7
c-5.2,5.3-10.7,10.3-15.9,15.5c-3.6,3.6-5.6,7.2-0.9,11.9c1.3,1.3,1.4,5.3,0.4,7.2c-3.3,6.3-7.3,12.2-11.1,18.2
c-20.6,32.4-49.8,56.7-77.8,82c-20.8,18.8-41.3,37.8-57,61.2c-4.8,7.2-8.7,15.1-13,22.7c16.8-14.3,27-35.3,50.6-41.7
c-1.5,7.8-3.9,14.9-6.6,21.9c-8.3,22-17,43.9-25,66.1c-9.5,26.5-15.1,54.4-13.5,82.2c2.2,37.9,6.6,75.9,13.2,113.3
c10.6,60.9,23.5,121.5,35.9,182.1c3.4,16.8,8.2,33.4,12.3,50c0.4-0.1,0.8-0.2,1.1-0.3c-0.9-11.5-1.9-23-2.8-34.5
c9.1,23.5,9.2,49.5,22,74.3c0.5-3.8,0.8-5.1,0.8-6.4c-1.1-33.4-2.2-66.8-3.7-100.2c-0.5-11.2-2-22.3-3.6-33.4
c-6.1-43.9-22.8-84.5-38-125.6c-19.6-52.8-30.3-106.7-22.5-163.4c9.5-69,38.7-128,87.9-177.1c2.7-2.7,6-4.8,9.6-7.6
c0.3,2,0.6,2.6,0.4,3c-0.8,1.7-1.6,3.4-2.5,5.2c-16.3,33.6-30.4,68-36,105.3c-4.3,28.5-3.4,56.3,7.6,83.5
c5.3-19.6,10.1-39,15.7-58.2c7.1-24.5,18-47.1,36.8-65.1c2.5-2.5,5.8-4.2,10.3-7.4c0.4,5.9,0.8,9.3,0.8,12.8
c0.1,25.9,2.9,51.5,11.6,76c5.2,14.3,9.3,28.5,8.6,44c-0.4,7.6,1.6,15.2,2.5,22.8c1.9,16,4.2,32,5.4,48.1
c3.1,43.1-8.2,84.3-18.5,125.5c-11.6,46.2-23.7,92.3-34.9,138.7c-5.2,21.3-4.8,43.1-3,65c1.5-2.6,2.4-5.4,3.1-8.2
c7.4-29.2,15.3-58.3,22.1-87.6c8.5-36.8,17.2-73.6,23.9-110.8c5.9-32.2,10.1-64.9,13.5-97.5c3-28.3,3.6-56.9-1.7-85.1
c-2.9-15-7.5-29.6-10.7-44.5c-5.3-24.9-10.5-49.8-14.8-74.9c-3.6-21.1-5.4-42.5,3-63.2c1.4-3.6,3.8-6.7,5.7-10.1
c4.9,39.5-3,79.2,11.9,117.3c11.7-6.7,12.4-6.5,16.8,5.1c1.9,5.2,4,10.2,5.7,15.4c25,75.7,36.3,153.2,31.1,233.1
c-2,30.5-7.1,60.3-14.3,89.8c-13,53.4-31.2,105.7-34.9,161.1c-1.3,20.2,0.4,40,5.2,59.8c0.9-106.7,29.1-205.9,79.9-299.1
c1,1.5,1.1,2.9,0.8,4.1c-8.6,37.6-17.4,75.2-26,112.9c-9.3,40.2-14.5,80.8-9.4,122.1c2,15.8,6.6,30.7,14.9,44.7
c0.6-8.1,1.4-16.8-1.6-23.7c-12.4-28.3-11.4-57.8-6.4-86.5c6.7-38.3,16.9-75.9,25.8-113.7c7.2-30.9,14.9-61.7,21.7-92.7
c2.3-10.7,2.4-21.9,3.5-32.8c2.3,2,3.3,4.2,3.8,6.4c6.1,24.2,7.4,49,3.4,73.4c-5.3,31.5-12.6,62.8-19.9,94
c-9.7,41.6-20.1,83.1-13.8,126.5c2.3,15.7,7,30.6,14.4,44.6c0.8-1.4,0.9-2.7,0.8-3.9c-2-23.2-5.8-46.4-5.5-69.5
c0.4-28.1,3.2-56.3,7-84.1c3.7-27.2,11.8-53.5,25.2-77.8c3.8-6.9,6.7-16.3,18.3-11.6c1.1,0.4,2.7-0.2,4-0.6c6-1.7,8.2,1.5,9.8,6.7
c2.3,7.8,4.7,15.8,8.2,23.1c2.5,5.3,1.9,8.9-1.2,13.5c-30.2,45-56.5,91.6-60.4,147.3c-1,13.9-0.2,27.9-0.2,41.9
c1,0,1.9,0.1,2.9,0.1c0.8-3.4,1.6-6.7,2.3-10.1c7.8-37.5,18.1-74.1,39.9-106.3c2-2.9,4.6-5.4,8.5-9.9c5.4,13.9,9.8,25.6,14.5,37.2
c8.9,22.1,17.5,44.4,27.4,66c3.7,8.2,10.8,14.7,16.4,22c0.6-0.5,1.2-1,1.8-1.5c-40.3-54.2-47.7-175.5-41.2-227.6
c2.4,0.6,4.9,0.8,7,1.7c13.4,5.6,27.3,10.3,39.9,17.3c49.2,27.4,97,23.7,144.4-4.2c15.6-9.2,33-12.5,50.9-16.9
c0.8,18.2,2.5,35.1,1.8,51.9c-0.9,21.3-3.4,42.6-6.1,63.7c-4,31.7-11,62.6-24.8,91.6c-3.7,7.8-8.6,15.1-12.9,22.5
c11.2-8.5,19-19.3,24-31.9c8.9-22.4,17.4-45,26.1-67.4c2.9-7.5,6.2-14.9,9.3-22.3c1,0,2-0.1,3-0.1c28.6,37.3,39.4,81.7,48.5,128
c0.8-3.8,1.7-6.4,1.9-9c4.1-47.9-6.5-92.6-30.2-134.3c-9.6-16.9-19.8-33.5-30-50.1c-2.8-4.6-3.3-8.4-0.9-13.7
c3.7-7.9,5.8-16.5,9-24.6c0.7-1.9,2.9-4.7,4.4-4.7c11.9-0.2,24.2-0.6,28.2,14.7c0.7,2.6,2.7,4.8,3.8,7.2
c16.5,37,24.4,76.3,25.9,116.4c1.2,30.8-1.6,61.8-2.8,92.7c-0.2,5.4-1.1,10.8-1.7,16.2c3-2.3,4.7-4.8,5.7-7.6
c11.8-31.5,15.3-64,9.1-97c-6.2-32.8-14.2-65.2-21.5-97.9c-8.4-37.7-17.6-75.4-12-114.5c1.5-10.3,3.9-20.4,5.9-30.5
c3.1,13.8,2.7,27.4,5.7,40.3c9,39.2,18.5,78.4,29.3,117.1c10.2,36.8,18.4,73.8,20.2,112c1,20-0.6,39.8-8.9,58.7
c-3.2,7.2-6.3,15.6,0.3,25.3c3.7-11.3,7.8-20.9,10.1-31c9.5-42.4,4.9-84.5-4.4-126.2c-8.2-37.2-17.4-74.2-26-111.2
c-1.2-5.2-1.5-10.5-2.2-15.8c0.7-0.1,1.4-0.2,2.1-0.4c49.6,94.3,78.6,194,78.2,301.5c7.6-30.3,7.8-61.2,2.2-91.3
c-6.9-37.4-16.9-74.2-26.3-111c-9.8-38-19-76.2-19.8-115.5c-0.7-31.7,1.1-63.5,2.9-95.2c2.5-42.4,13.2-83.2,25.6-123.6
c2.9-9.4,6.2-18.7,9.5-28c2.9-8.3,5-9,12-4.3c0.8,0.6,1.9,0.8,3,1.3c0.9-1.1,1.7-1.9,2.1-2.9c0.8-2,1.7-4,2.2-6.1
c7.7-29,7-58.6,5.8-88.1c-0.2-6.2,0-12.4,0-18.5c1.1-0.1,2.1-0.2,3.2-0.4c1.6,2.8,3.3,5.4,4.6,8.3c9,19.6,8.2,40.2,4.3,60.5
c-6.8,35.4-14.3,70.7-22.4,105.8c-6.4,27.5-7.8,55-6.8,83.1c1.6,42.6,7.6,84.6,16.5,126.1c12.9,60,27.7,119.6,41.8,179.3
c1.3,5.7,3.2,11.4,4.8,17.1c0.7-0.1,1.4-0.1,2.1-0.2c0-11.9,1.8-24.2-0.3-35.7c-6.4-35.2-13.5-70.4-21.7-105.2
c-9.7-41.4-21.4-82.2-30.8-123.6c-4.9-21.3-7.6-43.1-4.6-65.2c3-22.2,4.7-44.6,7.2-66.9c1.3-11.1,1.3-22.7,5-33
c10.9-30.3,16.3-61.3,15.9-93.4c0-2.3,0.6-4.5,1.3-9.4c6,5.5,10.7,9.1,14.6,13.4c20.2,22.2,29.7,49.6,37.3,77.8
c3.4,12.7,6.3,25.6,9.5,38.3c10.9-25.3,12.8-51.1,8.9-77.6c-5.4-36.8-17.5-71.2-34.4-104.2c-1.9-3.7-3.4-7.7-5-11.6
c4.9,1.7,8.4,4.2,11.4,7.2c33.5,32.5,57.5,71,72.7,115c19.1,55.2,22.7,111.7,8.4,168.2c-8.8,34.7-21.9,68.3-33.6,102.1
c-10.5,30.1-19.8,60.5-22.7,92.3c-2.9,31.1-4.7,62.2-6.6,93.4c-0.7,10.7-0.1,21.6-0.1,32.4c1.5,0.2,3.1,0.4,4.6,0.6
c6.1-25.2,12.3-50.4,18.5-75.6c0.3,0.1,0.6,0.1,1,0.2c-1,12.1-1.9,24.2-2.9,36.4c1.4-1,2-2.3,2.3-3.5
c13.9-64.8,28.6-129.4,41.4-194.4c7.2-36.7,12-74,16.2-111.2c2.3-20.3,3.2-41.2,1.5-61.5c-3.3-39.7-17.9-76.5-33.1-113.1
c-4.2-10.2-8-20.5-12-30.7c0.8-0.5,1.7-1,2.5-1.5c20.2,6.1,30.1,24.9,45.2,37.8c-0.4-3.5-1.5-6.4-3-9.1
c-12.7-22.5-30.6-40.6-49.2-58.1c-21.9-20.7-45.1-40.2-65.7-62.1c-14.3-15.2-25.3-33.6-37.5-50.7c-1-1.3-0.6-5,0.6-6.2
c4.8-5,3.7-9-1-13.2c-4.7-4.2-9.5-8.3-13.7-13c-3.2-3.6-5.4-8-8-12c11-1.3,17.2,3.7,21.7,10.9c6.1,9.7,11.4,20,17.5,29.8
c3.8,6,8.3,11.5,12.5,17.3c0.8-0.5,1.6-1,2.4-1.5c-3.3-24.6-22.5-43.9-25.8-70c4.8,1.1,8.2,1.7,11.6,2.7c23.6,7.4,43.9,21,64.6,34
c16.5,10.3,33.2,20.3,50.4,29.4c6.7,3.5,11.3,7.8,15.1,14.2c5.3,9.2,11.5,17.9,17.5,26.8c15.8,23.2,30.9,46.7,33.7,75.7
c0.6,5.7,1.7,11.4,2.5,17.2c0.8,0,1.5-0.1,2.3-0.1C1046.5,674.8,1047,672.3,1046.9,669.8z M906.9,191.4c-16.2,29.7-3.6,57.9,4.1,86
c-0.1,0-0.3-0.1-0.4-0.2c-7.6-27.9-20-56-4-85.5c-29.7,20.9-56.8,44.4-93.1,51.7c0-0.1,0-0.2,0-0.2
C850,236,877.1,212.3,906.9,191.4z M209.7,416.6c-20.5,13.4-43.8,19.8-67.4,25.2c-24,5.5-48.3,10.1-71.9,16.6
c-15.2,4.2-29.6,11.3-44.4,17.1c-2.7,1.1-5.3,2.1-9.9,3.8c3.4-17.4,11.7-29.2,22.9-39.4C51.1,428.9,63.3,418,75,406.5
c3.7-3.7,5.1-9.5,8.3-13.9c2.1-2.9,5-6.2,8.1-6.9c36.2-8.7,68.6-25.5,100-44.9c32.8-20.3,65.7-40.9,103.7-50.7
c7.4-1.9,14.9-3.6,22.5-4.9c2.5-0.4,6.3,0.1,7.4,1.7c1,1.5,0,5.4-1.3,7.4c-3.1,4.5-7.2,8.2-10.3,12.7c-5.8,8.4-10.9,17.4-16.9,25.8
c-2.7,3.8-6.5,7.4-10.6,9.7c-22.3,12.3-45,23.9-67.4,36.2c-20.5,11.2-40.6,23-56,41.2c-1,1.2-1.9,2.5-2.2,5.3
c2.6-0.8,5.5-1.1,7.8-2.4c10.3-5.8,20.4-11.7,30.5-17.9c8.8-5.4,18.1-7.2,29.8-3.7C222.2,406.2,216.4,412.1,209.7,416.6z
M510,781.3c-10.6-12.4-19.7-26.1-28.7-39.7c-4.8-7.1-8-15.2-12.6-24.3h0c4.6,9.1,7.9,17.2,12.6,24.2
C490.4,755.1,499.5,768.8,510,781.3c9.3,10.9,15.1,10.6,25.8,1.3c19.8-17.1,29.8-40.8,41.1-63.5c0,0,0-0.1,0-0.1c0,0,0,0.1,0,0.2
c-11.3,22.7-21.2,46.4-41.1,63.5C525.1,791.9,519.3,792.2,510,781.3z M660.5,988.4c-0.1,2.1-1.5,4.2-2.4,6.3
c-1.5-1.9-3.2-3.7-4.6-5.7c-0.6-1-0.6-2.4-0.9-3.6c-0.6-0.1-1.3-0.1-1.9-0.2l-0.1,0c-1.6,7.6-3.6,15.1-4.6,22.7
c-1.1,7.8-1.7,15.8-1.7,23.7c0,5.5-2.4,8.4-7.5,8.7c-14.4,1-28.7,2.3-43.1,2.5c-9.8,0.2-19.7-0.8-29.4-1.7
c-35.6-3.2-71-0.3-106.4,2.6c-15.9,1.3-32.2-1.1-48.3-2.5c-5.2-0.5-8-4-8.2-10.3c-0.4-12.5-1.8-25-3.1-37.5
c-0.3-3.1-1.7-6.1-2.9-10c-2.8,5-0.8,12.4-9.8,11.1c0-8.8-0.2-17.5,0.2-26.1c0-1.9,1.6-4.4,3.2-5.3c20.1-11.1,38.7-25.6,61.8-29.9
c13.7-2.5,27.1-3.5,39.7,6.7c5.2,4.2,14.8,3.5,22.5,4.2c7.1,0.7,14.4,0.9,21.6,0.2c7.7-0.8,17.1-0.5,22.5-4.7
c13-10.1,27.5-10.5,40.5-5.7c20.6,7.6,40,18.7,59.7,28.8c1.9,1,3.1,4.4,3.3,6.9C660.9,975.7,660.8,982.1,660.5,988.4z M819.1,399.5
c9.4-2.1,18-1.1,26,3.5c9.2,5.4,18.1,11.4,27.3,16.8c4,2.3,8.6,3.7,12.9,5.5c0.5-0.8,1-1.5,1.5-2.2l0,0c-1.5-2-2.8-4.1-4.5-5.9
c-21.7-23.5-50.4-36.5-77.9-51.2c-15.3-8.1-30.5-16.2-45.4-25c-4-2.4-6.7-7.2-9.5-11.3c-5.5-7.7-10.5-15.8-16.1-23.5
c-2.7-3.7-6.7-6.6-9.3-10.4c-1.6-2.5-1.7-5.9-2.5-8.9c2.8-0.6,5.9-2.3,8.4-1.7c15.6,4,32.1,6.5,46.5,13.4
c28.2,13.4,55.5,28.6,82.4,44.4c30.9,18.1,62.3,34.5,97.4,42.7c2.5,0.6,5.6,3.1,6.5,5.3c5.5,14.9,17.3,24.4,28.4,34.5
c9.7,8.9,19.8,17.4,28.2,27.5c5.5,6.7,8.3,15.7,12.2,23.7c-0.6,0.6-1.2,1.1-1.9,1.7c-1.9-0.5-4-0.7-5.6-1.6
c-28.1-15.1-58.9-21.6-89.7-28.8c-27.9-6.5-55.3-15.1-82.8-23.4C841,421.5,823.8,407.5,819.1,399.5z"/>
<path class="st0" d="M465,250.9c6.1,1.9,9.4,5.1,12.5,10.4c7.5,12.7,14.6,25.9,24.1,37c16.8,19.6,28,19.5,44.8-0.5
c9.4-11.2,16.2-24.6,23.8-37.2c2.8-4.7,5.8-7.6,11.4-9.3c33-10,59.2-29.6,77.2-59.1c27.3-44.9,34.9-93.7,27.1-145.2
c-1.4,85.4-34.2,142.4-62.4,159c11.2-29.4,16.6-58.7,18-88.5c1.3-29.7-0.7-59.3-11.2-87.6c-0.7,13.4-0.9,26.7-0.7,40.1
c0.3,23.8,2.1,47.6,0.8,71.3c-0.6,11.8-6.3,23.6-10.8,34.9c-1.9,4.7-6.9,8.2-10.5,12.2c-0.8-0.4-1.7-0.8-2.5-1.2
c-2.9-64.5-9.7-128-44.6-185.9c6.3,18,13,34.5,17.8,51.5c8.5,29.5,9,59.9,8.5,90.4c-0.3,15.7-4.9,30.1-11.1,44.3
c-1,2.2-2.8,4-4.3,6c-0.8-0.2-1.6-0.4-2.4-0.6c-4.8-42.8-5.5-86.2-17-128.1c-0.9,2.7-1.7,5.4-1.4,8c1.2,11.4,2.3,22.9,4.2,34.2
c2.1,12.8,4.8,25.5-0.9,39.7c-10.5-13.7-21-27.4-31.9-41.6c-10.9,14.2-21.3,27.9-31.8,41.6c-0.9-0.4-1.8-0.8-2.7-1.2
c-0.6-3.4-2-6.9-1.6-10.2c2.1-15.4,5-30.7,6.9-46.2c1-8,0.2-16.1,0.2-24.2c-0.9,0-1.8,0-2.7,0c-6.3,41.9-12.5,83.8-18.7,125.7
c-9.7-18.2-14.3-37.2-14.6-57C457.9,88.5,464.2,45,484.1,4c-7.8,13.7-15.4,27.6-20.7,42.3c-14,38.9-20.1,79.5-21.7,120.8
c-0.3,6.6-1.4,13.2-2.1,19.7c-0.8,0.2-1.7,0.5-2.6,0.8c-3.5-4.3-7.9-8.2-10.5-13c-13.8-25.3-12.3-52.8-11-80.3
c1-21.5,2.2-42.9,3.3-64.4c-1.1,0-2.3,0-3.4,0c-16.7,59.2-12,117.8,6.7,176.3c-24.4-19.2-38.1-45.1-47.5-73.4
c-9.4-28.3-13.5-57.6-13.2-87.5c-1.6,10.9-3.3,21.7-3.5,32.6c-0.5,27.6,4,54.6,13.7,80.5C388.5,203.6,417.9,236.3,465,250.9z"/>
<path class="st0" d="M670.6,527.7c34.8,9.5,62.5-34.2,65.2-63.6c-1.1,0.4-2.5,0.5-3.1,1.1c-6.7,7.3-13,5.7-20.9,1
c-11.6-6.9-24.6-11.1-38.5-8.6c-31.6,5.7-51.9,25.5-65.4,53.4c-2,4.1-4,8.1-6.9,14.2c-2.9-4.8-5.5-7.4-6.2-10.5
c-3.3-14.9,6.6-25.7,13.4-37.4c-4.2-3.8-7.9-7.2-13.6-12.4c-1.9,7.8-3.5,13.3-4.6,19c-1.4,7.3-0.3,15.5-8,20.6
c-1.2,0.8-1.6,4.7-0.8,6.4c3.3,7.2,7.6,13.9,10.7,21.2c4.1,9.7,7.8,19.7,10.8,29.8c0.8,2.7-1.7,6.3-2.7,9.5
c-2.8-1.3-5.8-2.4-8.4-4.2c-2.3-1.6-4-4-6-6.1l0,0c-1,0.6-2,1.2-3.1,1.8c3.4,7.5,6.3,15.3,10.2,22.6c13.2,24.5,32.1,46,39.7,73.5
c0.1,0.3,0.8,0.4,2.2,1c0-4.9,1-9.7-0.2-13.8c-4.8-17.1-10.2-34.1-15.5-51c-5.4-17.4-8.4-34.7,1.1-51.8c3.3-5.9,6.9-8.3,12.8-2.9
c1.8,1.7,4.7,2.1,9.3,4c-2-7.8-3.4-13.1-4.7-18.3c-1.3-5.5-0.4-8.9,6.5-9.1c20.3-0.6,38.5-7.5,54.6-20c1.9-1.5,4.2-2.6,7.8-4.8
C700.8,511.8,687.1,520.6,670.6,527.7z M661.6,503.8c-10.5,3.4-15.7,0.1-22.9-14.1h33C673.2,498,668.2,501.7,661.6,503.8z"/>
<path class="st0" d="M413.7,660.1L413.7,660.1c0,0,0,0.1,0,0.1L413.7,660.1z"/>
<path class="st0" d="M448.8,543.6c3.6-8.5,9.3-16.1,13.8-24.3c3.4-6.2,7.3-12.6-1.1-18.3c-1.5-1-2.3-3.7-2.7-5.8
c-1.3-6.1-1.8-12.4-3.3-18.5c-0.8-3.2-3-6-4.5-9c-3.4,2.8-7.4,5.2-10.1,8.6c-1,1.3,0.7,5.2,1.7,7.6c3.2,7.2,7.4,14,9.9,21.4
c2.4,7,0.2,13.4-7.2,18.2c-1.9-3.5-3.7-6.5-5.1-9.6c-6.4-14.5-15.3-27.3-27.4-37.6c-22.4-19-48.1-25.9-74.4-11.6
c-9.4,5.1-17.5,8.2-26.3,0.1c-0.2-0.1-0.9,0.4-1.4,0.6c4.4,38.5,38.9,71.8,65.2,62.4c-15.8-7.2-30-15.8-35.5-35.2
c2.3,1.2,3.3,1.6,4.1,2.3c17.9,13.5,37.8,21.8,60.6,22.3c5.9,0.2,5.5,4.2,4.6,8.2c-1.3,5.5-2.9,10.9-4.4,16.3
c0.6,0.7,1.3,1.4,1.9,2.1c2.7-1.4,5.9-2.3,8-4.4c4.2-4.1,7.2-3,9.9,1.3c5.9,9.3,9.3,19.9,7.1,30.5c-3.4,16.5-8.4,32.8-13.4,49
c-4,13-8.4,25.8-5.2,39.9c8.7-36.7,40.3-61.7,49.2-98.3c-3.7,2-6.2,5-9.2,7.3c-1.8,1.3-4.8,2.7-6.5,2.1c-1.5-0.6-2.9-4.1-2.6-6.1
C445.5,557.8,446,550.2,448.8,543.6z M388.7,504.4c-9.1-1-12.8-4.8-13.7-14.9h33C403.8,501.9,399.1,505.4,388.7,504.4z"/>
<path class="st0" d="M523.6,487.6c3.3-18.9,5.5-37.9,0.2-56.8C515.8,449.9,521.2,468.8,523.6,487.6z"/>
</svg>
</template>