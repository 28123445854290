<template>

  <dx-scroll-view height="100%" width="100%" class="customer-app">
    <div class="customer-app-header">
      <div class="customer-app-content">

        <div class="grid-x align-middle" style="height: 50px;">
          <div class="shrink cell">

            <div v-if="stakeholder == 'msa'" class="app-logo logo-msa">
              <LogoMsa />
            </div>
            <div v-if="stakeholder == 'tgym'" class="app-logo logo-tgym">
              <LogoTgym />
            </div>
            <div v-if="stakeholder == 'dynamic'" class="app-logo logo-dynamic">
              <LogoDynamic />
            </div>
            <div v-if="stakeholder == 'lasuite'" class="app-logo logo-lasuite">
              <LogoLasuite />
            </div>
            <div v-if="stakeholder == 'x30'" class="app-logo logo-x30">
              <LogoX30 />
            </div>
            <div v-if="stakeholder == 'profitfitness'" class="app-logo logo-profitfitness">
              <LogoProfitfitness />
            </div>
            <div v-if="stakeholder == 'physioplus'" class="app-logo logo-physioplus">
              <LogoPhysioplus />
            </div>
            <div v-if="stakeholder == 'goldsgym'" class="app-logo logo-goldsgym">
              <LogoGoldsgym />
            </div>
            <div v-if="stakeholder == 'fitx'" class="app-logo logo-fitx">
              <LogoFitx />
            </div>
            <div v-if="stakeholder == 'kuoni'" class="app-logo logo-kuoni">
              <LogoKuoni />
            </div>
            <div v-if="stakeholder == 'chilihealth'" class="app-logo logo-chilihealth">
              <LogoChilihealth />
            </div>
            <div v-if="stakeholder == 'fitnessunited'" class="app-logo logo-fitnessunited">
              <LogoFitnessunited />
            </div>
            <div v-if="stakeholder == 'neogate'" class="app-logo logo-neogate">
              <LogoNeogate />
            </div>
            <div v-if="stakeholder == 'christoppark'" class="app-logo logo-christoppark">
              <LogoChristoppark />
            </div>
            <div v-if="stakeholder == 'besttraining'" class="app-logo logo-besttraining">
              <LogoBesttraining />
            </div>
            <div v-if="stakeholder == 'quantumgym'" class="app-logo logo-quantumgym">
              <LogoQuantumgym />
            </div>
            <div v-if="stakeholder == 'gym365'" class="app-logo logo-gym365">
              <LogoGym365 />
            </div>
            <div v-if="stakeholder == 'trainiq'" class="app-logo logo-trainiq">
              <LogoTrainiq />
            </div>
            <div v-if="stakeholder == 'lfit'" class="app-logo logo-lfit">
              <LogoLfit />
            </div>
            <div v-if="stakeholder == 'meintraining'" class="app-logo logo-meintraining">
              <LogoMeintraining />
            </div>
            <div v-if="stakeholder == 'fitwork'" class="app-logo logo-fitwork">
              <LogoFitwork />
            </div>
            <div v-if="stakeholder == 'fitphysiotitlis'" class="app-logo logo-fitphysiotitlis">
              <LogoFitphysiotitlis />
            </div>
            <div v-if="stakeholder == 'lifefitness24'" class="app-logo logo-lifefitness24">
              <LogoLifefitness24 />
            </div>
            <div v-if="stakeholder == 'fightindustry'" class="app-logo logo-fightindustry">
              <LogoFightindustry />
            </div>
            <div v-if="stakeholder == 'fitnessemotion'" class="app-logo logo-fitnessemotion">
              <LogoFitnessemotion />
            </div>
            <div v-if="stakeholder == 'moveon'" class="app-logo logo-moveon">
              <LogoMoveon />
            </div>
            <div v-if="stakeholder == 'sportstudio'" class="app-logo logo-sportstudio">
              <LogoSportstudio />
            </div>
            <div v-if="stakeholder == 'fitnessfactory24'" class="app-logo logo-fitnessfactory24">
              <LogoFitnessfactory24 />
            </div>
            <div v-if="stakeholder == 'mihusgym'" class="app-logo logo-mihusgym">
              <LogoMihusgym />
            </div>
            
          </div><!-- /shrink cell -->

          <div class="auto cell">
            &nbsp;
          </div><!-- /auto cell -->

          <div class="shrink cell" style="width: 50px; border: 1px solid transparent;">

            <!-- header-toolbar -->
            <header-toolbar />
            <!-- /header-toolbar -->

            <!-- user widget -->
            <!-- <div class="grid-x align-middle hide">
              <div class="shrink cell" style="margin-right: 10px;">
                <div class="user-thumb" style="width: 30px; height: 30px;" v-if="currentUser.Avatar"
                  v-bind:style="{ 'background-image': 'url(' + currentUser.Avatar + ')' }" >
                </div>
              </div>
              <div class="shrink cell" style="width: 150px;">
                <p class="ck-overflow-ellipsis" style="margin: 0; font-size: 12px;">{{ currentUser.Email }}</p>
              </div>
            </div> -->
            <!-- /user widget -->

          </div><!-- /shrink cell -->
          
        </div><!-- grid-x -->

      </div><!-- customer-app-content -->
    </div><!-- customer-app-header -->

    <div class="customer-app-content">
      <div class="app-content-slot">
        <slot />
      </div>
    </div><!-- customer-app-content -->

    <div class="customer-app-footer">
      <div class="customer-app-content">

        <ul class="customer-app-footer-nav hide">
          <li v-for="(item, index) in currentUser.Navigation" :key="index">
            <RouterLink :to="item.path">
              <AppAlertFooter
                :count="1"
              />
              <i class="footernav-icon" :class="item.icon"></i>
              <p>{{ item.text }}</p>
            </RouterLink>
          </li>
        </ul>

        <ul class="customer-app-footer-nav">
          <li>
            <RouterLink :to="'/dashboard'">
              <AppAlertFooter
                :count="countAlertDashboard"
              />
              <i class="footernav-icon fa-light fa-right-to-bracket"></i><p>Check-in</p>
            </RouterLink>
          </li>
          <li>
            <RouterLink :to="'/myprofile'">
              <AppAlertFooter
                :count="countAlertMyprofile"
              />
              <i class="footernav-icon fa-light fa-user"></i><p>Meine Daten</p>
            </RouterLink>
          </li>
          <li>
            <RouterLink :to="'/trainingplan'">
              <AppAlertFooter
                :count="countAlertTrainingplan"
              />
              <i class="footernav-icon fa-light fa-dumbbell"></i><p>Training</p>
            </RouterLink>
          </li>
          <li>
            <RouterLink :to="'/contract'">
              <AppAlertFooter
                :count="countAlertContract"
              />
              <i class="footernav-icon fa-light fa-file-contract"></i><p>Verträge</p>
            </RouterLink>
          </li>
          <li>
            <RouterLink :to="'/myshop'">
              <AppAlertFooter
                :count="countAlertShop"
              />
              <i class="footernav-icon fa-light fa-cart-shopping"></i><p>Shop</p>
            </RouterLink>
          </li>
        </ul>

      </div><!-- customer-app-content -->
    </div><!-- customer-app-footer -->

  </dx-scroll-view>

</template>

<script>
import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

import DxScrollView from "devextreme-vue/scroll-view";
import HeaderToolbar from "../components/app-header-toolbar";
import AppAlertFooter from "../appdetail/app-alert-footer";

import LogoMsa from '../svg/logo-msa-login.vue';
import LogoTgym from '../svg/logo-tgym.vue';
import LogoDynamic from '../svg/logo-dynamic.vue';
import LogoLasuite from '../svg/logo-lasuite.vue';
import LogoX30 from '../svg/logo-x30.vue';
import LogoProfitfitness from '../svg/logo-profitfitness.vue';
import LogoPhysioplus from '../svg/logo-physioplus.vue';
import LogoGoldsgym from '../svg/logo-goldsgym.vue';
import LogoFitx from '../svg/logo-fitx.vue';
import LogoKuoni from '../svg/logo-kuoni.vue';
import LogoChilihealth from '../svg/logo-chilihealth.vue';
import LogoFitnessunited from '../svg/logo-fitnessunited.vue';
import LogoNeogate from '../svg/logo-neogate.vue';
import LogoChristoppark from '../svg/logo-christoppark.vue';
import LogoBesttraining from '../svg/logo-besttraining.vue';
import LogoQuantumgym from '../svg/logo-quantumgym.vue';
import LogoGym365 from '../svg/logo-gym365.vue';
import LogoTrainiq from '../svg/logo-trainiq.vue';
import LogoLfit from '../svg/logo-lfit.vue';
import LogoMeintraining from '../svg/logo-meintraining.vue';
import LogoFitwork from '../svg/logo-fitwork.vue';
import LogoFitphysiotitlis from '../svg/logo-fitphysiotitlis.vue';
import LogoLifefitness24 from '../svg/logo-lifefitness24.vue';
import LogoFightindustry from '../svg/logo-fightindustry.vue';
import LogoFitnessemotion from '../svg/logo-fitnessemotion.vue';
import LogoMoveon from '../svg/logo-moveon.vue';
import LogoSportstudio from '../svg/logo-sportstudio.vue';
import LogoFitnessfactory24 from '../svg/logo-fitnessfactory24.vue';
import LogoMihusgym from '../svg/logo-mihusgym.vue';


import { 
  apihost, 
} from "../api";

import auth from "../auth";
import { locale, loadMessages } from "devextreme/localization";

let currentUser;

let countAlertDashboard = 0;
let countAlertMyprofile = 0;
let countAlertTrainingplan = 0;
let countAlertContract = 0;
let countAlertShop = 0;


auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
    //Set Local for Dexextreme Components
    locale(currentUser.Language);

  } // e.data
}); //auth


export default {

  async mounted() {

    await this.fetchAlert();

  },

  components: {
    DxScrollView,
    HeaderToolbar,
    AppAlertFooter,

    LogoMsa,
    LogoTgym,
    LogoDynamic,
    LogoLasuite,
    LogoX30,
    LogoProfitfitness,
    LogoPhysioplus,
    LogoGoldsgym,
    LogoFitx,
    LogoKuoni,
    LogoChilihealth,
    LogoFitnessunited,
    LogoNeogate,
    LogoChristoppark,
    LogoBesttraining,
    LogoQuantumgym,
    LogoGym365,
    LogoTrainiq,
    LogoLfit,
    LogoMeintraining,
    LogoFitwork,
    LogoFitphysiotitlis,
    LogoLifefitness24,
    LogoFightindustry,
    LogoFitnessemotion,
    LogoMoveon,
    LogoSportstudio,
    LogoFitnessfactory24,
    LogoMihusgym,

  },
  setup() {

    //stakeholder
    const pos = apihost.indexOf("api")+3;
    const result = apihost.substring(pos, 100);
    const posend = result.indexOf(".");
    const stakeholder = result.substring(0, posend);

    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
     () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
     }
    )
    return {
      title,
      description,
      stakeholder,
      locale,
      loadMessages,
    }
  },

  data() {
    return {
      apihost,
      currentUser,
      countAlertDashboard,
      countAlertMyprofile,
      countAlertTrainingplan,
      countAlertContract,
      countAlertShop,
    };
  },
  
  methods: {

    async fetchAlert() {

      fetch(apihost+'/'+currentUser.Language+'/de/vue/member/memberalert/'+currentUser.id)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        //console.log(data);

        this.countAlertDashboard = data.countAlertDashboard;
        this.countAlertMyprofile = data.countAlertMyprofile;
        this.countAlertTrainingplan = data.countAlertTrainingplan;
        this.countAlertContract = data.countAlertContract;
        this.countAlertShop = data.countAlertShop;
        
        
      })
      .catch(() => { throw 'Network error' });

    },


  },


};
</script>

