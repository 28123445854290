<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">
    <div class="grid-x grid-margin-x">
      <div class="large-auto cell">
        <h2><i class="dx-icon nav-icon fa-light fa-file-invoice"></i> {{ filterTypeText }}</h2>
      </div>



      <div class="shrink cell">
        <div class="grid-x">

          <div class="shrink cell">
            <button  class="cx-button tiny" :style="{ 'margin-right': '10px', 'opacity': filterType == 'open' ? 0.3 : 1}"
              @click="() => switchFilterType('open')"
            >
              <i class="fa-light fa-file-contract hide"></i> offen
            </button>

            <button  class="cx-button tiny" :style="{ 'margin-right': '10px', 'opacity': filterType == 'due' ? 0.3 : 1}"
              @click="() => switchFilterType('due')"
            >
              <i class="fa-light fa-file-contract hide"></i> fällig
            </button>

            <button  class="cx-button tiny" :style="{ 'margin-right': '10px', 'opacity': filterType == 'reminder1' ? 0.3 : 1}"
              @click="() => switchFilterType('reminder1')"
            >
              <i class="fa-light fa-file-contract hide"></i> Erinnerung
            </button>


            <button  class="cx-button tiny" :style="{ 'margin-right': '10px', 'opacity': filterType == 'reminder2' ? 0.3 : 1}"
              @click="() => switchFilterType('reminder2')"
            >
              <i class="fa-light fa-file-contract hide"></i> 1. Mahnung
            </button>


            <button  class="cx-button tiny" :style="{ 'margin-right': '10px', 'opacity': filterType == 'reminder3' ? 0.3 : 1}"
              @click="() => switchFilterType('reminder3')"
            >
              <i class="fa-light fa-file-contract hide"></i> 2. Mahnung
            </button>


            <button  class="cx-button tiny" :style="{ 'margin-right': '10px', 'opacity': filterType == 'reminder4' ? 0.3 : 1}"
              @click="() => switchFilterType('reminder4')"
            >
              <i class="fa-light fa-file-contract hide"></i> 3. Mahnung
            </button>


            <button  class="cx-button tiny" :style="{ 'margin-right': '10px', 'opacity': filterType == 'paid' ? 0.3 : 1}"
              @click="() => switchFilterType('paid')"
            >
              <i class="fa-light fa-file-contract hide"></i> bezahlt
            </button>

            <button  class="cx-button tiny" :style="{ 'margin-right': '10px', 'opacity': filterType == 'all' ? 0.3 : 1}"
              @click="() => switchFilterType('all')"
            >
              <i class="fa-light fa-file-contract hide"></i> alle
            </button>

            <a :href="$router.resolve({name: 'sap-invoicelist'}).href" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-invoice"></i> Rechnungen</a>


          </div>

        </div><!-- grid-x sub-->

      </div><!-- shrink cell -->
      
      <div class="large-shrink cell hide">
        <a :href="$router.resolve({name: 'sap-invoicelist-all'}).href" class="cx-button tiny" style="margin-right: 10px; opacity: 0.3;"><i class="fa-light fa-file-invoice"></i> alle</a>
        <a :href="$router.resolve({name: 'sap-invoicelist-due'}).href" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-invoice"></i> offen</a>
        <a :href="$router.resolve({name: 'sap-invoicelist-paid'}).href" class="cx-button tiny" style="margin-right: 10px; opacity: 1;"><i class="fa-light fa-file-invoice"></i> bezahlt</a>
      </div><!-- shrink cell -->
    </div><!-- grid-x -->

    <div style="height: 10px;"></div>

    <div class="dx-card no-paddings">

      <DxDataGrid
        ref="myDataGrid"
        id="gridContainer"
        :data-source="customDataSource"
        :remote-operations="{ paging: true, filtering: true }"

        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"

        :allow-column-reordering="true"
        :show-borders="false"

        @content-ready="getTotalCount"

        @editing-start="logEvent('EditingStart')"
        @init-new-row="logEvent('InitNewRow')"
        @row-inserting="logEvent('RowInserting')"
        @row-inserted="logEvent('RowInserted')"
        @row-updating="logEvent('RowUpdating')"
        @row-updated="logEvent('RowUpdated')"
        @row-removing="logEvent('RowRemoving')"
        @row-removed="logEvent('RowRemoved')"
        @saving="logEvent('Saving')"
        @saved="logEvent('Saved')"
        @edit-canceling="logEvent('EditCanceling')"
        @edit-canceled="logEvent('EditCanceled')"
      >

        <DxPaging :page-size="25" />
        <DxPager :show-page-size-selector="true" :allowed-page-sizes="[10, 25, 50]" :show-info="true" />
        <DxFilterRow :visible="true" />
        <DxSorting mode="None" />

        <DxEditing
          :allow-updating="false"
          :allow-deleting="true"
          :allow-adding="false"
          mode="row"
        />

        <DxColumn
          alignment='left' 
          data-field="Search"
          :caption="totalCount + ' ' + title"
          :placeholder="'Suchen'"
          cell-template="subjectTemplate"
          :hiding-priority="99"
        />
        <DxColumn
          alignment='left' 
          data-field="LocationName"
          :caption="''"
          :placeholder="'Standort'"
          :editor-options="locationEditorOptions"
          editor-type="dxSelectBox"
          :width="200" 
          cell-template="locationTemplate"
          :hiding-priority="99"
        />

        <template #locationTemplate="{ data: content }">

          <div class="fixedwidth-listitem">

            <p class="ck-overflow-ellipsis small strong">
              {{ content.data.LocationName }}
            </p>

          </div>

        </template>

        <template #subjectTemplate="{ data: content }">

          <!-- search: {{  content.data.Search }} -->

          <div class="grid-x align-middle">

            <div class="shrink cell">

              <!-- avatar -->
              <div class="user-thumb" style="margin: 2px 10px 2px 0;"
                v-bind:style="{ 'background-image': 'url(' + content.data.CustomerPicthumb + ')' }" >
              </div>
              <!-- /avatar -->

            </div><!-- /shrink cell main -->

            <div class="auto cell">
              <div class="grid-x align-middle">

                <div class="shrink cell">
                  <!-- row 1 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small">
                      <span v-if="content.data.CustomerSubject">
                        {{ content.data.CustomerSubject }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Kunde
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis small">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+content.data.Customerid+'/'+content.data.id+'/'+content.data.rateid+'&dest=send'" target="_blank" style="color: #111111;">
                        <i class="fa-light fa-file-pdf"></i>
                        RE-{{ content.data.id }}-{{ content.data.rateid }}
                      </a>
                    </p>

                    <p class="ck-overflow-ellipsis small" style="font-size: 9px;">
                      {{ content.data.stringReference }}
                    </p>
                    
                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 2 -->
                  <div class="fixedwidth-listitem">

                    <p class="ck-overflow-ellipsis small strong">
                      <span v-if="content.data.AboSubject">
                        {{ content.data.AboSubject }}
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Abo
                      </span>
                    </p>

                    <p v-if="content.data.Price" class="ck-overflow-ellipsis small" >
                      Preis Abo: CHF {{ formatPrice(content.data.Price) }}
                    </p>

                    <p v-if="content.data.DateStart" class="ck-overflow-ellipsis small" >
                      Abostart: {{ formatDate(content.data.DateStart) }}
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->
                
                <div class="shrink cell">
                  <!-- row 3 -->
                  <div class="fixedwidth-listitem">


                    <p class="ck-overflow-ellipsis small strong" :id="'titleRates'+content.data.id">
                      <span v-if="content.data.Rates">
                        Rate {{  content.data.rateid }} von {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> Raten
                      </span>
                    </p>
                    

                    <!-- PaidList -->
                    <div class="-hide" v-for="(item, index) in content.data.PaidList" :key="index">
                      
                      <template v-if="item.id == content.data.rateid">
                        
                        <p v-if="item.Price" class="ck-overflow-ellipsis small">
                          Preis Rate: CHF {{ formatPrice(item.Price) }}
                        </p>

                        <p v-if="item.Date" class="ck-overflow-ellipsis small success-color">
                          <i class="fa-light fa-circle-check"></i> bezahlt: {{ formatDate(item.Date) }}
                        </p>

                      </template>
                      
                    </div><!-- /v-for PaidList -->

                    <!-- DueList -->
                    <div class="-hide" v-for="(item, index) in content.data.DueList" :key="index">

                      <template v-if="item.id == content.data.rateid">

                        <p v-if="item.Price" class="ck-overflow-ellipsis small">
                          Preis Rate: CHF {{ formatPrice(item.Price) }}
                        </p>

                        <!--
                        <p v-if="item.Date" class="ck-overflow-ellipsis small -alert-color">
                          <i class="fa-light fa-triangle-exclamation"></i> fällig: {{ formatDate(item.Date) }} 
                        </p>
                        -->

                        <p v-if="compareDate(item.Date)" class="ck-overflow-ellipsis small -warning-color">
                          <i class="fa-light fa-hourglass-start"></i> fällig: <span v-if="item.Date">{{ formatDate(item.Date) }}</span>                    
                        </p>

                        <p v-else class="ck-overflow-ellipsis small alert-color">
                          <i class="fa-light fa-triangle-exclamation"></i> fällig: <span v-if="item.Date">{{ formatDate(item.Date) }}</span>  
                        </p>
                        
                      </template>

                    </div><!-- /v-for DueList -->

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell align-self-top">
                  <!-- row 4 -->
                  <div class="fixedwidth-listitem" style="margin: 2px 0;">

                    <!--{{filterType }} -->
                    <!--
                    <p v-if="content.data.DateValuta" class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                    {{ formatDateTs(content.data.DateValuta) }}
                    </p>
                    -->

                    <p v-if="filterType != 'paid'" class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <span v-if="content.data.DateSent">
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'invoice')">
                          <i class="fa-light fa-envelope"></i>
                        </a>
                        Rechnung: <span :id="'DateInvoice'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateSent) }}</span>
                      </span>
                      <span v-else>
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'invoice')">
                          <i class="fa-light fa-envelope"></i> Rechnung senden
                        </a>
                      </span>
                    </p>
                    <p v-else class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <!-- Rechnung: <span :id="'DateInvoice'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateSent) }}</span>  -->
                    </p>

                    <p v-if="filterType != 'paid'" class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <span v-if="content.data.DateReminder1">
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'rem1')">
                          <i class="fa-light fa-envelope"></i>
                        </a>
                        Erinnerung: <span :id="'DateReminder1'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder1) }}</span>
                      </span>
                      <span v-else>
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'rem1')">
                          <i class="fa-light fa-envelope"></i> Erinnerung senden
                        </a>
                      </span>
                    </p>
                    <p v-else class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <!-- Erinnerung: <span :id="'DateReminder1'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder1) }}</span> -->
                    </p>

                    <p v-if="content.data.DateReminder1 && filterType != 'paid'" class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <span v-if="content.data.DateReminder2">
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'rem2')">
                          <i class="fa-light fa-envelope"></i>
                        </a>
                        1. Mahnung: <span :id="'DateReminder2'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder2) }}</span>
                      </span>
                      <span v-else>
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'rem2')">
                          <i class="fa-light fa-envelope"></i> 1. Mahnung senden
                        </a>
                      </span>
                    </p>
                    <p v-else class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <span v-if="content.data.DateReminder2">
                        1. Mahnung: <span :id="'DateReminder2'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder2) }}</span>
                      </span>
                      <span v-else>
                        <!-- 1. Mahnung: <span :id="'DateReminder2'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder2) }}</span> -->
                      </span>
                    </p>

                    <p v-if="content.data.DateReminder2 && filterType != 'paid'" class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <span v-if="content.data.DateReminder3">
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'rem3')">
                          <i class="fa-light fa-envelope"></i>
                        </a>
                        2. Mahnung: <span :id="'DateReminder3'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder3) }}</span>
                      </span>
                      <span v-else>
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'rem3')">
                          <i class="fa-light fa-envelope"></i> 2. Mahnung senden
                        </a>
                      </span>
                    </p>
                    <p v-else class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <span v-if="content.data.DateReminder3">
                        2. Mahnung: <span :id="'DateReminder3'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder3) }}</span>
                      </span>
                      <span v-else>
                        <!-- 2. Mahnung: <span :id="'DateReminder3'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder3) }}</span> -->
                      </span>
                    </p>

                    <p v-if="content.data.DateReminder3 && filterType != 'paid'" class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <span v-if="content.data.DateReminder4">
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'rem4')">
                          <i class="fa-light fa-envelope"></i>
                        </a>
                        3. Mahnung: <span :id="'DateReminder4'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder4) }}</span>
                      </span>
                      <span v-else>
                        <a @click="sendEmail(content.data.Customerid, content.data.id, content.data.rateid, 'rem4')">
                          <i class="fa-light fa-envelope"></i> 3. Mahnung senden
                        </a>
                      </span>
                    </p>
                    <p v-else class="ck-overflow-ellipsis small -hide" style="font-size: 9px; line-height: 12px;">
                      <span v-if="content.data.DateReminder4">
                        3. Mahnung: <span :id="'DateReminder4'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder4) }}</span>
                      </span>
                      <span v-else>
                        <!-- 3. Mahnung: <span :id="'DateReminder4'+content.data.id+content.data.rateid">{{ formatDateTs(content.data.DateReminder4) }}</span> -->
                      </span>
                    </p>
                    
                  </div><!-- /fixedwidth-listitem -->

                </div><!-- /shrink cell -->

              </div><!-- /grid-x sub-->

            </div><!-- /auto cell main -->
          </div><!-- /grid-x main -->

        </template>

        <DxMasterDetail
          :enabled="true"
          template="masterDetailTemplate"
        />
        <template #masterDetailTemplate="{ data: content }">
          <DetailTemplate
            :template-data="content"
          />
        </template>

      </DxDataGrid>

    </div><!-- dx-card -->

  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

  <div class="content-block hide">
    <div id="events">

      <div class="grid-x grid-margin-x"> 
        <div class="auto cell"> 
          <div class="caption">
            Fired events:
          </div>
          <ul class="menu vertical">
            <li
              v-for="(event, index) in events"
              :key="index"
            >{{ event }}</li>
          </ul>
        </div>
        <div class="shrink cell"> 
          <DxButton
            id="clear"
            text="Clear"
            @click="clearEvents()"
          />
        </div>
      </div><!-- grid-x -->

    </div><!-- events -->
  </div><!-- content-block -->

</template>

<script>

import {
  DxDataGrid, 
  DxFilterRow, 
  DxColumn, 
  DxEditing, 
  DxPager, 
  DxPaging, 
  DxButton,
  DxMasterDetail,
  DxSorting,
  //DxSelection,
  // DxScrolling,

  //DxLookup, 
} from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DetailTemplate from '../parts/invoice-item-detail-master.vue';
import { ref } from 'vue';
import notify from 'devextreme/ui/notify';

import auth from "../auth";

import { 
  apihost, 
  pdfhost,
} from "../api";


const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3
}

let currentUser;
let totalCount;
let dateSent;


let filterType = ref("open");
let filterTypeText = ref("Raten offen");


auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

const customDataSource = new CustomStore({
  key: 'id',
  load: (opts) => {
    let objString = "?";
    [
      'skip',
      'take',
    ].forEach((i) => {
        if(i in opts && isNotEmpty(opts[i])){
          objString += `${i}=${JSON.stringify(opts[i])}&`
        }
      });

    const filter = opts['filter'];
    let filterString = "";
    if(filter) {
      // only one field searched
      if(typeof filter[0] == "string") {
        filterString += `&filter${filter[0]}=${filter[2]}`;
      } else {
        // otherwise multiple search
        for(const filterItem of filter) {
          if(typeof filterItem == "string") { continue; } 
          filterString += `&filter${filterItem[0]}=${filterItem[2]}`;
        }
      }
    }

    //objString = objString.slice(0, -1) + filterString;
    objString = objString.slice(0, -1) + `&filterType=${filterType.value}${filterString}`;
    console.log(objString);
    return fetch(`${apihost}/${currentUser.Language}/vue/invoice/list/${objString}`)
      .then(handleErrors)
      .then(response => response.json())
      .then(obj => {
        return {
          data: obj.data,
          totalCount: obj.totalCount,
        }
      })
      .catch(() => { throw 'Network error' });
  },
  insert: (values) => {
    //console.log(values);
    const key = '0';
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/insert'+objString, {
      // method: 'GET',
    })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
  },
  remove: (key) => {
    //console.log(key);
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/remove', {
      // method: 'GET',
    })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
  },
  update: (key, values) => {
    // console.log(key);
    // console.log(values);
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/save'+objString, {
      // method: 'GET',
    })
      .then(handleErrors)
      .catch(() => { throw 'Network error' });
  }

});

export default {

  mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-invoicelist", "/#/dashboard");
      window.location.href = redirecturl;
    }

    this.timestamp = this.printTimestamp();

  },

  components: {
    DxDataGrid,
    DxMasterDetail,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxButton,
    DetailTemplate,
    DxSorting,
    // DxForm,
    // DxGroupItem,
    // DxSimpleItem,
    // DxButtonItem,
    // DxLabel,
    // DxFileUploader,
    // DxDropDownBox,
    // DxSelection,
    // DxScrolling,
    //DxTextBox,
    //DxRequiredRule,
    //DxCompareRule,
    //DxPatternRule,
    //DxRangeRule,
    //DxEmailRule,
    //DxStringLengthRule,
    //DxAutocomplete,
    //DxAsyncRule,
    //DxLookup,


  },
  data() {
    return {
      apihost,
      pdfhost,
      totalCount,
      dateSent,

      //title: currentUser.Translation.vueappNavMemberRates,
      title: 'Raten',
      events: [],
      formInstance: null,
      // statuses,
      // priorities,
      // invoicetypes,
      // invoicecategory,
      currentUser,
      customDataSource,
      colCountByScreen,

      // //Option 1
      // option1DataSource,
      // option1Columns,
      // option1BoxValue,
      // //changeOption1,
      // //readyOption1,

      // //Option 2
      // option2DataSource,
      // option2Columns,
      // option2BoxValue,
      // //changeOption2,
      // //readyOption2,

      calendarOptions: { 
        displayFormat: "dd.MM.yyyy"
      },
      buttonOptions: {
        text: currentUser.Translation.vueappInvoiceFormSubmit,
        type: 'success',
        useSubmitBehavior: true,
      },
      dateBoxOptions: {
        invalidDateExerciseoption2:
        'The date must have the following format: MM/dd/yyyy',
      },
      checkBoxOptions: {
        text: 'I agree to the Terms and Conditions',
        value: false,
      },
      phoneEditorOptions: {
        mask: '+1 (X00) 000-0000',
        maskRules: {
          X: /[02-9]/,
        },
        maskInvalidExerciseoption2: 'The phone must have a correct USA phone format',
      },

      statusPattern: /^[^0-9]+$/,
      priorityPattern: /^[^0-9]+$/,
      invoicePattern: /^[^0-9]+$/,

      // phonePattern: /^[02-9]\d{9}$/,
      // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),

      validationRules: {

        // status: [
        //   { type: 'required', invoice: currentUser.Translation.vueappTaskStatusExerciseoption2 },
        // ],
        // priority: [
        //   { type: 'required', invoice: currentUser.Translation.vueappTaskPriorityExerciseoption2 },
        // ],
      },
      //statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
      //priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
      // statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
      // priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },
      locationEditorOptions: { items: currentUser.Constant.locations, searchEnabled: true },
      filterType,
      filterTypeText,

    };
  },
  methods: {

    getTotalCount(e) {
      this.totalCount = e.component.totalCount();
    },
    compareDate(expiryDate) {
      return Date.now() < (new Date(expiryDate).getTime());
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },

    formatDateTs(dateString) {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

      // Get Hours part from the timestamp
      var hours = '0' + date.getHours();
      hours = hours.slice(-2);
      //console.log(hours);

      // Get Minutes part from the timestamp
      var minutes = '0' + date.getMinutes();
      minutes = minutes.slice(-2);
      //console.log(minutes);

      // Get Seconds part from the timestamp
      var seconds = '0' + date.getSeconds();
      seconds = seconds.slice(-2);
      //console.log(seconds);
      
      //var time = '00:00:00';
      var time = hours+':'+minutes+':'+seconds;
      //console.log(time);

      const formattedDate = new Intl.DateTimeFormat('de-DE', options).format(date) + ' ' + time;
      //console.log(formattedDate);

      return formattedDate;
    },
    
				formatPrice(value) {
					value = parseFloat(value);
					//auf 5 Rappen runden
					value = Math.round(value * 20) / 20;
					//zwei Stellen anzeigen
					value = value.toFixed(2);
					return value;
				},

    validateForm(e) {
      e.component.validate();
    },

    logEvent(eventName) {
      //console.log(this.events);
      this.events.unshift(eventName);
    },
    clearEvents() {
      this.events = [];
    },

    printTimestamp: function () {
      //console.log('ts');
      //console.log(Date.now()/1000);
      let date = Date.now()/1000;
      //date = date.slice(0, -3);

      //console.log(Date.now());
      //var newString = date.slice(0, -1);
      //console.log(newString);

      return date;
    },

    switchFilterType(type) {
      filterType.value = type;
      switch(type){
        case 'open':
          filterTypeText.value = "Raten offen";
          break;
        case 'due':
          filterTypeText.value = "Raten fällig";
          break;
        case 'reminder1':
          filterTypeText.value = "Raten Erinnerung";
          break;
          case 'reminder2':
          filterTypeText.value = "Raten 1. Mahnung";
          break;
          case 'reminder3':
          filterTypeText.value = "Raten 2. Mahnung";
          break;
        case 'reminder4':
          filterTypeText.value = "Raten 3. Mahnung";
          break;
        case 'paid':
          filterTypeText.value = "Raten bezahlt";
          break;
        case 'all':
          filterTypeText.value = "alle Raten";
          break;
        default:
          filterTypeText.value = "Raten offen";
          break;
      }
      this.$refs['myDataGrid'].instance.refresh();
    },

    sendEmail(customerId, invoiceId, rateId, invoiceCase) {

      fetch(apihost+'/de/mail/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case='+invoiceCase)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);

          //Update Frontend 

          let htmlbeforeInvoice = '';
          let htmlafterInvoice = '';
          let htmlbeforeReminder1 = '';
          let htmlafterReminder1 = '';
          let htmlbeforeReminder2 = '';
          let htmlafterReminder2 = '';
          let htmlbeforeReminder3 = '';
          let htmlafterReminder3 = '';
          let htmlbeforeReminder4 = '';
          let htmlafterReminder4 = '';

          if(invoiceCase == 'invoice'){
            htmlbeforeInvoice = '<span class="success-color"><i class="fa-light fa-circle-check"></i> ';
            htmlafterInvoice = '</span>';
          }
          if(invoiceCase == 'rem1'){
            htmlbeforeReminder1 = '<span class="success-color"><i class="fa-light fa-circle-check"></i> ';
            htmlafterReminder1 = '</span>';
          }
          if(invoiceCase == 'rem2'){
            htmlbeforeReminder2 = '<span class="success-color"><i class="fa-light fa-circle-check"></i> ';
            htmlafterReminder2 = '</span>';
          }
          if(invoiceCase == 'rem3'){
            htmlbeforeReminder3 = '<span class="success-color"><i class="fa-light fa-circle-check"></i> ';
            htmlafterReminder3 = '</span>';
          }
          if(invoiceCase == 'rem4'){
            htmlbeforeReminder4 = '<span class="success-color"><i class="fa-light fa-circle-check"></i> ';
            htmlafterReminder4 = '</span>';
          }

          const elemDateInvoice = document.getElementById("DateInvoice"+data.invoiceid+data.rateid);
          if(elemDateInvoice){
            elemDateInvoice.innerHTML = htmlbeforeInvoice+data.datesentInvoice+htmlafterInvoice;
          }
          const elemDateReminder1 = document.getElementById("DateReminder1"+data.invoiceid+data.rateid);
          if(elemDateReminder1){
            elemDateReminder1.innerHTML = htmlbeforeReminder1+data.datesentRem1+htmlafterReminder1;
          }
          const elemDateReminder2 = document.getElementById("DateReminder2"+data.invoiceid+data.rateid);
          if(elemDateReminder2){
            elemDateReminder2.innerHTML = htmlbeforeReminder2+data.datesentRem2+htmlafterReminder2;
          }
          const elemDateReminder3 = document.getElementById("DateReminder3"+data.invoiceid+data.rateid);
          if(elemDateReminder3){
            elemDateReminder3.innerHTML = htmlbeforeReminder3+data.datesentRem3+htmlafterReminder3;
          }
          const elemDateReminder4 = document.getElementById("DateReminder4"+data.invoiceid+data.rateid);
          if(elemDateReminder4){
            elemDateReminder4.innerHTML = htmlbeforeReminder4+data.datesentRem4+htmlafterReminder4;
          }

          //End Update Frontend 

        })
        .catch(() => { throw 'Network error' });

      notify({
        message: 'E-Mail wurde versandt',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);


    },


  },
};


</script>

<style>

</style>

