<template>

<div class="dx-card">
      <DxList
        :data-source="checkinDataSource"
      >
        <template #item="{ data }">
          <div>

          <div class="grid-x align-middle">

          <div class="shrink cell">

            <!-- avatar -->
            <div class="user-thumb" style="margin: 2px 10px 2px 5px;"
              v-bind:style="{ 'background-image': 'url(' + data.Avatar + '?v=' + timestamp + ')' }" >
            </div>
            <!-- /avatar -->

          </div><!-- /shrink cell main -->

          <div class="auto cell">

            <div class="grid-x align-middle">

              <div class="shrink cell">
                <!-- row 1 -->
                <div class="fixedwidth-listitem">
                  <p class="ck-overflow-ellipsis strong small">
                    <span>
                      {{ data.FirstName }} {{ data.LastName }}
                    </span>
                  </p>
                  <p class="ck-overflow-ellipsis -strong small">
                    <span>{{ epochToDateTime(data.Timestamp) }}</span>
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 2 -->
                <div class="fixedwidth-listitem">

                  <p class="ck-overflow-ellipsis strong small">
                    <span v-if='data.GrantAccess === "true"' class="success-color">
                    <i class="fa-light fa-right-to-bracket"></i> Check{{ data.Status }}
                    </span>
                    <span v-else class="alert-color">
                    <i class='fa-solid fa-octagon-xmark'></i> Verweigert
                    </span>
                  </p>

                  <p class="ck-overflow-ellipsis small">
                    <span v-if='data.GrantAccess === "true"' class="success-color">
                    <i class="fa-light fa-circle-check"></i> Access Granted
                    </span>
                    <span v-else class="alert-color">
                     <i class="fa-light fa-triangle-exclamation"></i> {{ data.Status }}
                    </span>
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 3 -->
                <div class="fixedwidth-listitem">

                  <p class="ck-overflow-ellipsis small">
                    RFID: {{ data.Rfid }}
                  </p>

                  <p class="ck-overflow-ellipsis small">
                    DeviceId: {{ data.DeviceId }}
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 4 -->
                <div class="fixedwidth-listitem">

                  <p class="ck-overflow-ellipsis small">
                    LocationId: {{ data.LocationId }}
                  </p>

                  <p class="ck-overflow-ellipsis small">
                    Id: {{ data.id }}
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

            </div><!-- /grid-x sub-->

          </div><!-- /auto cell main -->
          </div><!-- /grid-x main -->

        </div>
        </template>

      </DxList>
    </div><!-- dx-card checkin-list -->

</template>

<script>
 
let currentUser;
let customerId;
let checkinDataSource;

import {
  DxList
} from 'devextreme-vue/list';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from "devextreme/data/data_source";

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } 
}); 




export default {
  name: 'customer-checkin',

  props: {
    customerData: Object,
  },

  components: {
    DxList,
  },

  mounted() {
    if(this.customerData){
      customerId = this.customerData.id;
    }
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
    this.checkinDataSource = new DataSource({
      store: new CustomStore({
        key: "id",
        load: (opts) => {
          let params = "?";
        
          [
            'take',
            'skip',
          ].forEach(i => {
              if(i in opts && isNotEmpty(opts[i])){
                params += `${i}=${JSON.stringify(opts[i])}&`;
              }
            });
          params += 'customerID=' + customerId;
          console.log(params); 
          
          return fetch(`${apihost}/vue/websocket/list/${params}`)
            .then(r => r.json())
            .then(o => {
              return {
                data: o.data,
                totalCount: o.totalCount,
                summary: []
              };
            })
            .catch(() => { throw 'Network Error' });
        }
      }),
      paginate: true,
      pageSize: 10, 
    });
  },

  data() {
    return {
      apihost,
      currentUser,
      checkinDataSource,
      customerId,
    };
  },

  methods: {
    epochToDateTime(timestamp) {
      const date = new Date(timestamp * 1000);
      const dateString = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      const timeString = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      return `${timeString} ${dateString}`;
    },
  },

  unmounted() {

  },

};

</script>

<style>

</style>
