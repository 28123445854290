<template>
<svg version="1.1" id="Logo-Quantum" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
y="0px" viewBox="0 0 2041.7 491.5" style="enable-background:new 0 0 2041.7 491.5;" xml:space="preserve">
<path class="st0" d="M1086.1,10.2l-20.6,36.9c-9.2-2.3-18.4-4.9-27.9-5.9c-67.5-7.2-130.1,33.9-115,108
c12.8,62.7,91.6,80.1,144.5,60c-9.7-8.5-35.5-17.7-35.5-17.7l20.7-35.2c13.8,1.3,25.1,9.5,36.1,17.1c17.4,11.9,36.7,29.2,54.7,39.3
s13.5,5,19.9,8.1l-19.2,33.3c-15.3,0.5-27.4-10.7-40.3-16.8c-94.4,52.6-233.1,3.9-226.6-117.5c5.5-102,124.1-142.8,209-109.4
L1086.1,10.2L1086.1,10.2L1086.1,10.2z"/>
<path class="st1" d="M1159,170.1l-32.9-22.7c3.3-18.8,3.3-38.2-5.2-55.7s-11.4-15.7-17.6-23.1l20.3-34.6c2.5-0.4,7.1,5.1,8.9,7.1
C1166.3,76.3,1172.5,124.2,1159,170.1L1159,170.1L1159,170.1z"/>
<path class="st0" d="M1383.8,334c-8.8-0.3-17.6-0.8-26.5,0.3c-3.3,0.3-6.6,4.1-9.4,7.2c-3.3,3.3-29.2,39.7-45.2,61.5v-0.6
c-5.5,6.3-13,16-17.1,14.6c-0.3,0-0.6-0.3-0.6-0.3l0,0c-0.3-0.3-0.6-0.3-0.8-0.6l0,0c-0.6-0.6-1.4-1.4-2.2-2.2
c-13.5-17.9-49.4-68.4-53.2-72.5c-2.8-2.8-6.1-6.9-9.4-7.2c-8.5-1.1-17.6-0.6-26.5-0.3c-2.8,0-4.1,1.9-3,4.4c1.9,3.9,4.1,7.7,6.9,11
s4.7,6.6,4.4,11.3v117.8c0,4.7,2.2,6.9,6.9,6.9h17.6c5.2,0,8.3-2.8,8.3-8.3c-0.3-26.8-0.6-40-0.8-66.7c0-3,0.3-5.8,3.3-6.6
c2.8-0.8,5,0.8,6.9,3.3c3.9,5,20.4,29.2,37,49.6l2.2,2.8l0,0l0.3,0.3l0,0c0.6,0.8,1.9,2.8,3.3,3.9c0.3,0.3,0.6,0.6,0.6,0.8v-0.3l0,0
c0.3,0,0.3,0.3,0.6,0.3s0.6,0.3,0.6,0.3h0.8c0.3,0,0.6-0.3,0.8-0.3v0.3l0.3-0.3c1.1-0.6,1.9-1.4,3-2.5l0,0c0.3-0.3,0.6-0.8,1.1-1.1
c4.1-4.7,8.8-11.6,8.8-11.6v-0.6c14.3-18.5,27.3-37.2,30.6-41.6c1.9-2.5,3.9-4.1,6.9-3.3s3.3,3.6,3.3,6.6c-0.3,26.8-0.6,40-0.8,66.7
c0,5.2,3,8.3,8.3,8.3h17.6c4.7,0,6.9-2.2,6.9-6.9V360.6c0-4.4,1.9-8,4.7-11.3c2.8-3.3,5-7.2,6.9-11c0.8-2.2-0.6-4.1-3.3-4.4"/>
<path class="st0" d="M525.9,435.5c-28.1,2.2-40.3,3.9-54.9,8.3c-2.8,0.8-5.5,1.7-8.3,2.2s-4.7-1.1-3.3-3.9c3.6-7.7,7.2-15.4,11-23.2
c5.8-11.6,11.6-23.2,17.6-34.5c1.1-2.2,3-4.1,5.2-5.2c0.8-0.3,3.6,2.5,4.7,4.4c6.1,10.8,27.3,51,27.9,51.8 M577.4,478.5
c-3.9-7.2-7.7-14.1-11.6-21.2c-7.2-13.5-14.1-27.3-21.2-40.8c-8.5-16-17.1-32-25.9-48c-5.8-11-11.6-22.1-17.6-33.1
c-1.9-3.6-5.2-6.1-9.7-5.8c-4.1,0.3-8,0.6-12.1,0.8c-2.8,0.3-6.1,1.4-9.1,1.9c0.8,2.8,2.2,6.1,3.9,8.3c3.6,5,3.3,9.7,0.8,14.9
c-5.2,9.7-10.2,19.6-15.4,29.2c-10.5,19.9-21,40-31.4,59.8c-6.1,11.9-12.4,23.7-18.8,35.6c-1.7,3,0,5.5,3.3,5.8h8.3
c6.9,0.3,13.5,0.6,18.2-5.5c9.9-9.7,52.4-21.8,92.7-23.7c3.3-0.3,7.2,6.6,9.1,11.3c1.4,3.3,3.6,6.6,5,9.9c2.2,6.3,6.3,9.1,13,8
c1.7-0.3,3.3,0,5,0h8.5c6.9-0.3,8-1.9,5.2-7.4"/>
<path class="st0" d="M773.2,409.3v-67.8c0-5-2.2-7.4-6.9-7.4h-15.7c-5,0-6.1,1.4-6.1,6.3v89.4c0,5-2.8,6.9-7.2,4.4
c-1.9-1.1-3.6-2.5-5-4.1c-6.1-6.9-12.1-14.3-18.2-21.2c-5.8-6.6-11.6-13-17.1-19.6c-9.4-11-19-22.1-28.4-33.1
c-4.1-5-8-9.9-12.4-14.6c-2.8-2.8-6.1-6.9-9.4-7.2c-8.5-1.1-17.6-0.6-26.5-0.3c-2.8,0-4.1,1.9-3,4.4c1.9,3.9,4.1,7.7,6.9,11
c2.8,3.3,4.7,6.6,4.4,11.3v117.8c0,4.7,2.2,6.9,6.9,6.9h12.7c5.2,0,8.3-2.8,8.3-8.3c-0.3-26.8-0.6-53.8-0.8-80.5
c0-3,0.3-5.8,3.3-6.6c2.8-0.8,5,0.8,6.9,3.3c6.1,8,59,70.3,66.5,78.9c4.1,4.7,8,10.5,13.2,12.7s12.1,0.6,18.5,0.6s8.8-4.7,9.1-9.1
v-67"/>
<path class="st0" d="M951.7,363.8c2.2,0,3.9-1.7,3.9-3.9v-21.2c0-2.2-1.7-3.9-3.9-3.9H818.2c-2.2,0-3.9,1.7-3.9,3.9v21.2
c0,2.2,1.7,3.9,3.9,3.9h47.2c2.2,0,3.9,1.7,3.9,3.9v112.5c0,2.2,1.7,3.9,3.9,3.9h19.6c2.2,0,3.9-1.7,3.9-3.9V367.7
c0-2.2,1.7-3.9,3.9-3.9h51.3H951.7L951.7,363.8z"/>
<path class="st0" d="M150,408.4c0,11.6-4.7,22.3-12.4,30.9l0,0l-25.1-16c-1.9-1.1-4.1-0.6-5.5,1.1l-12.1,19
c-1.1,1.9-0.6,4.1,1.1,5.5l12.4,8c-6.3,1.9-13.2,2.8-20.1,2.8c-33.9,0-61.5-23.2-61.5-51.6s27.6-51.6,61.5-51.6s61.8,23.4,61.8,51.8
M178.4,409.3c0-45.5-40-82.2-89.4-82.2S0,363.8,0,409.3s40,82.2,89.1,82.2s36.1-5.5,50.5-14.3l18.2,11.6c1.9,1.1,4.1,0.6,5.5-1.1
l12.1-19c1.1-1.9,0.6-4.1-1.1-5.5l-11.6-7.4c9.9-13.2,15.7-29.2,15.7-46.3"/>
<path class="st1" d="M2037.4,334c-8.8-0.3-17.7-0.8-26.5,0.3c-3.3,0.3-6.6,4.1-9.4,7.2c-3.3,3.3-29.2,39.7-45.2,61.5v-0.6
c-5.5,6.3-13,16-17.1,14.6c-0.3,0-0.6-0.3-0.6-0.3l0,0c-0.3-0.3-0.6-0.3-0.8-0.6l0,0c-0.6-0.6-1.4-1.4-2.2-2.2
c-13-17.4-49.1-67.8-52.9-71.7c-2.8-2.8-6.1-6.9-9.4-7.2c-8.8-1.1-17.7-0.6-26.5-0.3c-2.8,0-4.1,1.9-3,4.4c1.9,3.9,4.1,7.7,6.9,11
s4.7,6.6,4.7,11.3v117.8c0,4.7,2.2,6.9,6.9,6.9h17.7c5.2,0,8.3-2.8,8.3-8.3c-0.3-26.8-0.6-40-0.8-66.7c0-3,0.3-5.8,3.3-6.6
c2.8-0.8,5,0.8,6.9,3.3c3.6,5,20.4,29.2,37,49.6l2.2,2.8l0,0l0.3,0.3l0,0c0.6,0.8,1.9,2.8,3.3,3.9c0.3,0.3,0.6,0.6,0.6,0.8v-0.3l0,0
c0.3,0,0.3,0.3,0.6,0.3s0.6,0.3,0.6,0.3h0.8c0.3,0,0.6-0.3,0.8-0.3v0.3l0.3-0.3c1.1-0.6,1.9-1.4,3-2.5l0,0c0.3-0.3,0.6-0.8,1.1-1.1
c4.1-4.7,8.8-11.6,8.8-11.6v-0.6c14.3-18.5,27.3-37.2,30.6-41.6c1.9-2.5,3.9-4.1,6.9-3.3c3,0.8,3.3,3.6,3.3,6.6
c-0.3,26.8-0.6,40-0.8,66.7c0,5.2,3,8.3,8.3,8.3h17.7c4.7,0,6.9-2.2,6.9-6.9V361.4c0-4.4,1.9-8,4.7-11.3c2.8-3.3,5-7.2,6.9-11
c0.6-3-1.1-5-3.9-5.2"/>
<path class="st1" d="M1624,424.7L1624,424.7l-0.3-15.4c0-4.4-3.6-7.7-7.7-7.7h-68.4c-4.4,0-7.7,3.6-7.7,7.7v8.3
c0,4.4,3.6,7.7,7.7,7.7h32c7.2,0.3,8.3,3.3,8.3,5.2s-9.7,13.8-12.7,16.8c-9.7,5.8-21.2,9.4-33.9,9.4c-31.7,0-57.4-21.8-57.4-48.3
s25.6-48.3,57.4-48.3s32,6.1,42.5,15.7l22.1-19c-16.3-17.4-40.3-28.4-67-28.4c-48.3,0-87.1,35.6-87.1,79.7s39.2,79.7,87.1,79.7
s76.4-26.8,85.2-62.3l0,0L1624,424.7"/>
<path class="st1" d="M1689.4,337c-1.4-1.7-3.9-3.3-6.1-3.3h-28.4c-2.2,0-3,1.4-1.7,3.3l59,86.6c1.1,1.7,2.2,5,2.2,7.2v50.7
c0,2.2,1.7,3.9,3.9,3.9h22.6c2.2,0,3.9-1.7,3.9-3.9l-0.3-50.7c0-2.2,0.8-5.2,2.2-7.2l58.7-86.6c1.1-1.7,0.6-3.3-1.7-3.3h-27
c-2.2,0-5,1.4-6.3,3l-39.4,53.8c-1.4,1.7-3.3,1.7-4.7,0l-37-53.5L1689.4,337L1689.4,337z"/>
<path class="st0" d="M375.3,387.2v-46.9c0-4.7-1.4-6.1-6.1-6.1h-16.3c-5.5,0-7.7,1.9-7.7,7.4c0,30.3,0.3,60.7,0,91
c0,9.7-5.2,17.1-13.2,22.1c-8,5.2-17.6,5.5-27,6.1c-6.9,0.6-13.8-0.6-20.7-1.7c-6.1-1.1-12.1-2.2-17.1-6.6
c-7.2-6.6-9.7-15.4-9.7-24.5c0.3-29.2,0.6-58.2,0.6-87.4s0-5.2,0-7.1h-5.4c-0.4,0.3-0.9,0.5-1.4,0.5h-16.8c-3.3,0-6.1,2.5-6.1,5.8
c0.8,29,0.3,89.9,0.3,91.3c0,33.4,32.8,60.4,73.4,60.4s72.8-26.8,73.1-59.6c0.3-0.3,0.3-0.8,0.3-1.1c0.3-14.6,0.3-29.2,0-43.6l0,0
H375.3z"/>
<path class="st0" d="M1132.2,387.2v-46.9c0-4.7-1.4-6.1-6.1-6.1h-16.3c-5.5,0-7.7,1.9-7.7,7.4c0,30.3,0.3,60.7,0,91
c0,9.7-5.2,17.1-13.2,22.1c-8,5.2-17.6,5.5-27,6.1c-6.9,0.6-13.8-0.6-20.7-1.7c-6.1-1.1-12.1-2.2-17.1-6.6
c-7.2-6.6-9.7-15.4-9.7-24.5c0.3-29.2,0.6-58.2,0.6-87.4s0-5.2,0-7.1h-5.4c-0.4,0.3-0.9,0.5-1.4,0.5h-16.8c-3.3,0-6.1,2.5-6.1,5.8
c0.8,29,0.3,89.9,0.3,91.3c0,33.4,32.8,60.4,73.4,60.4s72.8-26.8,73.1-59.6c0.3-0.3,0.3-0.8,0.3-1.1c0.3-14.6,0.3-29.2,0-43.6l0,0
H1132.2z"/>
</svg>
</template>

