<template>

<div v-if="currentUser.Role == 'Customer'" class="-content-block -hide">

  <h2 class="app-title hide">
    {{ title }}
  </h2>

  <div class="tabs-content tabs-content-customer" :data-tabs-content="'tabsDashboard'+currentUser.id">

    <div class="tabs-panel" :class="tab1" :id="'panelDashboard_1'+currentUser.id">
      <!-- <p>subtitle panel1</p> -->
      <h2 class="app-title">
        <i class="fa-light fa-user"></i>
        Meine Daten
      </h2>

      <div class="dx-card hide">
        <div class="user-base-wrap has-padding">
          <!-- <p>Profile Dashboard</p> -->
        </div><!-- /user-base-wrap --> 
      </div><!-- /dx-card --> 


    </div><!-- /tabs-panel -->     

    <div class="tabs-panel" :class="tab2" :id="'panelDashboard_2'+currentUser.id">
      <!-- <p>subtitle panel2</p> -->
      <h2 class="app-title">
        <i class="fa-light fa-address-card"></i>
        Adresse
      </h2>
      
      <div class="dx-card">
      
        <div class="user-base-wrap has-padding">
          <!-- Adresse -->

          <div id="form-demo">
            <div class="upload-widget-container">
              <form
                action="your-action"
                @submit="handleSubmit"
              >

                <!-- SUBMIT BUTTON  -->
                <div class="grid-x hide" style="margin-top: 15px;">
                  <div class="auto cell">
                  </div>
                  <div class="shrink cell">
                    <button :id="'submitform'+currentUser.id" class="cx-button">
                      Profil sichern
                    </button>
                  </div>
                </div>
                <!-- /SUBMIT BUTTON  -->
              
              <DxForm
                  :form-data="fetchUser"
                  :read-only="false"
                  @initialized="saveFormInstance"
                  :show-colon-after-label="true"
                  :show-validation-summary="true"
                  validation-group="currentUserData"
                >

                  <DxGroupItem 
                  caption=""
                  :colCountByScreen="colCountByScreen"
                  >
        
                    <DxSimpleItem 
                      data-field="Title"
                      help-text=""
                      :caption="currentUser.Translation.vueappCustomerPrefix"
                      :placeholder="currentUser.Translation.vueappCustomerPrefix"
                      :editor-options="genderEditorOptions"
                      :validation-rules="validationRules.gender"
                      editor-type="dxSelectBox"
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerPrefix"/>
                      <DxRequiredRule :message="currentUser.Translation.vueappCustomerPrefixMessage"/>
                      <DxPatternRule
                        :pattern="titlePattern"
                        :message="currentUser.Translation.vueappCustomerPrefixPattern"
                      />
                    </DxSimpleItem>
        
                    <DxSimpleItem 
                      data-field="FirstName"
                      help-text=""
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerFirstName"/>
                      <DxRequiredRule :message="currentUser.Translation.vueappCustomerFirstNameMessage"/>
                      <DxPatternRule
                        :pattern="firstnamePattern"
                        :message="currentUser.Translation.vueappCustomerFirstNamePattern"
                      />
                    </DxSimpleItem>
        
                    <DxSimpleItem 
                      data-field="LastName"
                      help-text=""
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerLastName"/>
                      <DxRequiredRule :message="currentUser.Translation.vueappCustomerLastNameMessage"/>
                      <DxPatternRule
                        :pattern="lastnamePattern"
                        :message="currentUser.Translation.vueappCustomerLastNamePattern"
                      />
                    </DxSimpleItem>

                    <DxSimpleItem 
                      data-field="Email"
                      help-text=""
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerEmail"/>
                      <DxRequiredRule :message="currentUser.Translation.vueappCustomerEmailMessage"/>
                      <DxEmailRule :message="currentUser.Translation.vueappCustomerEmailRule"/>
                      <DxAsyncRule
                        :validation-callback="asyncValidation"
                        :message="currentUser.Translation.vueappCustomerEmailPattern"
                      />
                    </DxSimpleItem>

                    <DxSimpleItem
                      data-field="Street"
                      help-text=""
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerStreet"/>
                    </DxSimpleItem>
        
                    <DxSimpleItem 
                      data-field="Zip"
                      help-text=""
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerZip"/>
                    </DxSimpleItem>
        
                    <DxSimpleItem 
                      data-field="City"
                      help-text=""
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerCity"/>
                    </DxSimpleItem>
        
                    <DxSimpleItem
                      data-field="Phone"
                      help-text=""
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerPhone"/>
                    </DxSimpleItem>

                    <DxSimpleItem
                      data-field="Birthdate"
                      help-text=""
                      editor-type="dxDateBox"
                      :editor-options="calendarOptions"
                      displayFormat="dd.MM.yyyy"  
                    >
                      <DxLabel :text="currentUser.Translation.vueappCustomerBirthdate"/>
                    </DxSimpleItem>
        
                    <DxSimpleItem
                      data-field="Ahvnummer"
                      help-text=""
                    >
                      <DxLabel :text="'AHV-Nummer'"/>
                    </DxSimpleItem>

                    <DxSimpleItem
                      data-field="Krankenversicherung"
                      help-text=""
                    >
                      <DxLabel :text="'Krankenversicherung'"/>
                    </DxSimpleItem>

                    <DxSimpleItem
                      data-field="Zusatzversicherung"
                      help-text=""
                    >
                      <DxLabel :text="'Zusatzversicherung'"/>
                    </DxSimpleItem>

                    
                    <DxSimpleItem 
                      data-field="Country"
                      help-text=""  
                      :editor-options="countryEditorOptions"
                      :validation-rules="validationRules.country"
                      editor-type="dxSelectBox"
                      css-class="hide"
                  >
                    <DxLabel :text="currentUser.Translation.vueappCustomerCountry"/>

                  </DxSimpleItem>

                  <DxSimpleItem 
                      data-field="Language"
                      help-text=""  
                      :editor-options="languageEditorOptions"
                      :validation-rules="validationRules.language"
                      editor-type="dxSelectBox"
                      css-class="hide"
                  >
                    <DxLabel :text="currentUser.Translation.vueappCustomerLanguage"/>
                  </DxSimpleItem>


                  </DxGroupItem>

                  <DxSimpleItem 
                    data-field="Notes"
                    help-text=""
                    css-class="hide"
                  >
                    <DxLabel :text="currentUser.Translation.vueappCustomerNotes"/>
                  </DxSimpleItem>

                  <DxButtonItem
                    horizontal-alignment="left"
                    :button-options="buttonOptions"
                    css-class="hide"
                  />
        
                </DxForm>

                <!-- SUBMIT BUTTON  -->
                <div class="grid-x" style="margin-top: 15px;">
                  <div class="auto cell">
                  </div>
                  <div class="shrink cell">
                    <button :id="'submitform'+currentUser.id" class="cx-button">
                      Profil sichern
                    </button>
                  </div>
                </div>
                <!-- /SUBMIT BUTTON  -->

              </form>
            </div>
          </div>

        </div><!-- /user-base-wrap --> 
      </div><!-- /dx-card --> 

    </div><!-- /tabs-panel -->    

    <div class="tabs-panel" :class="tab3" :id="'panelDashboard_3'+currentUser.id">
      <!-- <p>subtitle panel3</p> -->
      <h2 class="app-title">
        <i class="fa-light fa-camera"></i>
        Foto
      </h2>

      <div class="dx-card">
        <div class="user-base-wrap has-padding">

          <div class="user-thumb-medium-wrap">
            <div class="user-thumb user-thumb-medium" v-if="fetchUser"
              v-bind:style="{ 'background-image': 'url(' + fetchUser.Avatar + '?v=' + timestamp + ')' }" >
            </div>
          </div><!-- /user-thumb-wrap -->

          <!-- upload Picture -->
          <DxFileUploader
            id="upfile"
            name="upfile"
            class="uploadbutton"
            :upload-url="apihost+'/uploadavatar.php?id=' + currentUser.id"
            :accept="accept"
            :multiple="false"
            :upload-mode="uploadMode"
            :allowed-file-extensions="['.jpg', '.jpeg', '.png']"
            :min-file-size="1024"
            :max-file-size="10240*1024"
            :on-files-uploaded="onFilesUploaded"
            label-text=""
            :select-button-text="currentUser.Translation.vueappCustomerAvatarButton"
            @value-changed="e => files = e.value"
          />


        </div><!-- /user-upload-wrap -->
      </div><!-- /dx-card --> 
    </div><!-- /tabs-panel -->    

    <div class="tabs-panel" :class="tab4" :id="'panelDashboard_4'+currentUser.id">
      <!-- <p>subtitle panel4</p> -->
      <h2 class="app-title">
        <i class="fa-light fa-heart-pulse"></i>
        Gesundheit
      </h2>
      
      <div class="dx-card">
        <div class="user-base-wrap has-padding">
          <!-- <p>Gesundheit</p> -->
          <!-- <HealthDetailTemplate
            :count="countAlertMyprofile"
          /> -->
          <HealthDetailTemplate

          />
        </div><!-- /user-base-wrap --> 
      </div><!-- /dx-card --> 
    </div><!-- /tabs-panel --> 
  
    <div class="tabs-panel" :class="tab5" :id="'panelDashboard_5'+currentUser.id">
      <!-- <p>subtitle panel5</p> -->
      <h2 class="app-title">
        <i class="fa-light fa-weight-scale"></i>
        Messungen
      </h2>
      
      <div class="dx-card">
        <div class="user-base-wrap has-padding">
          <!-- <p>Messungen</p> -->
        </div><!-- /user-base-wrap --> 
      </div><!-- /dx-card --> 
    </div><!-- /tabs-panel --> 

  </div><!-- /tabs-content -->               

  <ul :id="'tabsDashboard'+currentUser.id" class="tabs grid-x grid-margin-x small-up-2" data-tabs style="border: none; background: none; margin: 0 -15px;">
    <li class="cell tabs-title hide" :class="tab1">
      <a :href="'#panelDashboard_1'+currentUser.id" aria-selected="true" style="padding: 0;">
        <!-- panelDashboard_1 start -->
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
          :count="countAlertMyprofile"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-user"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Meine Daten</p>
          </div>
        </div>
      </a>
    </li>
    <li class="cell tabs-title" :class="tab2">
      <a :href="'#panelDashboard_2'+currentUser.id" aria-selected="true" style="padding: 0;">
        <!-- panelDashboard_2 adress -->
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertAdress"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-address-card"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Adresse</p>
          </div>
        </div>
      </a>
    </li>
    <li class="cell tabs-title" :class="tab3">
      <a :href="'#panelDashboard_3'+currentUser.id" aria-selected="true" style="padding: 0;">
        <!-- panelDashboard_3 foto -->
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertFoto"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-camera"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Foto</p>
          </div>
        </div>
      </a>
    </li>
    <li class="cell tabs-title" :class="tab4">
      <a :href="'#panelDashboard_4'+currentUser.id" aria-selected="true" style="padding: 0;">
        <!-- panelDashboard_4 gesundheit -->
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertHealth"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-heart-pulse"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Gesundheit</p>
          </div>
        </div>
      </a>
    </li>
    <li class="cell tabs-title" :class="tab5">
      <a :href="'#panelDashboard_5'+currentUser.id" aria-selected="true" style="padding: 0;">
        <!-- panelDashboard_5 messungen -->
        <div class="dx-card dashboard-item">
          <!-- alert-icon -->
          <AppAlertDashboard
            :count="countAlertMeasurement"
          />
          <!-- /alert-icon -->
          <div class="text-center">
            <i class="dashboard-icon fa-light fa-weight-scale"></i>
          </div>
          <div class="text-center">
            <p class="dashboard-text">Messungen</p>
          </div>
        </div>
      </a>
    </li>

  </ul>

</div><!-- content-block -->

<div v-else class="content-block">
  Oh no 😢
</div><!-- content-block -->

</template>

<script>

import AppAlertDashboard from "../appdetail/app-alert-dashboard";
import HealthDetailTemplate from '../appdetail/health-detail.vue';


import $ from 'jquery';
import Foundation from 'foundation-sites';

import DxForm, {
  DxGroupItem,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  DxPatternRule,
  DxEmailRule,
  DxAsyncRule,
} from 'devextreme-vue/form';
//import DxAutocomplete from 'devextreme-vue/autocomplete';
//import DxDateBox from 'devextreme-vue/date-box';

import notify from 'devextreme/ui/notify';
import Validator from 'devextreme/ui/validator';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
//import { DxTextBox } from 'devextreme-vue/text-box';
//import { DxButton } from 'devextreme-vue/button';
//import { DxCheckBox } from 'devextreme-vue/check-box';
//import { DxSelectBox } from 'devextreme-vue/select-box';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1
}

let tab1 = 'is-active';
let tab2 = '';
let tab3 = '';
let tab4 = '';
let tab5 = '';

//let invalidEmail = 'test@dx-email.com';
let invalidEmail = '';
let currentUser;
let fetchUser;
let timestamp;

let countAlertMyprofile = 0;
let countAlertAdress = 0;
let countAlertFoto = 0;
let countAlertHealth = 0;
let countAlertMeasurement = 0;


//Async Check Email
const sendRequest = function(value) {

  setTimeout(() => {
    fetch(apihost+'/de/vue/member/membercheckmail/' + currentUser.id + '/' + value)
    .then(response => response.text())
    .then(result => {
    const data = JSON.parse(result);
    //console.log(data);
    invalidEmail = data;
    //this.fetchUser = data;
    })
    .catch(() => { throw 'Network error' });
  
  }, 0);

  //console.log(invalidEmail);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value !== invalidEmail);
    }, 1000);
  });

};

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    //!Important for Role id etc. => update data with fetch on mounted!
    currentUser = e.data;

  } // e.data
}); //auth

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
  }

export default {

  async mounted() {

    await this.fetchAlert();

    let url = window.location.href;
    let pos = url.indexOf("?");
    let tab = '';

    if(pos > 0){
      tab = url.substring(pos+1, 100);
    }

    if(tab == 'adress'){
      this.tab1 = '';
      this.tab2 = 'is-active';
    }

    if(tab == 'foto'){
      this.tab1 = '';
      this.tab3 = 'is-active';
    }

    if(tab == 'health'){
      this.tab1 = '';
      this.tab4 = 'is-active';
    }

    if(tab == 'measurement'){
      this.tab1 = '';
      this.tab5 = 'is-active';
    }

    this.tabsDashboard = new Foundation.Tabs($('#tabsDashboard'+this.currentUser.id), {
      matchHeight: false
    });

    this.accordion = new Foundation.Accordion($('#accordion'), {
      // These options can be declarative using the data attributes
      slideSpeed: 500,
      multiExpand: false,
      allowAllClosed: true,
    });
    

    //REFRESH https://stackoverflow.com/questions/44094629/i-have-page-refreshing-on-vuejs-single-page-app
    //this.$router.push('/about');

    auth.getUser().then((e) => {
      if(e.data){
        // console.log(e.data);
        // console.log(e.data.id);
        // console.log(e.data.Language);

        //DATA
        setTimeout(() => {
          fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/view')
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data.Avatar);
          this.fetchUser = data;
          })
          .catch(() => { throw 'Network error' });
        
        }, 0);

      } // e.data
      
    }); //auth

    this.timestamp = this.printTimestamp();

  },

  components: {

    AppAlertDashboard,
    HealthDetailTemplate,

    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxRequiredRule,
    //DxCompareRule,
    DxPatternRule,
    //DxRangeRule,
    DxEmailRule,
    //DxStringLengthRule,
    DxForm,
    //DxAutocomplete,
    DxAsyncRule,
    //notify,
    //DxDateBox,
    DxFileUploader,
    //DxTextBox,
    //DxButton,
    //DxSelectBox,
    //DxCheckBox,

  },
  data() {
    return {
      apihost,
      title: 'Meine Daten',
      //title: currentUser.Translation.vueappNavMemberProfile,
      colCountByScreen,
      formInstance: null,
      currentUser,
      fetchUser,
      timestamp,

      tab1,
      tab2,
      tab3,
      tab4,
      tab5,

      countAlertMyprofile,
      countAlertAdress,
      countAlertFoto,
      countAlertHealth,
      countAlertMeasurement,


      //uploader
      formRefName: 'tree-view',
      multiple: false,
      accept: '*',
      uploadMode: 'instantly',
      //uploadUrl: apihost+'/uploadavatar.php?id=' + currentUser.id,
      fileTypesSource: [
        { name: 'All types', value: '*' },
        { name: 'Images', value: 'image/*' },
        { name: 'Videos', value: 'video/*' },
      ],
      files: [],
      //end uploader        

      calendarOptions: { 
        displayFormat: "dd.MM.yyyy"
      },
      buttonOptions: {
      text: currentUser.Translation.vueappCustomerProfileSubmit,
        type: 'success',
        useSubmitBehavior: true,
      },
      passwordOptions: {
        mode: 'password',
        onValueChanged: () => {
          const editor = this.formInstance.getEditor('ConfirmPassword');
          if (editor.option('value')) {
            const instance = Validator.getInstance(editor.element());
            instance.validate();
          }
        },
        buttons: [
          {
            name: 'password',
            location: 'after',
            options: {
              icon: apihost+'/images/icons/eye.png',
              type: 'default',
              onClick: () => this.changePasswordMode('Password'),
            },
          },
        ],
      },
      confirmPasswordOptions: {
        mode: 'password',
        buttons: [
          {
            name: 'password',
            location: 'after',
            options: {
              icon: apihost+'/images/icons/eye.png',
              type: 'default',
              onClick: () => this.changePasswordMode('ConfirmPassword'),
            },
          },
        ],
      },
      dateBoxOptions: {
        invalidDateMessage:
          'The date must have the following format: MM/dd/yyyy',
      },
      checkBoxOptions: {
        text: 'I agree to the Terms and Conditions',
        value: false,
      },
      phoneEditorOptions: {
        mask: '+1 (X00) 000-0000',
        maskRules: {
          X: /[02-9]/,
        },
        maskInvalidMessage: 'The phone must have a correct USA phone format',
      },
      titlePattern: /^[^0-9]+$/,
      firstnamePattern: /^[^0-9]+$/,
      lastnamePattern: /^[^0-9]+$/,
      namePattern: /^[^0-9]+$/,
      cityPattern: /^[^0-9]+$/,
      //phonePattern: /^[02-9]\d{9}$/,
      //maxDate: new Date().setFullYear(new Date().getFullYear() - 21),

      validationRules: {
        position: [
          { type: 'required', message: currentUser.Translation.vueappCustomerPrefixMessage },
        ],
        firstname: [
          { type: 'required', message: currentUser.Translation.vueappCustomerFirstNameMessage },
        ],
        lastname: [
          { type: 'required', message: currentUser.Translation.vueappCustomerLastNameMessage },
        ],
        email: [
          { type: 'required', message: currentUser.Translation.vueappCustomerEmailMessage },
        ],
        role: [
          { type: 'required', message: currentUser.Translation.vueappCustomerRoleMessage },
        ],
        country: [
          { type: 'required', message: currentUser.Translation.vueappCustomerCountryMessage },
        ],
        language: [
          { type: 'required', message: currentUser.Translation.vueappCustomerLanguageMessage },
        ],
      },
      genderEditorOptions: { items: currentUser.Constant.genders, searchEnabled: true },
      roleEditorOptions: { items: currentUser.Constant.roles, searchEnabled: true },
      countryEditorOptions: { items: currentUser.Constant.countries, searchEnabled: true },
      languageEditorOptions: { items: currentUser.Constant.languages, searchEnabled: true },
    };
  },
  methods: {


    async fetchAlert() {

      fetch(apihost+'/'+currentUser.Language+'/de/vue/member/memberalert/'+currentUser.id)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        //console.log(data);

        this.countAlertMyprofile = data.countAlertMyprofile;
        this.countAlertAdress = data.countAlertAdress;
        this.countAlertFoto = data.countAlertFoto;
        this.countAlertHealth = data.countAlertHealth;
        this.countAlertMeasurement = data.countAlertMeasurement;

      })
      .catch(() => { throw 'Network error' });

    },

    printTimestamp: function () {
      return Date.now();
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },

				formatPrice(value) {
					value = parseFloat(value);
					//auf 5 Rappen runden
					value = Math.round(value * 20) / 20;
					//zwei Stellen anzeigen
					value = value.toFixed(2);
					return value;
				},

    onFilesUploaded() {
      //console.log(e);
      //console.log('finished');
      //this.$router.push('/');

      setTimeout(() => {
        window.location.reload();
      }, 2000);

    },

    saveFormInstance(e) {
      this.formInstance = e.component;
      //console.log(e.component);
    },
    changePasswordMode(name) {
      const editor = this.formInstance.getEditor(name);
      editor.option(
        'mode',
        editor.option('mode') === 'text' ? 'password' : 'text',
      );
    },
    passwordComparison() {
      return this.fetchUser.Password;
    },
    checkComparison() {
      return true;
    },
    asyncValidation(params) {
      return sendRequest(params.value);
    },
    handleSubmit(e) {

      e.preventDefault();

      setTimeout(() => {

        if(invalidEmail){

          notify({
            message: currentUser.Translation.vueappCustomerEmailPattern,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 3000);


        } else {


          auth.getUser().then((e) => {
            if(e.data){

              const Id = this.fetchUser.Id;
              const Title = this.fetchUser.Title;
              const FirstName = this.fetchUser.FirstName;
              const LastName = this.fetchUser.LastName;
              const Street = this.fetchUser.Street;
              const Zip = this.fetchUser.Zip;
              const City = this.fetchUser.City;
              const Phone = this.fetchUser.Phone;
              const Email = this.fetchUser.Email;
              const Birthdate = this.fetchUser.Birthdate;

              const Ahvnummer = this.fetchUser.Ahvnummer;
              const Krankenversicherung = this.fetchUser.Krankenversicherung;
              const Zusatzversicherung = this.fetchUser.Zusatzversicherung;

              let getBirthdate;
              let isoDateBirthdate;

              if(Birthdate){
                getBirthdate = new Date(Birthdate); // Or the date you'd like converted.
                isoDateBirthdate = new Date(getBirthdate.getTime() - (getBirthdate.getTimezoneOffset() * 60000)).toISOString();
                //console.log(isoDateBirthdate)  // => '2015-01-26T06:40:36.181'
              }

              let objString = '?id=' + Id;

              if(Title){
                objString = objString + '&Title=' + Title;
              } else {
                objString = objString + '&Title=';
              }
              if(FirstName){
                objString = objString + '&FirstName=' + FirstName;
              } else {
                objString = objString + '&FirstName=';
              }
              if(LastName){
                objString = objString + '&LastName=' + LastName;
              } else {
                objString = objString + '&LastName=';
              }
              if(Street){
                objString = objString + '&Street=' + Street;
              } else {
                objString = objString + '&Street=';
              }
              if(Zip){
                objString = objString + '&Zip=' + Zip;
              } else {
                objString = objString + '&Zip=';
              }
              if(City){
                objString = objString + '&City=' + City;
              } else {
                objString = objString + '&City=';
              }
              if(Phone){
                objString = objString + '&Phone=' + Phone;
              } else {
                objString = objString + '&Phone=';
              }
              if(Email){
                objString = objString + '&Email=' + Email;
              } else {
                objString = objString + '&Email=';
              }
              if(Birthdate){
                objString = objString + '&BirthDate=' + isoDateBirthdate;
              } else {
                objString = objString + '&BirthDate=';
              }
              if(Ahvnummer){
                objString = objString + '&Ahvnummer=' + Ahvnummer;
              } else {
                objString = objString + '&Ahvnummer=';
              }
              if(Krankenversicherung){
                objString = objString + '&Krankenversicherung=' + Krankenversicherung;
              } else {
                objString = objString + '&Krankenversicherung=';
              }
              if(Zusatzversicherung){
                objString = objString + '&Zusatzversicherung=' + Zusatzversicherung;
              } else {
                objString = objString + '&Zusatzversicherung=';
              }
              //console.log(objString);
              
              fetch(apihost+'/de/vue/member/getmember/'+e.data.id+'/save'+objString)
                .then(handleErrors)
                .then(response => response.text())
                .then(result => {
                const data = JSON.parse(result);
                //console.log(data);
                this.fetchUser = data;
              })
              .catch(() => { throw 'Network error' });
    
            } // e.data
            
          }); //auth
    
          notify({
            message: currentUser.Translation.vueappCustomerProfileSubmitSuccess,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'success', 3000);

        } //if invalidEmail
        
      }, 1000); //timeout
      
    },

  },
};
</script>

<style>

</style>
  