<template>

  <h2 class="app-title">
    <!-- <i class="nav-icon fa-light fa-grip-dots"></i>  -->
    {{ title }}
  </h2>


    <!-- Accordion -->
    <div class="accordion-customer-wrap" style="margin-top: 40px;">

    <h3 class="app-title" style="margin-bottom: 20px;">
      Nachrichten
    </h3>

    <ul id="accordionMessage" class="accordion accordion-customer" data-accordion>
      <li class="accordion-item -is-active" data-accordion-item>
        <a href="#" class="accordion-title">
          Neue Nachrichten
        </a>
        <div class="accordion-content" data-tab-content>
          <p><br/>coming soon</p>
        </div>
      </li>
      <li class="accordion-item" data-accordion-item>
        <a href="#" class="accordion-title">
          Gelesene Nachrichten
        </a>
        <div class="accordion-content" data-tab-content>
          <p><br/>coming soon</p>
          <!-- I need to be clicked, in order to show up. -->
        </div>
      </li>
    </ul>

  </div><!-- /accordion-wrap -->

  <!-- Accordion -->
  <div class="accordion-customer-wrap" style="margin-top: 40px;">

    <h3 class="app-title" style="margin-bottom: 20px;">
      Abodaten
    </h3>

    <ul id="accordionAbo" class="accordion accordion-customer" data-accordion>
      <li class="accordion-item -is-active" data-accordion-item>
        <a href="#" class="accordion-title">
          Verträge
        </a>
        <div class="accordion-content" data-tab-content>

          <div class="customer-datagrid">

            <DxDataGrid
              ref="myDataGrid"
              id="gridContainer"
              :data-source="contractDataSource"
              :focused-row-enabled="false"
              :column-auto-width="false"
              :column-hiding-enabled="false"
              :allow-column-reordering="false"
              :show-borders="false"
            >

            <DxPaging :page-size="50" />
            <DxPager :show-page-size-selector="true" :show-info="true" />
            <DxFilterRow :visible="false" />

            <DxEditing
              :allow-updating="false"
              :allow-deleting="false"
              :allow-adding="false"
              mode="row"
            />  

            <DxColumn
              alignment='left' 
              data-field="Search"
              :caption="'Abos'"
              :placeholder="'Suchen'"
              :hiding-priority="99"
              cell-template="contractTemplate"

            />
            <template #contractTemplate="{ data: content }">

              <div class="grid-x -grid-margin-x align-middle ck-itemrow">

                <div class="shrink cell">
                  <!-- avatar -->
                  <div class="user-thumb" style="margin-right: 15px;"
                    v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
                  </div>
                  <!-- /avatar -->
                </div><!-- /shrink cell -->

                <div class="auto cell">
                  <p class="ck-overflow-ellipsis strong">

                    <span v-if="content.data.AboSubject">
                      VG-{{ content.data.id}} | {{ content.data.AboSubject }}
                    </span>
                    <span v-else class="alert-color">
                      <i class="fa-light fa-triangle-exclamation"></i> Abo
                    </span>

                    <span v-if="!content.data.SignaturePng">
                      |
                      <span class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> 
                        <!-- Unterschrift -->
                      </span>
                    </span>

                  </p>
                  <p class="ck-overflow-ellipsis small -hide">          

                    <span v-if="content.data.DateStart && content.data.DateStop">
                      {{ formatDate(content.data.DateStart) }} – {{ formatDate(content.data.DateStop) }}
                    </span>
                    <span v-else class="alert-color">
                      <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                    </span>
                    | 
                    <span v-if="content.data.Price">
                      CHF {{ formatPrice(content.data.Price - content.data.Discount) }}
                    </span>
                    <span v-else class="alert-color">
                      <i class="fa-light fa-triangle-exclamation"></i> Preis
                    </span>
                    | 
                    <span v-if="content.data.Rates && content.data.Price">
                      {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                    </span>
                    <span v-else class="alert-color">
                      <i class="fa-light fa-triangle-exclamation"></i> Raten
                    </span>

                  </p>
                </div><!-- auto cell -->
                <div class="shrink cell">

                  <!-- as wkhtmltopdf-->
                  <div class="-hide">
                    <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/contract/'+currentUser.id+'/'+content.data.id+'&dest=send'" target="_blank" style="font-size: 20px;">
                      <i class="fa-light fa-file-pdf"></i> 
                      <!-- Vertrag-{{content.data.id}}.pdf -->
                    </a>
                  </div>

                </div><!-- /shrink cell -->

              </div><!-- grid-x -->
            </template>

            <DxMasterDetail
              :enabled="true"
              template="masterContractDetailTemplate"
            />
            <template #masterContractDetailTemplate="{ data: content }">
              <ContractDetailTemplate
                :template-data="content"
              />
            </template>

          </DxDataGrid>

        </div><!-- customer-datagrid -->

        </div><!-- accordion-content -->
      </li>

      <li class="accordion-item -is-active" data-accordion-item>
        <a href="#" class="accordion-title">
          Rechnungen
        </a>
        <div class="accordion-content" data-tab-content>

          <div class="customer-datagrid">

            <DxDataGrid
            id="gridContainer"
            :data-source="invoiceDataSource"
    
            :focused-row-enabled="false"
            :column-auto-width="true"
            :column-hiding-enabled="true"
            
            :allow-column-reordering="true"
            :show-borders="false"
    
            @editing-start="logEvent('EditingStart')"
            @init-new-row="logEvent('InitNewRow')"
            @row-inserting="logEvent('RowInserting')"
            @row-inserted="logEvent('RowInserted')"
            @row-updating="logEvent('RowUpdating')"
            @row-updated="logEvent('RowUpdated')"
            @row-removing="logEvent('RowRemoving')"
            @row-removed="logEvent('RowRemoved')"
            @saving="logEvent('Saving')"
            @saved="logEvent('Saved')"
            @edit-canceling="logEvent('EditCanceling')"
            @edit-canceled="logEvent('EditCanceled')"
          >
    
          <DxPaging :page-size="100" />
          <DxPager :show-page-size-selector="false" :show-info="true" />
          <DxFilterRow :visible="true" />
    
          <DxEditing
            :allow-updating="false"
            :allow-deleting="false"
            :allow-adding="false"
            mode="row"
          />

          <DxColumn
            alignment='left' 
            data-field="Search"
            :caption="title"
            :placeholder="'Suchen'"
            cell-template="subjectTemplate"
            :hiding-priority="99"
          />

          <template #subjectTemplate="{ data: content }">

            <div class="grid-x align-middle ck-itemrow">
              <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                <p class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                  RE-Nr.
                </p>
                <p class="ck-overflow-ellipsis small">
                  <strong>{{ content.data.id}}</strong>
                </p>
              </div>
              <div class="shrink cell" style="width: 100px; margin-right: 5px;">
                <p class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                  Preis (Rate)
                </p>
                <p class="ck-overflow-ellipsis small">
                  CHF {{ formatPrice(content.data.ratePrice)}}
                </p>
              </div>

              <!-- <div class="shrink cell" style="width: 150px; margin-right: 5px;">
                <p class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                  Kunde
                </p>
                <p class="ck-overflow-ellipsis small">
                  {{ content.data.customerSubject}}
                </p>
              </div> -->

              <div class="shrink cell" style="width: 100px; margin-right: 5px;">
                <p v-if="content.data.rateValuta" class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                  Bezahlt
                </p>
                <p v-else class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                  Fällig
                </p>

                <p v-if="content.data.rateValuta" class="ck-overflow-ellipsis small">
                  <span class="success-color"><i class="fa-light fa-check-circle"></i> {{ formatDate(content.data.rateValuta) }}</span>
                </p>
                <p v-else class="ck-overflow-ellipsis small">
                  <span v-if="content.data.rateDate">
                    <span v-if="content.data.rateStatus == 'alert'" class="alert-color"><i class="fa-light fa-triangle-exclamation"></i> {{ formatDate(content.data.rateDate) }}</span>
                    <span v-if="content.data.rateStatus == 'wait'" class=""><i class="fa-light fa-hourglass-clock"></i> {{ formatDate(content.data.rateDate) }}</span>
                  </span>
                </p>

              </div>

              <!-- <div class="shrink cell hide" style="width: 50px; margin-right: 5px;">
                <p class="ck-overflow-ellipsis small" style="color: grey; font-size: 10px;">
                  Tage
                </p>
                <p class="ck-overflow-ellipsis small">
                  <span v-if="content.data.rateDaysdue < 1" class="success-color">{{ content.data.rateDaysdue }}</span>
                  <span v-else class="alert-color">{{ content.data.rateDaysdue }}</span>
                </p>
              </div> -->

              <div class="auto cell">

              </div>


              <div class="shrink cell">

              <!-- as wkhtmltopdf-->
              <div class="-hide">
                <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+currentUser.id+'/'+content.data.invoiceId+'/'+content.data.rateId+'&dest=send'" target="_blank" style="font-size: 20px;">
                  <i class="fa-light fa-file-pdf"></i> 
                  <!-- Rechnung-{{content.data.id}}.pdf -->
                </a>
              </div>

              </div><!-- /shrink cell -->

              </div><!-- grid-x -->


          </template>

          <!-- <DxMasterDetail
            :enabled="true"
            template="masterDetailTemplate"
          />
          <template #masterDetailTemplate="{ data: content }">
            <DetailTemplate
              :template-data="content"
            />
          </template> -->
    
        </DxDataGrid>


        </div><!-- customer-datagrid -->

        </div><!-- accordion-content -->

      </li>

      <li class="accordion-item" data-accordion-item>
        <a href="#" class="accordion-title">
          Belege Qualicert
        </a>
        <div class="accordion-content" data-tab-content>

          <!-- <p><br/>coming soon</p> -->

          <div class="customer-datagrid">

          <DxDataGrid
            ref="myDataGrid"
            id="gridContainer"
            :data-source="contractDataSource"
            :focused-row-enabled="false"
            :column-auto-width="false"
            :column-hiding-enabled="false"
            :allow-column-reordering="false"
            :show-borders="false"
          >

          <DxPaging :page-size="50" />
          <DxPager :show-page-size-selector="true" :show-info="true" />
          <DxFilterRow :visible="false" />

          <DxEditing
            :allow-updating="false"
            :allow-deleting="false"
            :allow-adding="false"
            mode="row"
          />  

          <DxColumn
            alignment='left' 
            data-field="Search"
            :caption="'Abos'"
            :placeholder="'Suchen'"
            :hiding-priority="99"
            cell-template="contractTemplate"

          />
          <template #contractTemplate="{ data: content }">

            <div class="grid-x -grid-margin-x align-middle ck-itemrow">

              <div class="shrink cell">
                <!-- avatar -->
                <div class="user-thumb" style="margin-right: 15px;"
                  v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
                </div>
                <!-- /avatar -->
              </div><!-- /shrink cell -->

              <div class="auto cell">
                <p class="ck-overflow-ellipsis strong">

                  <span v-if="content.data.AboSubject">
                    VG-{{ content.data.id}} | {{ content.data.AboSubject }}
                  </span>
                  <span v-else class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Abo
                  </span>

                </p>
                <p class="ck-overflow-ellipsis small -hide">          

                  <span v-if="content.data.DateStart && content.data.DateStop">
                    {{ formatDate(content.data.DateStart) }} – {{ formatDate(content.data.DateStop) }}
                  </span>
                  <span v-else class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                  </span>
                  | 
                  <span v-if="content.data.Price">
                    CHF {{ formatPrice(content.data.Price - content.data.Discount) }}
                  </span>
                  <span v-else class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Preis
                  </span>
                  | 
                  <span v-if="content.data.Rates && content.data.Price">
                    {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                  </span>
                  <span v-else class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Raten
                  </span>

                </p>
              </div><!-- auto cell -->
              <div class="shrink cell">

                <!-- as wkhtmltopdf-->
                <div class="-hide">

                  <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/qualicert/'+currentUser.id+'/'+content.data.id+'&dest=send'" target="_blank" style="font-size: 20px;">
                    <i class="fa-light fa-file-pdf"></i> 
                    <!-- Qualicert-{{content.data.id}}.pdf -->
                  </a>
                </div>

              </div><!-- /shrink cell -->

            </div><!-- grid-x -->
          </template>

          </DxDataGrid>

          </div><!-- customer-datagrid -->

        </div>
      </li>


      <li class="accordion-item" data-accordion-item>
        <a href="#" class="accordion-title">
          Belege Fitnessguide
        </a>
        <div class="accordion-content" data-tab-content>

          <!-- <p><br/>coming soon</p> -->

          <div class="customer-datagrid">

          <DxDataGrid
            ref="myDataGrid"
            id="gridContainer"
            :data-source="contractDataSource"
            :focused-row-enabled="false"
            :column-auto-width="false"
            :column-hiding-enabled="false"
            :allow-column-reordering="false"
            :show-borders="false"
          >

          <DxPaging :page-size="50" />
          <DxPager :show-page-size-selector="true" :show-info="true" />
          <DxFilterRow :visible="false" />

          <DxEditing
            :allow-updating="false"
            :allow-deleting="false"
            :allow-adding="false"
            mode="row"
          />  

          <DxColumn
            alignment='left' 
            data-field="Search"
            :caption="'Abos'"
            :placeholder="'Suchen'"
            :hiding-priority="99"
            cell-template="contractTemplate"

          />
          <template #contractTemplate="{ data: content }">

            <div class="grid-x -grid-margin-x align-middle ck-itemrow">

              <div class="shrink cell">
                <!-- avatar -->
                <div class="user-thumb" style="margin-right: 15px;"
                  v-bind:style="{ 'background-image': 'url(' + content.data.AboPicthumb + '?v=' + timestamp + ')' }" >
                </div>
                <!-- /avatar -->
              </div><!-- /shrink cell -->

              <div class="auto cell">
                <p class="ck-overflow-ellipsis strong">

                  <span v-if="content.data.AboSubject">
                    VG-{{ content.data.id}} | {{ content.data.AboSubject }}
                  </span>
                  <span v-else class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Abo
                  </span>

                </p>
                <p class="ck-overflow-ellipsis small -hide">          

                  <span v-if="content.data.DateStart && content.data.DateStop">
                    {{ formatDate(content.data.DateStart) }} – {{ formatDate(content.data.DateStop) }}
                  </span>
                  <span v-else class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Zeitraum
                  </span>
                  | 
                  <span v-if="content.data.Price">
                    CHF {{ formatPrice(content.data.Price - content.data.Discount) }}
                  </span>
                  <span v-else class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Preis
                  </span>
                  | 
                  <span v-if="content.data.Rates && content.data.Price">
                    {{ content.data.Rates }} Rate<span v-if="content.data.Rates > 1">n</span>
                  </span>
                  <span v-else class="alert-color">
                    <i class="fa-light fa-triangle-exclamation"></i> Raten
                  </span>

                </p>
              </div><!-- auto cell -->
              <div class="shrink cell">

                <!-- as wkhtmltopdf-->
                <div class="-hide">

                  <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/fitnessguide/'+currentUser.id+'/'+content.data.id+'&dest=send'" target="_blank" style="font-size: 20px;">
                    <i class="fa-light fa-file-pdf"></i> 
                    <!-- Fitnessguide-{{content.data.id}}.pdf -->
                  </a>
                </div>

              </div><!-- /shrink cell -->

            </div><!-- grid-x -->
          </template>

          </DxDataGrid>

          </div><!-- customer-datagrid -->

        </div>
      </li>

      <li class="accordion-item" data-accordion-item>
        <a href="#" class="accordion-title">
          Besuchte Trainings
        </a>
        <div class="accordion-content" data-tab-content>
          <p><br/>coming soon</p>
          <!-- I need to be clicked, in order to show up. -->
        </div>
      </li>
    </ul>

  </div><!-- /accordion-wrap -->


</template>

<script>
import {
  DxDataGrid, 
  DxFilterRow, 
  DxColumn, 
  DxEditing, 
  DxPager, 
  DxPaging, 
  DxMasterDetail,
  //DxButton,
  //DxLookup, 
} from 'devextreme-vue/data-grid';

import CustomStore from 'devextreme/data/custom_store';
//import notify from 'devextreme/ui/notify';
import ContractDetailTemplate from '../appdetail/contract-detail.vue';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import auth from "../auth";

import { 
  apihost, 
  pdfhost,
} from "../api";
//console.log(apihost);

let currentUser;
let customerId;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

const contractDataSource = new CustomStore({
  key: 'id',
  load: () => {
    return fetch(apihost+'/de/vue/contract/list/?Customerid='+customerId)
    .then(handleErrors)
    .then(response => response.text())
    .then(data => {
    //console.log(`response text`, text)
    return JSON.parse(data)
    })
    .catch(() => { throw 'Network error' });
  },
  insert: (values) => {
    //console.log(values);
    const key = '0';
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/insert'+objString+'&Customerid='+customerId, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  remove: (key) => {
    //console.log(key);
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/remove', {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  update: (key, values) => {
    // console.log(key);
    // console.log(values);
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/contract/getcontract/'+key+'/save'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  }

});

 //console.log(contractDataSource)

 const invoiceDataSource = new CustomStore({
  key: 'id',
  load: () => {
  //const objString = '?case=due';
  const objString = '?Customerid='+customerId;

  return fetch(apihost+'/'+currentUser.Language+'/vue/invoicerate/list/'+objString)
    .then(handleErrors)
    .then(response => response.text())
    .then(data => {
    //console.log(`response text`, text)
    return JSON.parse(data)
    })
    .catch(() => { throw 'Network error' });
  },
  insert: (values) => {
    //console.log(values);
    const key = '0';
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/insert'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  remove: (key) => {
    //console.log(key);
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/remove', {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  },
  update: (key, values) => {
    // console.log(key);
    // console.log(values);
    const objString = '?' + new URLSearchParams(values).toString();
    return fetch(apihost+'/de/vue/invoice/getinvoice/'+key+'/save'+objString, {
      // method: 'GET',
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
  }

});

//console.log(invoiceDataSource)

export default {

  props: {
    customerData: Object,
  },

  components: {
    DxDataGrid,
    DxFilterRow,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxMasterDetail,
    //DxButton,
    //DxLookup,

    ContractDetailTemplate,

  },

  mounted() {

    this.timestamp = this.printTimestamp();

    // console.log(this.customerData.id);
    // customerId = '1189';
    if(this.currentUser){
      customerId = this.currentUser.id;
    }
    //console.log(customerId);

    this.accordionMessage = new Foundation.Accordion($('#accordionMessage'), {
      // These options can be declarative using the data attributes
      slideSpeed: 500,
      multiExpand: false,
      allowAllClosed: true,
    });

    this.accordionAbo = new Foundation.Accordion($('#accordionAbo'), {
      // These options can be declarative using the accordionMessage attributes
      slideSpeed: 500,
      multiExpand: false,
      allowAllClosed: true,
    });

  },
  data() {
    return {
      apihost,
      pdfhost,

      title: 'Weitere Tools',
      currentUser,
      contractDataSource,
      invoiceDataSource,

    };
  },
  methods: {

    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
      return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
    },

				formatPrice(value) {
					value = parseFloat(value);
					//auf 5 Rappen runden
					value = Math.round(value * 20) / 20;
					//zwei Stellen anzeigen
					value = value.toFixed(2);
					return value;
				},

    printTimestamp: function () {
      return Date.now();
    },

  },
  unmounted() {

  },
};
</script>

<style>

</style>
