<template>
<svg version="1.1" id="logo-fitness-factory-24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
x="0px" y="0px" viewBox="0 0 179.4 75.3" style="enable-background:new 0 0 179.4 75.3;" xml:space="preserve">
<polygon class="st0" points="17.4,11.7 25.5,11.7 19.8,32.7 32.7,28 33.2,31.9 43.9,28 44.4,31.9 55,28 55.5,31.9 66.2,28 
53.5,74.9 27.1,74.9 0,58.2 "/>
<path class="st1" d="M26,0c2.5,0,4.9,1,6.6,2.8C35.9,6.4,41,5.1,41,5.1c-4.7,3.8-10-0.6-13,1.2c-2.2,1.2-2,3.1-2,3.1h-8.1
c0,0-1.7-4.5,1.6-7.3C21,0.6,23.5,0,26,0z"/>
<polygon class="st2" points="28.7,52 27.1,74.9 0,58.2 "/>
<polygon class="st1" points="73.7,28 82.5,28 81.7,30.9 77.2,30.9 75.6,36.7 78.8,36.7 77.9,39.7 74.7,39.7 72.1,49.1 67.8,49.1 "/>
<polygon class="st1" points="83.8,28 88,28 82.1,49.1 77.8,49.1 "/>
<polygon class="st1" points="89.5,28 99.8,28 98.9,31.2 95.9,31.2 90.9,49.1 86.6,49.1 91.6,31.2 88.6,31.2 "/>
<polygon class="st1" points="101.3,28 104.3,28 105.9,39 109,28 112.5,28 106.6,49.1 103.6,49.1 102.3,37.4 99,49.1 95.3,49.1 "/>
<polygon class="st1" points="115,28 123.8,28 123,30.9 118.4,30.9 116.8,36.7 120,36.7 119.2,39.7 116,39.7 114.1,46.2 118.7,46.2 
117.9,49.1 109.1,49.1 "/>
<path class="st1" d="M134.1,29.2c0.8,1.1,0.9,2.3,0.5,3.8v0.2c0,0-0.1,0.2-0.1,0.3l-4.6,0.6c0,0,0-0.2,0.1-0.4
c0-0.2,0.4-1.2,0.4-2.1s-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.2-0.6-0.2c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.4-0.6,0.8-0.7,1.3
c0,0.4-0.2,0.8,0,1.2c0,0.4,0,0.7,0.3,1.1c0.2,0.3,0.4,0.6,0.5,0.8c0.2,0.2,0.4,0.5,0.7,0.8s0.5,0.5,0.6,0.7c0.5,0.5,0.8,1,1.1,1.5
s0.5,1,0.7,1.6s0.3,1.2,0.3,1.9s-0.1,1.4-0.3,2.2c-0.4,1.5-1.3,2.7-2.6,3.6c-1.3,0.9-2.7,1.4-4.2,1.4s-1.8-0.2-2.5-0.5
c-0.7-0.4-1.1-0.8-1.4-1.5c-0.3-0.6-0.5-1.3-0.5-2s0-1.6,0.2-2.4l4.5-0.8c-0.7,2.2-0.5,2.9-0.5,3.2s0.2,0.5,0.3,0.6
c0.2,0.2,0.4,0.2,0.7,0.2c0.8,0,1.4-0.5,1.7-1.6c0.4-1.5,0-2.9-1.3-4.4l-1-1.1c-0.3-0.3-0.6-0.6-0.8-0.9c-0.2-0.2-0.4-0.5-0.6-0.9
s-0.4-0.8-0.5-1.2c0-0.4,0-0.8,0-1.3s0-1.1,0.3-1.7c0.5-1.7,1.4-3.1,2.8-4.1s3-1.5,4.7-1.5c0,0,1.9-0.1,3.4,1.7L134.1,29.2z"/>
<polygon class="st1" points="67,53.8 75.8,53.8 74.9,56.7 70.4,56.7 68.8,62.5 72,62.5 71.1,65.5 67.9,65.5 65.3,74.9 61,74.9 "/>
<path class="st1" d="M95,53.4c0.9,0,1.6,0,2.2,0.3s1.1,0.6,1.4,1s0.5,0.9,0.7,1.5c0.2,0.9,0,2.6-0.5,4.2s-0.5,1.8-0.5,1.8h-4.2
l0.5-1.9c0.1-0.3,0.4-1.9,0.4-2.5c0-0.2-0.1-1-1.1-1.1s-2.1,1-2.7,3.1l-2.6,9.1C88,71,88.3,72,89.6,72s2-1.5,2.7-3.6l0.7-2.2h4.1
L96.6,68c-0.2,0.8-0.5,1.5-0.8,2.2s-0.7,1.3-1.2,2s-1,1.2-1.5,1.6s-1.2,0.8-2,1.1c-0.8,0.3-1.6,0.4-2.5,0.4s-1.7,0-2.3-0.4
c-0.6-0.3-1.1-0.6-1.4-1s-0.6-1-0.7-1.6s0-2.2,0.5-4.3l2-7C88.1,56,90.8,53.5,95,53.4L95,53.4z"/>
<polygon class="st1" points="101.4,53.8 111.8,53.8 110.9,57 107.9,57 102.8,74.9 98.6,74.9 103.6,57 100.5,57 "/>
<path class="st1" d="M119,53.4c-3.3,0-5.1,1.9-5.1,1.9c-1.4,1.3-2.4,3.1-3.1,5.6l-2,7.1c-1.3,3.4-0.5,4.9,0.2,5.9
c0.4,0.4,0.9,0.7,1.5,1s1.4,0.4,2.3,0.4c3.1-0.1,6-1.7,7.5-5.1s0.6-1.5,0.8-2.3l2-7C124.5,55.9,123.1,53.4,119,53.4z M119.2,59.7
l-2.6,9.1c-0.6,2-1.7,3.2-2.9,3.2s-1.6-1-1-3.1l2.6-9.1c0-0.4,1-3.3,2.9-3.2S119.8,57.6,119.2,59.7z"/>
<path class="st1" d="M139.1,53.8h4l-0.6,9.3c0.5-1,1.3-2.6,2.4-4.6c1.1-2.1,1.9-3.6,2.4-4.6h3.9L143.3,68l-2,7h-4.1l2-7V53.9l0,0
L139.1,53.8z"/>
<path class="st1" d="M166.6,55.2c0.7,1,0.8,2.5,0.3,4.2c-0.3,0.9-0.7,1.8-1.2,2.8c-0.5,0.9-1.2,1.8-2,2.6s-1.5,1.6-2.2,2.2
s-1.5,1.4-2.4,2.2c-0.9,0.8-1.6,1.4-2,1.9h6.6l-1.1,4h-11.2l1-3.6l7.2-7.3c0.4-0.4,0.8-0.8,1-1.1c0.3-0.3,0.6-0.7,0.9-1.1
s0.6-0.9,0.9-1.3c0.2-0.4,0.4-0.8,0.5-1.2c0-0.2,0.1-0.5,0.1-0.7v-0.7c0-0.2-0.2-0.4-0.3-0.6c-0.2,0-0.4-0.2-0.7-0.2s-0.4,0-0.6,0
s-0.4,0.2-0.5,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.2,0.5-0.3,0.8c0,0.3-0.2,0.5-0.2,0.7
s-0.1,0.4-0.2,0.6v0.3h-4l0.4-1.3c0.2-0.8,0.5-1.5,0.8-2.1s0.7-1.2,1.1-1.8c0.4-0.6,0.9-1,1.5-1.4c0.5-0.4,1.1-0.7,1.8-0.9
s1.4-0.3,2.2-0.3c0,0,2.3,0,3.5,1.5L166.6,55.2z"/>
<path class="st1" d="M179.4,54h-4.8l-3,4.5l-5.7,8.2L165,70h5.9l-1.4,5.1h4l1.4-5.1h2l0.9-3.3h-1.9C175.9,66.7,179.4,54,179.4,54z
M171.8,66.7h-2.3l2.1-3.1l2-3.2L171.8,66.7z"/>
<polygon class="st1" points="28.3,57.7 36.1,57.7 27.1,74.9 "/>
<path class="st1" d="M79.9,53.8l-1.5,3l-9,18.1h4.2l2.1-4.5h2.8v0.7l-0.6,3.8h4.4l1.3-21.1C83.6,53.8,79.9,53.8,79.9,53.8z
M78.9,67.7h-2.2l1.7-3.9l1.6-3.9L78.9,67.7z"/>
<path class="st1" d="M146.3,29.2c0.8,1.1,0.9,2.3,0.5,3.8v0.2c0,0-0.1,0.2-0.1,0.3l-4.6,0.6c0,0,0-0.2,0.1-0.4
c0-0.2,0.4-1.2,0.4-2.1s-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.2-0.6-0.2c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.4-0.6,0.8-0.7,1.3
c0,0.4-0.2,0.8,0,1.2c0,0.4,0,0.7,0.3,1.1c0.2,0.3,0.4,0.6,0.5,0.8c0.2,0.2,0.4,0.5,0.7,0.8s0.5,0.5,0.6,0.7c0.5,0.5,0.8,1,1.1,1.5
s0.5,1,0.7,1.6s0.3,1.2,0.3,1.9s-0.1,1.4-0.3,2.2c-0.4,1.5-1.3,2.7-2.6,3.6c-1.3,0.9-2.7,1.4-4.2,1.4s-1.8-0.2-2.5-0.5
c-0.7-0.4-1.1-0.8-1.4-1.5c-0.3-0.6-0.5-1.3-0.5-2s0-1.6,0.2-2.4l4.5-0.8c-0.7,2.2-0.5,2.9-0.5,3.2s0.2,0.5,0.3,0.6
c0.2,0.2,0.4,0.2,0.7,0.2c0.8,0,1.4-0.5,1.7-1.6c0.4-1.5,0-2.9-1.3-4.4l-1-1.1c-0.3-0.3-0.6-0.6-0.8-0.9c-0.2-0.2-0.4-0.5-0.6-0.9
s-0.4-0.8-0.5-1.2c0-0.4,0-0.8,0-1.3s0-1.1,0.3-1.7c0.5-1.7,1.4-3.1,2.8-4.1s3-1.5,4.7-1.5c0,0,1.9-0.1,3.4,1.7L146.3,29.2z"/>
<path class="st1" d="M137.3,56.7c-0.1-0.7-0.4-1.3-0.9-1.7c-0.4-0.4-1.1-0.7-1.8-0.9c-0.8-0.3-1.7-0.3-2.7-0.3h-4.6l-5.9,21.1h4.2
l2.8-9.7h0.7l-0.3,9.7h4.3c-0.1-2.8-0.3-6.2-0.4-10.2c0.5-0.2,1-0.5,1.5-0.8c0.4-0.3,0.8-0.7,1.1-1c0.3-0.4,0.6-0.7,0.8-1.2
s0.4-0.9,0.5-1.2c0.2-0.4,0.3-0.8,0.4-1.2C137.3,58.3,137.4,57.4,137.3,56.7L137.3,56.7z M132,61.4c-0.7,1-1.7,1.2-1.7,1.2h-1.2
l1.6-5.7h1.4c0.3,0,0.5,0,0.7,0.3s0.2,0.5,0.2,0.8C133,59.9,132.2,61.1,132,61.4z"/>
</svg>
</template>