<template>

  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px;">
    {{ this.templateData.data }}
    <!-- subhide -->
    <div class="-hide" style="color: orange;">
      <p>id: {{ this.templateData.data.id }}</p>
      <p>invoiceId: {{ this.templateData.data.invoiceId }}</p>
      <p>rateId: {{ this.templateData.data.rateId }}</p>
      <p>customerId: {{ this.templateData.data.customerId }}</p>
      <p>ratePrice: {{ this.templateData.data.ratePrice }}</p>
      <p>rateDate: {{ this.templateData.data.rateDate }}</p>
      <p>rateValuta: {{ this.templateData.data.rateValuta }}</p>

      &nbsp;

      <p>rateReminder1: {{ this.templateData.data.rateReminder1 }}</p>
      <p>rateReminder2: {{ this.templateData.data.rateReminder2 }}</p>
      <p>rateReminder3: {{ this.templateData.data.rateReminder3 }}</p>
      <p>ratFee1: {{ this.templateData.data.rateFee1 }}</p>
      <p>ratFee2: {{ this.templateData.data.rateFee2 }}</p>
      <p>ratFee3: {{ this.templateData.data.rateFee3 }}</p>

      &nbsp;

      <p>rateDayspaid: {{ this.templateData.data.rateDayspaid }}</p>
      <p>rateDaysdue: {{ this.templateData.data.rateDaysdue }}</p>
      <p>reference: {{ this.templateData.data.reference }}</p>
      <p>Search: {{ this.templateData.data.Search }}</p>

      &nbsp;

      <p>arrDatesent: {{ this.templateData.data.arrDatesent }}</p>
      <p>datesentInvoice: {{ this.templateData.data.datesentInvoice }}</p>
      <p>datesentRem1: {{ this.templateData.data.datesentRem1 }}</p>
      <p>datesentRem2: {{ this.templateData.data.datesentRem2 }}</p>
      <p>datesentRem3: {{ this.templateData.data.datesentRem3 }}</p>
      
    </div>

    <!-- /subhide -->

    &nbsp;

    <!-- subhide -->
    <div class="-hide" style="color: green;">
      <p>id: {{ id }}</p>
      <p>invoiceId: {{ invoiceId }}</p>
      <p>rateId: {{ rateId }}</p>
      <p>customerId: {{ customerId }}</p>
      <p>ratePrice: {{ ratePrice }}</p>
      <p>rateDate: {{ rateDate }}</p>
      <p>rateValuta: {{ rateValuta }}</p>

      &nbsp;
      
      <p>rateReminder1: {{ rateReminder1 }}</p>
      <p>rateReminder2: {{ rateReminder2 }}</p>
      <p>rateReminder3: {{ rateReminder3 }}</p>
      <p>rateFee1: {{ rateFee1 }}</p>
      <p>rateFee2: {{ rateFee2 }}</p>
      <p>rateFee3: {{ rateFee3 }}</p>

      &nbsp;
      
      <p>rateDayspaid: {{ rateDayspaid }}</p>
      <p>rateDaysdue: {{ rateDaysdue }}</p>
      <p>reference: {{ reference }}</p>

      &nbsp;

      <p>arrDatesent: {{ arrDatesent }}</p>
      <p>datesentInvoice: {{ datesentInvoice }}</p>
      <p>datesentRem1: {{ datesentRem1 }}</p>
      <p>datesentRem2: {{ datesentRem2 }}</p>
      <p>datesentRem3: {{ datesentRem3 }}</p>

    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->

  <form 
    style="padding: 0;"
    :id="id"
    @submit="handleSubmit"
  >

    <DxForm 
      :id="id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >
    <DxSimpleItem 
      data-field="id"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="invoiceId"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>
    <DxSimpleItem 
      data-field="rateId"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="customerId"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="ratePrice"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="rateValuta"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="mastercontent"
      help-text=""
      template="mastercontent"
    >
    <DxLabel 
      :text="'mastercontent'"
      :visible="false"
      />
    </DxSimpleItem>

      <template #mastercontent>

        <div class="master-content">
        
        <!-- SUBMIT BUTTON  -->
        <div class="grid-x grid-margin-x align-middle title-area-content">
          <div class="auto cell">
            <h5>Rate bearbeiten</h5>
          </div>
          <div class="shrink cell">
            <button :id="'submitform'+id" class="cx-button"><i class="fa-light fa-file-invoice"></i> Rate sichern</button>
          </div>
        </div>
        <!-- /SUBMIT BUTTON  -->
        
        <div class="grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid">

          <!-- cell Invoice -->
          <div class="cell cell-margin-y large-3">
            <div class="dx-card no-paddings">

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>Preis</p>
                </div>
              </div>
              <DxNumberBox
                :id="'ratePrice'+id"
                :value="ratePrice"
                :input-attr="{ 'aria-label': 'Price' }"
              />

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>Fällig</p>
                </div>

                <div class="auto cell" style="margin-left: 30px;">
                  <p>Bezahlt</p>
                </div>

                <div class="shrink cell hide" style="width: 20px; margin-right: 5px;">
                  <div style="display: inline-block; margin-left: 10px; font-size: 14px;">
                    <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'&dest=send'" target="_blank">
                    <i class="fa-light fa-file-pdf"></i>
                    </a>
                  </div><!-- /inline-block -->
                </div>
              </div>

              <div class="grid-x align-middle">
                <div class="auto cell" style="margin-right: 10px;">

                  <DxDateBox
                  :id="'rateDate'+id"
                  :value="rateDate"
                  :input-attr="{ 'aria-label': 'Date' }"
                  displayFormat="dd.MM.yyyy"  
                  type="date"
                  />

                </div>
                <div class="auto cell" style="margin-right: 0;">

                  <DxDateBox
                  :id="'rateValuta'+id"
                  :value="rateValuta"
                  :input-attr="{ 'aria-label': 'Valuta' }"
                  displayFormat="dd.MM.yyyy"  
                  type="date"
                  />

                </div>
              </div>

              <div :class="{ 'hide' : rateDaysdue < 1 }">

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>Zahlungserinnerung</p>
                  </div>
                  <div class="auto cell" style="margin-left: 30px;">
                    <p>Mahngebühr</p>
                  </div>
                  <div class="shrink cell hide" style="width: 20px; margin-right: 5px;">
                    <div v-if="rateReminder1" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case=rem1'+'&dest=send'" target="_blank">
                      <i class="fa-light fa-file-pdf"></i>
                      </a>
                    </div><!-- /inline-block -->
                  </div>
                </div>
                <div class="grid-x align-middle">
                  <div class="auto cell" style="margin-right: 10px;">
                    <DxDateBox
                      :id="'rateReminder1'+id"
                      :value="rateReminder1"
                      :input-attr="{ 'aria-label': 'Reminder1' }"
                      displayFormat="dd.MM.yyyy"  
                      type="date"
                    />
                  </div>
                  <div class="auto cell">
                    <DxNumberBox
                      :id="'rateFee1'+id"
                      :value="rateFee1"
                      :input-attr="{ 'aria-label': 'Fee1' }"
                    />
                  </div>
                </div><!-- /grid-x -->
              </div><!-- /v-if -->

              <div :class="{ 'hide' : rateReminder1 == '' }">

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>1. Mahnung</p>
                  </div>
                  <div class="auto cell" style="margin-left: 30px;">
                    <p>Mahngebühr</p>
                  </div>

                  <div class="shrink cell hide" style="width: 20px; margin-right: 5px;">
                    <div v-if="rateReminder2" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case=rem2'+'&dest=send'" target="_blank">
                      <i class="fa-light fa-file-pdf"></i>
                      </a>
                    </div><!-- /inline-block -->
                  </div>
                </div>
                <div class="grid-x align-middle">
                  <div class="auto cell" style="margin-right: 10px;">
                    <DxDateBox
                      :id="'rateReminder2'+id"
                      :value="rateReminder2"
                      :input-attr="{ 'aria-label': 'Reminder2' }"
                      displayFormat="dd.MM.yyyy"  
                      type="date"
                    />
                  </div>
                  <div class="auto cell">
                    <DxNumberBox
                      :id="'rateFee2'+id"
                      :value="rateFee2"
                      :input-attr="{ 'aria-label': 'Fee2' }"
                    />
                  </div>
                </div><!-- /grid-x -->
              </div><!-- /v-if -->

              <div :class="{ 'hide' : rateReminder2 == '' }">

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>2. Mahnung</p>
                  </div>
                  <div class="auto cell" style="margin-left: 30px;">
                    <p>Mahngebühr</p>
                  </div>

                  <div class="shrink cell hide" style="width: 20px; margin-right: 5px;">
                    <div v-if="rateReminder3" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                      <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case=rem3'+'&dest=send'" target="_blank">
                      <i class="fa-light fa-file-pdf"></i>
                      </a>
                    </div><!-- /inline-block -->
                  </div>
                </div>
                <div class="grid-x align-middle">
                  <div class="auto cell" style="margin-right: 10px;">
                    <DxDateBox
                      :id="'rateReminder3'+id"
                      :value="rateReminder3"
                      :input-attr="{ 'aria-label': 'Reminder3' }"
                      displayFormat="dd.MM.yyyy"  
                      type="date"
                    />
                  </div>
                  <div class="auto cell">
                    <DxNumberBox
                      :id="'rateFee3'+id"
                      :value="rateFee3"
                      :input-attr="{ 'aria-label': 'Fee3' }"
                    />
                  </div>
                </div>

              </div><!-- /grid-x -->

              <div class="dx-card-label grid-x align-middle hide">
                <div class="auto cell">
                  <p>Referenz: {{reference}}</p>
                </div>
              </div>

            </div><!-- dx-card-->

          </div>
          <!-- /cell Invoice-->


          <!-- cell Reminder -->
          <div class="cell cell-margin-y large-3">
            <div class="dx-card no-paddings">

              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                  <p>Rechnung</p>
                </div>
                <div class="shrink cell">
                  <div style="display: inline-block; margin-left: 10px; font-size: 14px;">
                    <a @click="showMore(customerId, invoiceId, rateId, 'invoice')">
                      <i class="fa-light fa-file-invoice"></i>
                    </a>
                  </div><!-- /inline-block -->
                </div>
              </div>

              <div :id="customerId+invoiceId+rateId+'invoice'" class="more -hide">

                <div class="grid-x align-middle row-rates" style="height: 30px;">

                  <div class="auto cell">
                    <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'&dest=send'" target="_blank">
                      <i class="fa-light fa-file-pdf"></i>
                        &nbsp;RE-{{ invoiceId }}-{{ rateId }}.pdf
                    </a>
                  </div>

                  <div class="shrink cell">
                    &nbsp;
                  </div>

                </div>

                <div class="grid-x align-middle row-rates" style="height: 30px;">

                  <div class="auto cell">
                    <a @click="sendEmail(customerId, invoiceId, rateId, 'invoice')">
                      <i class="fa-light fa-envelope"></i>
                      &nbsp;E-Mail senden
                      <!-- an info@kohlio.ch -->
                    </a>
                  </div>

                  <div class="shrink cell">
                    <p>{{ datesentInvoice }}</p>
                  </div>

                </div>

              </div>


              <div class="dx-card-label grid-x align-middle -hide" :class="!rateReminder1  ? 'hide' : ''">
                <div class="auto cell">
                  <p>Zahlungserinnerung</p>
                </div>

                <div class="shrink cell">
                  <div v-if="rateReminder1" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                    <a @click="showMore(customerId, invoiceId, rateId, 'rem1')">
                      <i class="fa-light fa-file-invoice"></i>
                    </a>
                  </div><!-- /inline-block -->
                </div>
              </div>


              <div :id="customerId+invoiceId+rateId+'rem1'" class="more -hide" :class="!rateReminder1  ? 'hide' : ''">

                <div class="grid-x align-middle row-rates" style="height: 30px;">

                  <div class="auto cell">
                    <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case=rem1&dest=send'" target="_blank">
                      <i class="fa-light fa-file-pdf"></i>
                        &nbsp;RE-{{ invoiceId }}-{{ rateId }}.pdf
                    </a>
                  </div>

                  <div class="shrink cell">
                    &nbsp;
                  </div>

                </div>

                <div class="grid-x align-middle row-rates" style="height: 30px;">

                  <div class="auto cell">
                    <a @click="sendEmail(customerId, invoiceId, rateId, 'rem1')">
                      <i class="fa-light fa-envelope"></i>
                      &nbsp;E-Mail senden
                      <!-- an info@kohlio.ch -->
                    </a>
                  </div>

                  <div class="shrink cell">
                    <p>{{ datesentRem1 }}</p>
                  </div>

                </div>

              </div>

              <div class="dx-card-label grid-x align-middle -hide" :class="!rateReminder2  ? 'hide' : ''">
                <div class="auto cell">
                  <p>1. Mahnung</p>
                </div>
                <div class="shrink cell">
                  <div v-if="rateReminder2" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                    <a @click="showMore(customerId, invoiceId, rateId, 'rem2')">
                      <i class="fa-light fa-file-invoice"></i>
                    </a>
                  </div><!-- /inline-block -->
                </div>
              </div>

              <div :id="customerId+invoiceId+rateId+'rem2'" class="more -hide" :class="!rateReminder2  ? 'hide' : ''">

                <div class="grid-x align-middle row-rates" style="height: 30px;">

                  <div class="auto cell">
                    <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case=rem2&dest=send'" target="_blank">
                      <i class="fa-light fa-file-pdf"></i>
                        &nbsp;RE-{{ invoiceId }}-{{ rateId }}.pdf
                    </a>
                  </div>

                  <div class="shrink cell">
                    &nbsp;
                  </div>

                </div>

                <div class="grid-x align-middle row-rates" style="height: 30px;">

                  <div class="auto cell">
                    <a @click="sendEmail(customerId, invoiceId, rateId, 'rem2')">
                      <i class="fa-light fa-envelope"></i>
                      &nbsp;E-Mail senden
                      <!-- an info@kohlio.ch -->
                    </a>
                  </div>

                  <div class="shrink cell">
                    <p>{{ datesentRem2 }}</p>
                  </div>

                </div>

              </div>

              <div class="dx-card-label grid-x align-middle -hide" :class="!rateReminder3  ? 'hide' : ''">
                <div class="auto cell">
                  <p>2. Mahnung</p>
                </div>
                <div class="shrink cell">
                  <div v-if="rateReminder3" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                    <a @click="showMore(customerId, invoiceId, rateId, 'rem3')">
                      <i class="fa-light fa-file-invoice"></i>
                    </a>
                  </div><!-- /inline-block -->
                </div>
              </div>

              <div :id="customerId+invoiceId+rateId+'rem3'" class="more -hide" :class="!rateReminder3  ? 'hide' : ''">

                <div class="grid-x align-middle row-rates" style="height: 30px;">

                  <div class="auto cell">
                    <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case=rem3&dest=send'" target="_blank">
                      <i class="fa-light fa-file-pdf"></i>
                        &nbsp;RE-{{ invoiceId }}-{{ rateId }}.pdf
                    </a>
                  </div>

                  <div class="shrink cell">
                    &nbsp;
                  </div>

                </div>

                <div class="grid-x align-middle row-rates" style="height: 30px;">

                  <div class="auto cell">
                    <a @click="sendEmail(customerId, invoiceId, rateId, 'rem3')">
                      <i class="fa-light fa-envelope"></i>
                      &nbsp;E-Mail senden
                      <!-- an info@kohlio.ch -->
                    </a>
                  </div>

                  <div class="shrink cell">
                    <p>{{ datesentRem3 }}</p>
                  </div>

                </div>

              </div>


            </div>
            <!-- /dx-card-->

          </div>
          <!-- /cell Reminder-->

        </div><!-- grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid -->

      </div><!-- master-form -->

    </template><!-- mastercontent -->
  
    <DxButtonItem
      horizontal-alignment="left"
      :button-options="buttonOptions"
      css-class="hide"
    />

  </DxForm>

  </form>

</template>
<script>


import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
} from 'devextreme-vue/form';

import notify from 'devextreme/ui/notify';
import DxNumberBox from 'devextreme-vue/number-box';
import DxDateBox from 'devextreme-vue/date-box';

import auth from "../auth";

import { 
  apihost, 
  pdfhost,
} from "../api";
//console.log(apihost);
//console.log(pdfhost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;

let id;
let invoiceId;
let rateId;
let customerId;
let invoiceCase;
let ratePrice;
let rateDate;
let rateValuta;
let rateReminder1;
let rateReminder2;
let rateReminder3;
let rateFee1;
let rateFee2;
let rateFee3;
let rateDayspaid;
let rateDaysdue;
let reference;
let arrDatesent;
let datesentInvoice;
let datesentRem1;
let datesentRem2;
let datesentRem3;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
   if (!response.ok) {
       throw Error(response.statusText);
   }
   return response;
 }

export default {
 name: 'invoicerate-item-template',

 props: {
  templateData: Object,
 },

 components: {
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxNumberBox,
  DxDateBox,
 },

 mounted() {

  this.id = this.templateData.data.id;
  this.invoiceId = this.templateData.data.invoiceId;
  this.rateId = this.templateData.data.rateId;
  this.customerId = this.templateData.data.customerId;
  this.ratePrice =  this.templateData.data.ratePrice;
  this.rateDate = this.templateData.data.rateDate;
  this.rateValuta = this.templateData.data.rateValuta;
  this.rateReminder1 = this.templateData.data.rateReminder1;
  this.rateReminder2 = this.templateData.data.rateReminder2;
  this.rateReminder3 = this.templateData.data.rateReminder3;
  this.rateFee1 = this.templateData.data.rateFee1;
  this.rateFee2 = this.templateData.data.rateFee2;
  this.rateFee3 = this.templateData.data.rateFee3;
  this.rateDayspaid = this.templateData.data.rateDayspaid;
  this.rateDaysdue = this.templateData.data.rateDaysdue;
  this.reference = this.templateData.data.reference;
  this.arrDatesent = this.templateData.data.arrDatesent;
  this.datesentInvoice = this.templateData.data.datesentInvoice;
  this.datesentRem1 = this.templateData.data.datesentRem1;
  this.datesentRem2 = this.templateData.data.datesentRem2;
  this.datesentRem3 = this.templateData.data.datesentRem3;

},

 data() {
   return {

    apihost,
    pdfhost,

    currentUser,
    colCountByScreen,

    id,
    invoiceId,
    rateId,
    customerId,
    invoiceCase,
    ratePrice,
    rateDate,
    rateValuta,
    rateReminder1,
    rateReminder2,
    rateReminder3,
    rateFee1,
    rateFee2,
    rateFee3,
    rateDayspaid,
    rateDaysdue,
    reference,
    arrDatesent,
    datesentInvoice,
    datesentRem1,
    datesentRem2,
    datesentRem3,

    //save Button
    buttonOptions: {
      text: currentUser.Translation.vueappInvoiceFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },


   };

 },
 methods: {

  functionStatus(dateInvoice, dateValuta){

    const today = new Date().getTime();
    const invoice = new Date(dateInvoice).getTime();
    const valuta = dateValuta;
    let status = 0;

    if (today <= invoice) {
      //console.log('hourglass');
      status = 1;
    }

    if (today > invoice) {
      //console.log('warning');
      status = 2;
    }

    if (today > invoice ) {
      //console.log('alert');
      status = 3;
    }

    if(valuta){
      //console.log('success');
      status = 4;
    }

    return status;

  },

  formatDate(dateString) {
    const date = new Date(dateString);
    // Then specify how you want your dates to be formatted
    //return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    return new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(date);
  },

		formatPrice(value) {
			value = parseFloat(value);
			//auf 5 Rappen runden
			value = Math.round(value * 20) / 20;
			//zwei Stellen anzeigen
			value = value.toFixed(2);
			return value;
		},

  printTimestamp: function () {
    return Date.now();
  },


  showMore(customerId, invoiceId, rateId, invoiceCase) {
    const showElement = document.getElementById(customerId+invoiceId+rateId+invoiceCase);
    if(showElement.classList.contains('hide')){
      showElement.classList.remove("hide");
    } else {
      showElement.classList.add("hide");
    }
  },

  sendEmail(customerId, invoiceId, rateId, invoiceCase) {

    fetch(apihost+'/de/mail/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case='+invoiceCase)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);

      //Update Frontend
      this.datesentInvoice = data.datesentInvoice;
      this.datesentRem1 = data.datesentRem1;
      this.datesentRem2 = data.datesentRem2;
      this.datesentRem3 = data.datesentRem3;
      //this.fetchUser = data;
    })
    .catch(() => { throw 'Network error' });

    notify({
      message: 'E-Mail wurde versandt',
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);


  },

  onFilesUploaded() {

    setTimeout(() => {
      window.location.reload();
    }, 1000);

  },

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  handleSubmit(e) {
    e.preventDefault();

    const id = this.formInstance.getEditor('id').option('value'); 
    const invoiceId = this.formInstance.getEditor('invoiceId').option('value'); 
    const rateId = this.formInstance.getEditor('rateId').option('value'); 
    const customerId = this.formInstance.getEditor('customerId').option('value'); 
    const ratePrice = document.getElementById("ratePrice"+id).getElementsByTagName("input")[0].value;
    const rateDate = document.getElementById("rateDate"+id).getElementsByTagName("input")[0].value;
    const rateValuta = document.getElementById("rateValuta"+id).getElementsByTagName("input")[0].value;
    const rateReminder1 = document.getElementById("rateReminder1"+id).getElementsByTagName("input")[0].value;
    const rateReminder2 = document.getElementById("rateReminder2"+id).getElementsByTagName("input")[0].value;
    const rateReminder3 = document.getElementById("rateReminder3"+id).getElementsByTagName("input")[0].value;
    const rateFee1 = document.getElementById("rateFee1"+id).getElementsByTagName("input")[0].value;
    const rateFee2 = document.getElementById("rateFee2"+id).getElementsByTagName("input")[0].value;
    const rateFee3 = document.getElementById("rateFee3"+id).getElementsByTagName("input")[0].value;
    
    let objString = '?id=' + id;

    if(invoiceId){
      objString = objString + '&invoiceId=' + invoiceId;
    } else {
      objString = objString + '&invoiceId=';
    }
    if(rateId){
      objString = objString + '&rateId=' + rateId;
    } else {
      objString = objString + '&rateId=';
    }
    if(customerId){
      objString = objString + '&customerId=' + customerId;
    } else {
      objString = objString + '&customerId=';
    }
    if(ratePrice){
      objString = objString + '&ratePrice=' + ratePrice;
    } else {
      objString = objString + '&ratePrice=';
    }
    if(rateDate){
      objString = objString + '&rateDate=' + rateDate;
    } else {
      objString = objString + '&rateDate=';
    }
    if(rateValuta){
      objString = objString + '&rateValuta=' + rateValuta;
    } else {
      objString = objString + '&rateValuta=';
    }
    if(rateReminder1){
      objString = objString + '&rateReminder1=' + rateReminder1;
    } else {
      objString = objString + '&rateReminder1=';
    }
    if(rateReminder2){
      objString = objString + '&rateReminder2=' + rateReminder2;
    } else {
      objString = objString + '&rateReminder2=';
    }
    if(rateReminder3){
      objString = objString + '&rateReminder3=' + rateReminder3;
    } else {
      objString = objString + '&rateReminder3=';
    }
    if(rateFee1){
      objString = objString + '&rateFee1=' + rateFee1;
    } else {
      objString = objString + '&rateFee1=';
    }
    if(rateFee2){
      objString = objString + '&rateFee2=' + rateFee2;
    } else {
      objString = objString + '&rateFee2=';
    }
    if(rateFee3){
      objString = objString + '&rateFee3=' + rateFee3;
    } else {
      objString = objString + '&rateFee3=';
    }

    //console.log(objString);

    fetch(apihost+'/de/vue/invoice/getinvoice/'+invoiceId+'/saverate'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      //console.log(data.arrRates);
      //console.log(data.arrRates[0]);
      //console.log(data.arrRates[0].id);
      //console.log(data.arrRates[0].Price);
      //console.log(data.arrRates[0].Date);
      //console.log(data.arrRates[0].Reminder1);

      //Update Frontend 
      this.rateReminder1 = data.arrRates[0].Reminder1;
      this.rateReminder2 = data.arrRates[0].Reminder2;
      this.rateReminder3 = data.arrRates[0].Reminder3;

     //Update List-Title

      //start titlePrice
      const titlePrice = document.getElementById('titlePrice'+id);
      if(titlePrice){
        const val = (ratePrice/1).toFixed(2).replace('.', '.');
        const price = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        titlePrice.innerHTML = 'CHF ' + price + ' | ' + rateId + ' Rate';
        //titlePrice.innerHTML = "I have changed!";
      }
      //end titlePrice

      //start titleDaterate
      const titleDaterate = document.getElementById('titleDaterate'+id);
      if(titleDaterate){
        const timeratedate = new Date(rateDate).getTime();
        const stringDaterate = new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(timeratedate);
        titleDaterate.innerHTML = 'Fällig: ' +stringDaterate;
        //titleDaterate.innerHTML = "I have changed!";
      }
      //end titleDaterate
      

    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: 'Rate wurde gesichert',
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

  },    

  },

};

</script>

<style>

</style>
